import React from 'react'
import { Flex, Text, Icon, Link } from 'components'
import { instructionPdfs } from 'api'

export default () => (
  <Flex full column>
    <Text size="24px" weight="600" margin="0 0 40px">
      Instructions
    </Text>
    <Flex column width="75%" margin="0 0 40px 8px">
      {instructionPdfs(process.env.REACT_APP_S3BUCKET_UPLOADS).map((pdf, i) => (
        <Link href={pdf.url} key={`file-${i}`}>
          <Flex justify="center">
            <Icon icon="pdf" fill="gray1" />
            <Text weight="500" margin="0 0 24px 12px">
              {pdf.name}
            </Text>
          </Flex>
        </Link>
      ))}
    </Flex>
  </Flex>
)
