import styled from 'styled-components'
import { StyledComponentType } from 'types/style'

type ContainerProps = {
  height: number
  width: number
}

type FrameProps = {
  color: string
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  paddingBottom?: number
}

export const Container: StyledComponentType<
  'div',
  ContainerProps
> = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  display: flex;
  z-index: 1;
`

export const TopFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: 0;
  left: ${({ paddingLeft }) => `${paddingLeft}px`};
  right: ${({ paddingRight }) => `${paddingRight}px`};
  height: ${({ paddingTop }) => `${paddingTop}px`};
  background: ${({ color }) => color};
`

export const RightFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: ${({ paddingTop }) => `${paddingTop}px`};
  right: 0;
  bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
  width: ${({ paddingRight }) => `${paddingRight}px`};
  background: ${({ color }) => color};
`

export const BottomFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  bottom: 0;
  left: ${({ paddingLeft }) => `${paddingLeft}px`};
  right: ${({ paddingRight }) => `${paddingRight}px`};
  height: ${({ paddingBottom }) => `${paddingBottom}px`};
  background: ${({ color }) => color};
`

export const LeftFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: ${({ paddingTop }) => `${paddingTop}px`};
  left: 0;
  bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
  width: ${({ paddingLeft }) => `${paddingLeft}px`};
  background: ${({ color }) => color};
`

export const TopLeftCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ paddingLeft }) => `${paddingLeft}px`};
  height: ${({ paddingTop }) => `${paddingTop}px`};
  background: ${({ color }) => color};
`

export const TopRightCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ paddingRight }) => `${paddingRight}px`};
  height: ${({ paddingTop }) => `${paddingTop}px`};
  background: ${({ color }) => color};
`

export const BottomLeftCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${({ paddingLeft }) => `${paddingLeft}px`};
  height: ${({ paddingBottom }) => `${paddingBottom}px`};
  background: ${({ color }) => color};
`

export const BottomRightCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: ${({ paddingRight }) => `${paddingRight}px`};
  height: ${({ paddingLeft }) => `${paddingLeft}px`};
  background: ${({ color }) => color};
`
