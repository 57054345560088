import { Flex } from 'components/priority'
import styled from 'styled-components'
import { colors as c } from 'utils'

export const Tag = styled(Flex)`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  background: ${c('secondary')};
  color: ${c('white')};
  border-radius: 3px;
  padding: 0.2rem 0.5rem;
`
