import { axios } from 'api'

// NOTE: Endpoint missing in backend
export const getFloralArrangements = async (case_id) => {
  try {
    const response = await axios.get(`floralArrangements?case_id=${case_id}`)
    return response
  } catch (error) {
    throw error
  }
}
