import { css } from 'styled-components'
import type { StyledFunction } from 'types/style'

export type SpacingProps = {
  padding?: string
  margin?: string
}

const spacing: StyledFunction<SpacingProps> = ({ padding, margin }) => css`
  padding: ${padding};
  margin: ${margin};
`

export default spacing
