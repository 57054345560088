import React from 'react'
import { Flex, Button } from 'components'
import { useRecoilValue } from 'recoil'
import { editorClipboardAtom, selectedItemAtom, selectedItemsAtom } from 'atoms'
import { useCopyItemToClipboard, usePasteItemFromClipboard } from 'hooks'
import { useCopyMultiItemsToClipboard } from 'hooks/editor/useCopyMultipleItemsToClipboard'
import { usePasteMultipleItemsFromClipboard } from 'hooks/editor/usePasteMultipleItemsFromClipboard'

export default ({ padding, justify }) => {
  const selectedItems = useRecoilValue(selectedItemsAtom)
  const selectedItem = useRecoilValue(selectedItemAtom)
  const clipboard = useRecoilValue(editorClipboardAtom)

  const copyItemToClipboard = useCopyItemToClipboard()
  const pasteItemFromClipBoard = usePasteItemFromClipboard()
  const copyMultiItemsToClipboard = useCopyMultiItemsToClipboard()
  const pasteMultiItemsFromClipBoard = usePasteMultipleItemsFromClipboard()

  const hasSelectedMultipleItems = selectedItems.length > 0
  const isClipboardEmpty = clipboard.length === 0
  const hasClipboardMultipleItems = clipboard.length > 1

  const copy = () => {
    if (hasSelectedMultipleItems) {
      copyMultiItemsToClipboard()
    } else if (selectedItem) {
      copyItemToClipboard()
    }
  }

  const paste = () => {
    if (hasClipboardMultipleItems) {
      pasteMultiItemsFromClipBoard()
    } else {
      pasteItemFromClipBoard()
    }
  }

  return (
    <Flex full margin="0 0 24px" justify={justify} padding={padding}>
      {(hasSelectedMultipleItems || selectedItem) && (
        <Button center full={isClipboardEmpty} onClick={copy}>
          {`Copy Item${hasSelectedMultipleItems ? 's' : ''}`}
        </Button>
      )}
      {!isClipboardEmpty && (
        <Button full center margin="0 0 0 12px" onClick={() => paste()}>
          {`Paste Item${hasClipboardMultipleItems ? 's' : ''}`}
        </Button>
      )}
    </Flex>
  )
}
