import React from 'react'
import { Navigation, Text } from 'components'

export default () => {
  return (
    <>
      <Navigation type="admin" />
      <Text margin="32px 0 0 0">Assets</Text>
    </>
  )
}
