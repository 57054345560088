import { useEffect } from 'react'

export default (ref, active, callback, type) => {
  const handleClick = (e) =>
    active && ref.current && !ref.current.contains(e.target) && callback()
  useEffect(() => {
    document.addEventListener(type || 'click', handleClick)
    return () => document.removeEventListener(type || 'click', handleClick)
  })
}
