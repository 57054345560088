import React from 'react'
import { Flex, Input, ColorPicker, Text, Slider } from 'components'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const [color, setColor] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.borderColor' }),
  )

  const [thickness, setThickness] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.borderThickness' }),
  )
  const [cornerRadius, setCornerRadiuses] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.borderRadius' }),
  )
  const circle = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'edits.circle' }),
  )
  const feather = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'edits.feather' }),
  )
  const width = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'width' }),
  )
  const height = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'height' }),
  )

  const largestDimension = width > height ? width : height

  return (
    <Flex column padding="0 12px">
      <Text>Radius</Text>
      <Slider
        sliderState={[cornerRadius, setCornerRadiuses]}
        min="0"
        max={largestDimension}
        margin="0 0 20px"
        initialValue={0}
        disabled={circle}
      />
      <Flex margin="0 0 16px">
        <Input
          label="Thickness (px)ㅤ"
          width="50%"
          margin="0 16px 0 0"
          value={[thickness, setThickness]}
          type="number"
          disabled={feather}
        />
      </Flex>
      <ColorPicker
        color={[color, setColor]}
        label="Color"
        margin="0 0 16px 0"
        disabled={feather}
      />
    </Flex>
  )
}
