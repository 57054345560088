import React from 'react'
import { Flex, Button, Text } from 'components'
import * as S from './ArchiveFooter.styled.js'

export default ({
  archive,
  clearSelectedRows,
  selectedRows,
  show,
  showingArchived,
}) => (
  <S.Footer
    full
    left="0"
    zindex="2"
    height="70px"
    align="center"
    padding="0 42px"
    position="fixed"
    background="white"
    justify="space-between"
    bottom={show ? '0' : '-70px'}
    transition="0.25s"
    borderTop="1px solid"
    borderColor="gray4"
    show={show}
  >
    <Text>{selectedRows.length} selected</Text>
    <Flex row justify="flex-end" align="center">
      <Button margin="0 16px 0 0" onClick={() => clearSelectedRows()}>
        Cancel
      </Button>
      <Button primary onClick={() => archive(true)}>
        {showingArchived ? 'Unarchive' : 'Archive'}
      </Button>
    </Flex>
  </S.Footer>
)
