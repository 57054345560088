import styled from 'styled-components'
import { Flex, Icon } from 'components'
import { colors as c } from 'utils'

export const HeaderContainer = styled(Flex)`
  background: ${c('paleSecondary')};
`

export const PhoneIcon = styled(Icon)`
  position: absolute;
  bottom: 0;
  left: 0;
`

export const SpeechBubbleIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
`

export const WebinarSection = styled(Flex)`
  width: 480px;
  border 1px solid ${c('gray4')};
  padding: 24px;
`
