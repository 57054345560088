import React from 'react'
import * as S from './PageQrCode.styled'
import QRCode from 'react-qr-code'
import { useRecoilValue } from 'recoil'
import { caseAtom } from 'atoms'
import { Flex } from 'components'

export default ({ itemRef, ...props }) => {
  const caseDetails = useRecoilValue(caseAtom)
  const qrCodeUrl = caseDetails?.website_obit_url || ''

  return (
    <S.Shape ref={itemRef} {...props}>
      {qrCodeUrl ? (
        <QRCode
          size={256}
          style={{ height: '100%', width: '100%' }}
          value={qrCodeUrl || ''}
          viewBox={`0 0 256 256`}
        />
      ) : (
        <Flex height="100%" width="100%" background="lockedWhite" center>
          No QR Code added
        </Flex>
      )}
    </S.Shape>
  )
}
