import { css } from 'styled-components'
import { colors as c } from 'utils'
import type { CSS } from 'types/style'

const floatingMenu: CSS = css`
  background: ${c('FloatingMenu')};
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 2px;
`

export default floatingMenu
