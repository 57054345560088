import { caseDetailSpan, replaceInitialValues } from 'utils'
import getThemeStylesForTemplate from 'utils/tinyMCE/templates/getThemeStylesForTemplate'

export default (
  editor,
  funeralHomeDetails,
  templates,
  dateFormat,
  selectedTheme,
) => {
  let themeStyles = ''

  /* Apply theme styling if:
   * - This is a new funeral home insertion
   * - Theme object is not null
   */
  if (selectedTheme) {
    themeStyles = getThemeStylesForTemplate({ selectedTheme })
  }

  const funeralHomeOption1 = replaceInitialValues(
    `
    <div style="${themeStyles}">
      <p>${caseDetailSpan(`funeralHomeDetails.name`)}</p>
      <p>
        ${caseDetailSpan(`funeralHomeDetails.city`)}, ${caseDetailSpan(
      `funeralHomeDetails.state`,
    )}
      </p>
    </div>
  `,
    { funeralHomeDetails },
  )

  const funeralHomeOption2 = replaceInitialValues(
    `
    <div style="${themeStyles}">
      <p>${caseDetailSpan(`funeralHomeDetails.name`)}</p>
      <p>
        ${caseDetailSpan(`funeralHomeDetails.address`)}, 
        ${caseDetailSpan(`funeralHomeDetails.city`)}, 
        ${caseDetailSpan(`funeralHomeDetails.state`)} 
        ${caseDetailSpan(`funeralHomeDetails.zip_code`)}
      </p>
    </div>
  `,
    { funeralHomeDetails },
  )

  const funeralHomeOption3 = replaceInitialValues(
    `
    <div style="${themeStyles}">
      <p>${caseDetailSpan(`funeralHomeDetails.name`)}</p>
      <p>
        ${caseDetailSpan(`funeralHomeDetails.address`)}, 
        ${caseDetailSpan(`funeralHomeDetails.city`)}, 
        ${caseDetailSpan(`funeralHomeDetails.state`)} 
        ${caseDetailSpan(`funeralHomeDetails.zip_code`)}
      </p>
      <p>${caseDetailSpan(`funeralHomeDetails.email_address`)}</p>
    </div>
  `,
    { funeralHomeDetails },
  )

  const funeralMenuOption = [
    funeralHomeOption1,
    funeralHomeOption2,
    funeralHomeOption3,
  ]

  funeralMenuOption.forEach((option, index) => {
    templates.push({
      type: 'menuitem',
      text: `Funeral Home option ${index + 1}`,
      onAction: () => editor.execCommand('mceInsertTemplate', false, option),
    })
  })
}
