import React from 'react'
import { Flex, Text } from 'components'
import {
  TransferThemesButton,
  GlobalTemplatesButton,
  LocalTemplatesButton,
} from './'
import DesignerGate from 'components/auth/DesignerGate'

export default () => {
  return (
    <DesignerGate>
      <Flex center gap="32px" column>
        <Text margin="32px 0 0 0" size="32px" bold>
          🚀 Launch dashboard
        </Text>
        <Flex gap="16px">
          <TransferThemesButton />
          <GlobalTemplatesButton />
          <LocalTemplatesButton />
        </Flex>
      </Flex>
    </DesignerGate>
  )
}
