import React, { useEffect } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { FileDropzone, Flex } from 'components'
import Masonry from './Masonry'
import { useFamilyUpload } from 'hooks'

export default () => {
  const { loading, token, _case, getCase, uploadCaseImagesAsGuest } =
    useFamilyUpload(window.location.pathname)

  useEffect(() => {
    token && getCase()
    //eslint-disable-next-line
  }, [token])

  return (
    <Flex column margin="32px 0 0 0">
      <FileDropzone
        text="Drag and drop file here to edit"
        fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
        onDrop={(files) => uploadCaseImagesAsGuest(files)}
      />
      {loading ? (
        <Flex center width="100%" margin="32px 0 0 0">
          <ClipLoader color="#1A6CAB" size="60" />
        </Flex>
      ) : (
        <Masonry images={_case?.personal_images} />
      )}
    </Flex>
  )
}
