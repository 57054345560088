import React from 'react'
import { Link, Flex, Text, DropIcon } from 'components'
import * as S from './NavItem.styled'

export default ({
  text,
  link,
  dropdown,
  selected,
  onClick,
  children,
  active,
  disabled,
  ...props
}) => (
  <Link href={link} disabled={disabled}>
    <Flex
      margin="0 24px"
      align="stretch"
      justify="center"
      height="100%"
      clickable
      {...props}
    >
      <S.NavItem selected={selected} center onClick={onClick}>
        <Text color="lockedWhite">{text}</Text>
        {dropdown && (
          <DropIcon up={selected} fill="lockedWhite" margin="0 0 0 12px" />
        )}
      </S.NavItem>
      {children}
    </Flex>
  </Link>
)
