import React, { useState, useEffect } from 'react'
import { Flex, Tabs, Text, Button } from 'components'
import { Agreement } from './AgreementSections'

export default ({ setShowFullModal, agreeToTerms }) => {
  const [activeTab, setActiveTab] = useState(null)

  useEffect(() => {
    setActiveTab('Digital Print Suite')
  }, [])

  return (
    <Flex width="1056px" height="100vh" column>
      <Flex
        padding="24px 40px"
        margin="0 0 48px 0"
        height="94px"
        background="paleSecondary"
        align="center"
        justify="space-between"
      >
        <Text size="34px" weight="600">
          Terms of Use
        </Text>
      </Flex>
      <Tabs
        tabs={[
          {
            title: 'Digital Print Suite',
            content: <Agreement />,
          },
          { title: 'Colors', content: <Agreement expand="Colors" /> },
          {
            title: 'Indemnification',
            content: <Agreement expand="Indemnification" />,
          },
        ]}
        selected={[activeTab, setActiveTab]}
      />
      <Flex
        padding="24px 40px"
        height="94px"
        background="paleSecondary"
        align="center"
        justify="flex-end"
      >
        <Button margin="0 8px 0 0" onClick={() => setShowFullModal(false)}>
          Cancel
        </Button>
        <Button
          margin="0 8px 0 0"
          primary
          icon="chevronRight"
          onClick={() => agreeToTerms()}
        >
          I Agree
        </Button>
      </Flex>
    </Flex>
  )
}
