import styled, { css } from 'styled-components'
import { Flex as BaseFlex } from 'components'
import { colors as c } from 'utils'

// Styled Container with hover styles
export const Container = styled(BaseFlex)(
  () => css`
    cursor: pointer;
    align-items: center;
    height: 16px;
    transition: color 0.3s ease, fill 0.3s ease;

    &:hover {
      color: ${c('blue')};
    }

    & svg {
      fill: ${c('gray2')};
      transition: fill 0.3s ease;
    }

    &:hover svg {
      fill: ${c('blue')};
    }
  `,
)

export default Container
