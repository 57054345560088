import styled from 'styled-components'
import { colors as c } from 'utils'
import { Flex } from 'components'

export const LoadingPlaceholder = styled(Flex)`
  position: relative;
  overflow: hidden;

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(
      90deg,
      transparent,
      ${c('white', 25)},
      transparent
    );
    animation: loading 0.8s infinite;
  }
`
