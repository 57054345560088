import styled, { css } from 'styled-components'
import { editorItem, itemFeather } from 'styles'
import { hexOpacity } from 'utils'

export const Shape = styled.div(
  ({ edits }) => css`
    ${editorItem}
    background-color: ${edits?.backgroundColor};
    box-shadow: ${edits?.dropShadow
      ? `${edits?.dropShadow?.xOffset}px ${edits?.dropShadow?.yOffset}px
      ${edits?.dropShadow?.blur}px ${edits?.dropShadow?.spread}px
      ${edits?.dropShadow?.color}${
          hexOpacity[Math.floor(edits?.dropShadow?.opacity * 100)] || ''
        }`
      : `${edits?.boxShadow}`};
    border: ${edits?.borderThickness}px ${edits?.borderThickness && 'solid'}
      ${edits?.borderColor};
    border-radius: ${edits?.circle ? '50%' : `${edits?.borderRadius || 0}px`};
    opacity: ${edits?.opacity};
    ${itemFeather(edits)}
  `,
)
