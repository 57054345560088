import React, { useState, useEffect } from 'react'
import { Flex, LoadingInsertImages, InsertImageGrid } from 'components'
import { caseAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export default ({ setShow }) => {
  const _case = useRecoilValue(caseAtom)
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  const sanitize = (data) => {
    return data.map((image) => ({
      name: '',
      image: image,
    }))
  }

  const getItems = async () => {
    setLoading(true)
    setItems(sanitize(_case.personal_images))
    setLoading(false)
  }

  useEffect(() => {
    _case.personal_images && getItems()
    // eslint-disable-next-line
  }, [_case])

  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex full center padding="8px 0 0 0">
      {/* TODO: add 'make default' button */}
      <InsertImageGrid
        items={items}
        prependPrefix={false}
        imageKey="image"
        nameKey="name"
        type="caseImage"
        setShow={setShow}
        canDelete
      />
    </Flex>
  )
}
