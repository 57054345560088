import { isDate, convertTimeZones } from 'utils'
import { parseISO, isValid } from 'date-fns'

export default (date) => {
  const alreadyDate = isDate(date)
  const parsedISO = parseISO(date)
  let parsedDate = alreadyDate ? date : parsedISO

  if (isValid(parseISO(date))) {
    parsedDate = convertTimeZones.toUTC(parsedISO)
  }

  return isValid(parsedDate) ? parsedDate : date
}
