import type { LockedPalette } from 'types/style'

const lockedPalette: LockedPalette = {
  lockedWhite: {
    default: '#FFFFFF',
  },
  lockedGray1: {
    default: '#2F3133',
  },
  lockedGray2: {
    default: '#797E83',
  },
  lockedGray3: {
    default: '#B0B6BC',
  },
  lockedGray4: {
    default: '#DBDEE6',
  },
  lockedGray5: {
    default: '#F3F5F9',
  },
  lockedAlmostBlack: {
    default: '#1C2125',
  },
}

export default lockedPalette
