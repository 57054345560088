import chunk from 'lodash/chunk'
import { handleHtmlData } from 'utils/global'

const tableData = ({ value, styles }) => {
  const { attributes } = styles
  const encodedValue = handleHtmlData.encode(value)
  const style = attributes?.style || ''

  const cellContent = `<span class="mceNonEditable" data-template-item="${encodedValue}" ${style}>${value}</span>`

  return `
    <td>
      ${cellContent}
    </td>
  `
}

const tableRow = ({ chunk, styles }) => {
  const item = chunk.map((value) => tableData({ value, styles })).join('')

  return `<tr>${item}</tr>`
}

export default ({ path, items, columns = 2, styles = {} }) => {
  const chunked = chunk(items, columns)
  const { attributes } = styles
  const dataStyle = attributes?.styleData || ''

  return `
    <div data-case-detail-table="${path}" class="mceNonEditable" ${dataStyle}>
      <table>
        <tbody>
          ${chunked.map((chunk) => tableRow({ chunk, styles })).join('')}
        </tbody>
      </table>
    </div>
`
}
