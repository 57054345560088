import React from 'react'
import { Button, Flex, Input, TextArea } from 'components'

export default ({
  memorial,
  memorialError,
  setMemorial,
  submitter,
  submitterError,
  setSubmitter,
  setFormOpen,
  createCaseMemorial,
}) => {
  const submit = async () => {
    const data = await createCaseMemorial()

    if (data) setFormOpen(false)
  }

  return (
    <Flex column>
      <TextArea
        label="Add a memorial"
        placeholder="She/he was the most amazing mother/father.."
        error={memorialError}
        value={[memorial, setMemorial]}
      />
      <Input
        width="100%"
        minWidth="300px"
        margin="40px 0 0 0"
        label="Shared by"
        placeholder="Shared by..."
        error={submitterError}
        value={[submitter, setSubmitter]}
        onEnter={submit}
      />
      <Flex width="100%" justify="flex-end" margin="48px 0 0 0">
        <Flex>
          <Button onClick={submit} primary margin="0 16px 0 0">
            Save
          </Button>
          <Button onClick={() => setFormOpen(false)}>Cancel</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
