import React from 'react'
import { Flex } from 'components'
import { CaseFormSection } from 'components/dashboard/CaseDetails'
import { SidebarMenuSaveSection } from 'components/editor/InsertMenu/SidebarMenuSaveSection'

export default ({ saveCase }) => {
  return (
    <>
      <Flex full center padding="8px 8px 100px 8px" gap="8px" column>
        <CaseFormSection formSection="memorialContributions" sidebar={true} />
      </Flex>
      <SidebarMenuSaveSection saveCase={saveCase} />
    </>
  )
}
