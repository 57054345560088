import styled, { css } from 'styled-components'
import { Overlay } from 'components'

export const RefWrapper = styled.div(
  ({ show }) => css`
    pointer-events: ${!show && 'none'};
  `,
)

export const Wrapper = styled(Overlay)(
  ({ show }) => css`
    transition: 0.25s;
    opacity: ${show ? '1' : '0'};
    pointer-events: ${!show && 'none'};
  `,
)
