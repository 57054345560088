import styled from 'styled-components'
import { disabled, elements } from 'styles'
import { Flex, Text } from 'components'

export const DropdownWrapper = styled(Flex)`
  ${disabled}
`

export const Dropdown = styled(Flex)`
  ${elements.default}
`

export const DropdownText = styled(Text)`
  overflow: hidden;
  mask-image: linear-gradient(
    to left,
    transparent 0%,
    black 10%,
    black 100%,
    transparent 100%
  );
`
