import { TableCell, TableActions } from 'components'

const rightAligned = { display: 'flex', justifyContent: 'flex-end' }

export default (itemsLoaded, deleteTemplate, selectDefaultTemplate) => [
  // Deceased Name
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => (
      <TableCell text={value || '---'} loaded={itemsLoaded} />
    ),
  },

  // Default
  {
    Header: 'Default',
    accessor: 'default',
    Cell: ({ value }) => (
      <TableCell text={value && 'Yes'} loaded={itemsLoaded} />
    ),
  },

  // Actions
  {
    Header: 'Actions',
    style: rightAligned,
    accessor: '',
    disableSortBy: true,
    Cell: ({ row }) => (
      <TableActions
        loaded={itemsLoaded}
        actions={[
          {
            text: 'Delete',
            icon: 'archive',
            onClick: () => deleteTemplate(row.original),
          },
          {
            text: 'Make Default',
            icon: 'check',
            onClick: () => selectDefaultTemplate(row.original),
          },
        ]}
      />
    ),
  },
]
