import React from 'react'
import { Flex, Text } from 'components'
import {
  CaseInputWrapper,
  CaseDateWrapper,
  CaseCheckWrapper,
  CaseArrayInput,
  NestedObjectWrapper,
  NestedObjectWrapperWithKeyMapping,
  CaseTextAreaInput,
} from '.'

export default ({ formField }) => {
  return (
    <>
      {formField.type === 'input' && (
        <CaseInputWrapper
          width="100%"
          margin="0 24px 24px 24px"
          label={formField.label}
          name={formField.key}
        />
      )}
      {formField.type === 'date' && (
        <CaseDateWrapper
          width="100%"
          margin="0 24px 24px 24px"
          label={formField.label}
          name={formField.key}
        />
      )}
      {formField.type === 'check' && (
        <CaseCheckWrapper
          width="100%"
          margin="0 24px 24px 24px"
          label={formField.label}
          type={formField.key}
        />
      )}
      {formField.type === 'array' && (
        <CaseArrayInput
          width="100%"
          margin="0 24px 24px 24px"
          type={formField.key}
          shortType={formField.shortLabel}
          title={formField.label}
        />
      )}
      {formField.type === 'textarea' && (
        <CaseTextAreaInput
          label={formField.label}
          name={formField.key}
          width="100%"
          margin="0 24px 24px 24px"
        />
      )}
      {formField.type === 'nestedArray' &&
        formField.typeKeys.map((type, index) => (
          <NestedObjectWrapper
            key={`${type}_${index}`}
            type={formField.key}
            shortType={type}
            title={type}
            formField={formField}
            width="100%"
            margin="0 24px 24px 24px"
          />
        ))}
      {formField.type === 'blank' && (
        <Flex width="100%" key={`${formField.key}`} />
      )}
      {formField.type === 'sectionLabel' && (
        <Flex full>
          <Text size="16px" weight="600" margin="0 24px 24px 24px">
            {formField.label}
          </Text>
        </Flex>
      )}
      {/* this is for arrays we do not know the 'type' of for all entries currently, only additionalFamily */}
      {formField.type === 'mappedArray' && (
        <NestedObjectWrapperWithKeyMapping
          formField={formField}
          width="100%"
          margin="0 24px 24px 24px"
        />
      )}
    </>
  )
}
