import styled, { css } from 'styled-components'
import { editorItem } from 'styles'

export const Image = styled.div(({ imgSrc, opacity, z }) => {
  return css`
    ${editorItem}
    background: url(${imgSrc});
    background-size: cover;
    background-position: center;
    z-index: ${z};
    opacity: ${opacity};
  `
})
