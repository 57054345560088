import React from 'react'
import { Flex, Text } from 'components'
import { unsavedChangesAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export default ({ ...props }) => {
  const unsavedChanges = useRecoilValue(unsavedChangesAtom)
  return (
    <Flex
      full
      center
      // height="100%"
      width="250px"
      padding="16px"
      // background="white"
      flex="0"
      {...props}
    >
      <Text size="12px" color="secondary">
        {unsavedChanges ? 'Unsaved changes' : 'Saved'}
      </Text>
    </Flex>
  )
}
