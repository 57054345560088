import React, { useState } from 'react'
import { Button, Flex, Text, Radio } from 'components'
import { SettingsFormSection } from './InputWrappers'
import { PrintPreview } from './'

export default ({ saveSettings }) => {
  const [topOrBottom, setTopOrBottom] = useState('Top')
  const [leftOrRight, setLeftOrRight] = useState('Left')

  return (
    <>
      <Flex column padding="0 40px 0 0">
        <Text size="24px" weight="600" margin="0 0 24px 0">
          Print Settings
        </Text>
        <Flex>
          <Radio
            label="Top/Bottom Offset"
            value={[topOrBottom, setTopOrBottom]}
            options={['Top', 'Bottom']}
            column
            width="300px"
          />

          <Radio
            label="Left/Right Offset"
            value={[leftOrRight, setLeftOrRight]}
            options={['Left', 'Right']}
            column
            width="300px"
          />
        </Flex>
        <Flex>
          <SettingsFormSection
            printOffsetTB={topOrBottom}
            printOffsetLR={leftOrRight}
            formSection="printSettings"
          />
        </Flex>
      </Flex>
      <Button primary margin="0 0 40px 0" onClick={() => saveSettings()}>
        Save changes
      </Button>
      <PrintPreview />
    </>
  )
}
