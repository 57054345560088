import React, { useState, useEffect } from 'react'
import {
  Button,
  Flex,
  Input,
  Text,
  Dropdown,
  FullscreenModal,
} from 'components'
import { DeleteAccountModal } from './'
import { userAtom, objectSelector } from 'atoms'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { useSetToast } from 'hooks'
import { archiveUser, updateUser } from 'api/users'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler()
  const [staffName, setStaffName] = useState('')
  const [uiTheme, setTheme] = useState('')
  const [user, setUser] = useRecoilState(userAtom)
  const setUserName = useSetRecoilState(
    objectSelector({
      property: 'name',
      atom: userAtom,
    }),
  )
  const setUITheme = useSetRecoilState(
    objectSelector({
      property: 'ui_theme',
      atom: userAtom,
    }),
  )
  const setToast = useSetToast()
  const [showButtonModal, setShowButtonModal] = useState(false)
  const { logout } = useAuth0()

  useEffect(() => {
    user?.name && setStaffName(user.name)
    user?.ui_theme && setTheme(user.ui_theme)
    //eslint-disable-next-line
  }, [user])

  const requestPasswordEmail = async () => {
    const payload = {
      client_id: process.env.REACT_APP_AUTH0_CLIENTID,
      connection: process.env.REACT_APP_AUTH0_AUDIENCE,
      email: user.email,
    }

    try {
      const resp = await axios({
        method: 'post',
        url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        data: payload,
      })
      if (resp.status === 200) {
        setToast({
          text: resp.data,
          icon: 'check',
        })
      }
    } catch (err) {
      handleError(
        err?.response?.data?.message || err.message,
        err,
        'deleteOutline',
      )
    }
  }

  const saveUser = async () => {
    setUserName(staffName)
    setUITheme(uiTheme)
    try {
      const resp = await updateUser({
        name: staffName,
        ui_theme: uiTheme,
      })
      if (resp.status === 200) {
        setToast({
          text: 'User successfully updated!',
          icon: 'check',
        })

        setUser(resp.data)
      }
    } catch (err) {
      handleError(
        err?.response?.data?.message || err.message,
        err,
        'deleteOutline',
      )
    }
  }

  const deleteAccount = async () => {
    try {
      //TODO - make it so archived users can't sign in 😂
      const resp = await archiveUser(user.id)
      if (resp.status === 200) {
        logout({ returnTo: window.location.origin })
      }
    } catch (err) {
      handleError(
        err?.response?.data?.message || err.message,
        err,
        'deleteOutline',
      )
    }
  }
  return (
    <Flex column full padding="0 40px 0 0" height="500px">
      <Flex column align="flex-start">
        <Text size="24px" weight="600" margin="0 0 24px 0">
          Account Settings
        </Text>
        <Input
          width="300px"
          margin="0 0 24px 0"
          label="User Name"
          value={[staffName, setStaffName]}
        />
        <Dropdown
          width="300px"
          margin="0 0 24px 0"
          label="Theme"
          placeholder="Select Default UI Theme"
          textKey="textKey"
          valueKey="valueKey"
          selected={[uiTheme, setTheme]}
          items={[
            { textKey: 'Automatic', valueKey: 'automatic' },
            { textKey: 'Light', valueKey: 'light' },
            { textKey: 'Dark', valueKey: 'dark' },
          ]}
        />
        <Button primary margin="0 0 40px 0" onClick={saveUser}>
          Save Changes
        </Button>

        <Flex row>
          <Button primary margin="0 20px 0 0" onClick={requestPasswordEmail}>
            Change Password
          </Button>
          <Button onClick={() => setShowButtonModal(true)}>
            DeleteAccount
          </Button>
          <FullscreenModal show={[showButtonModal, setShowButtonModal]}>
            <DeleteAccountModal
              deleteAccount={deleteAccount}
              closeModal={() => setShowButtonModal(false)}
            />
          </FullscreenModal>
        </Flex>
      </Flex>
    </Flex>
  )
}
