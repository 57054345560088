import React, { useState } from 'react'
import orderBy from 'lodash/orderBy'
import { Flex } from 'components'
import { TableLiteCell } from './'
import * as S from './TableLite.styled'
import LoadingPlaceholder from 'components/global/LoadingPlaceholder/LoadingPlaceholder'

const TableLite = ({ structure, data, loaded, rowClick, ...props }) => {
  const [orderKey, setOrderKey] = useState(structure?.[0]?.key)
  const [ascending, setAscending] = useState(false)
  const [descending, setDescending] = useState(false)
  const sortedData = orderBy(data, [orderKey], [descending ? 'desc' : 'asc'])

  const setSort = (ascending, descending, orderKey) => {
    // sets the arrow state
    setAscending(ascending)
    setDescending(descending)
    setOrderKey(orderKey)
  }

  const sortRows = (key) => {
    // when the column is not sorted, switch to ascending
    ascending && setSort(false, true, key)

    // when the column is already ascending, switch to descending
    !ascending && !descending && setSort(true, false, key)

    // when the column is already descending, and resets no sorting order
    descending && setSort(false, false, 0)
  }

  const isSelected = (key) => {
    // determines if the column is being actively sorted or not
    const keyIsOrderKey = orderKey === key
    const isSorted = ascending || descending
    return keyIsOrderKey && isSorted
  }

  // feeds the array to be sorted
  const items = !ascending && !descending ? data : sortedData

  return (
    <S.Table {...props}>
      <S.TableBody>
        <S.HeaderRow>
          {structure?.map(({ key, title }) => {
            const selected = isSelected(key)

            return (
              <S.Header
                key={key}
                onClick={() => sortRows(key)}
                selected={selected}
              >
                <Flex align="center">
                  <S.CellText>{title}</S.CellText>
                  <S.HeaderArrow
                    icon="arrowDown"
                    fill={selected ? 'gray2' : 'gray3'}
                    margin="0 0 0 8px"
                    up={orderKey === key && descending}
                    selected={selected}
                  />
                </Flex>
              </S.Header>
            )
          })}
          <S.Header />
        </S.HeaderRow>

        {items?.map((row, i) => (
          <S.Row key={i} onClick={() => rowClick?.(row)}>
            {structure?.map(({ key, secondKey, truncate }) => (
              <S.Cell key={key || secondKey}>
                <TableLiteCell
                  text={row?.[key] || row?.[secondKey]}
                  loaded={loaded}
                  truncate={truncate}
                />
              </S.Cell>
            ))}
            <S.IconCell>
              <S.GoIcon icon="chevronRight" />
            </S.IconCell>
          </S.Row>
        ))}
      </S.TableBody>
    </S.Table>
  )
}

export default ({ ...props }) =>
  props?.loaded ? <TableLite {...props} /> : <LoadingPlaceholder {...props} />
