import React from 'react'
import { Flex, Text, DropIcon, AnimatedHeight } from 'components'

export default ({
  title,
  show: [show, setShow] = [null, () => {}],
  children,
  ...props
}) => (
  <Flex column radius="2px" {...props}>
    <Flex
      justify="space-between"
      align="center"
      background="gray1"
      height="64px"
      padding="24px"
      show={show}
      onClick={() => setShow(!show)}
      radius={show ? '2px 2px 0 0' : '2px'}
      transition="0.25s"
    >
      <Text color="white">{title}</Text>
      <DropIcon fill="white" margin="0 0 0 12px" up={show} />
    </Flex>
    <AnimatedHeight duration={250} height={show ? 'auto' : 0}>
      <Flex
        column
        background="white"
        border="1px solid"
        borderTop="0"
        borderColor="gray4"
        radius="0 0 2px 2px"
        padding="24px"
      >
        {children}
      </Flex>
    </AnimatedHeight>
  </Flex>
)
