import { fonts } from 'utils'

export default fonts
  .map(
    ({ name, language, fallback }) =>
      `${name}${language ? ` - ${language}` : ''}=${name}${
        fallback ? `,${fallback.join(',')}` : ''
      }`,
  )
  .join('; ')
