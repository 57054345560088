import { default as _get } from 'lodash/get'
import { formatDate } from 'utils'

// this utility replaces any values between squiggly brackets: {$VALUE}
// with its corresponding value determined by the "keys" object

// finds all values with squiggly brackets and a dollar sign: {$VALUE}
const itemRegEx = /{\$([^}]*)}/g
// extracts the value between the squiggly brackets
const keySubstring = (key) => key.substr(2, key.length - 3)

// replaces the regEx with keys from an object
const replaceItems = (html, keys) =>
  html.replace(itemRegEx, (key) => {
    const value = _get(keys, keySubstring(key))
    return value ? value : ''
  })

// replaces the editor's html
export const replaceInitialValuesEditorRef = ({ current: editor }, keys) => {
  const content = editor.getContent()
  const replacedHTML = replaceItems(content, keys)
  editor.setContent(replacedHTML)
}

export const replaceInitialValues = (content, keys) => {
  return replaceItems(content, keys)
}

export const replaceInitialValuesAndDates = (content, keys, dateFormat) => {
  const dates = [
    'date_of_birth',
    'date_of_death',
    'date_of_marriage',
    'interment_date',
    'date_entered_service',
    'date_discharged_from_service',
  ]

  return content.replace(itemRegEx, (key) => {
    let value
    if (dates.includes(keySubstring(key))) {
      value = formatDate(_get(keys, keySubstring(key)), dateFormat)
    } else {
      value = _get(keys, keySubstring(key))
    }

    return value ? value : ''
  })
}
