import { format as dateFnFormat } from 'date-fns'
import { isoStringToDate, momentToDateFns } from 'utils'

export default (date, format, isMomentJSFormat) => {
  const convertedFormat = isMomentJSFormat ? momentToDateFns(format) : format

  const convertedDate = isoStringToDate(date)
  const isDate = convertedDate
  return isDate ? dateFnFormat(convertedDate, convertedFormat) : date
}
