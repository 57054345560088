import React, { useEffect } from 'react'
import { Divider } from '../page'
import * as S from './Page.styled'
import * as Items from '../page/Items'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { caseAtom, selectedThemeAtom, themeIdAtom } from 'atoms'
import { useFillCaseImages } from 'hooks'
import ProofWatermark from './ProofWatermark'
import { camelToSnakeCase, transformToPascalCase } from 'utils'
import { isEmpty } from 'lodash'

export default ({
  children,
  pageRef,
  page: { product, pageProperties },
  theme,
  themeLayout,
  padding,
  isPMRegisterBook,
  isEven,
  pdfRender,
  pageTwo,
  isPageShuffleItem,
  isProof,
  ...props
}) => {
  const _case = useRecoilValue(caseAtom)
  const themeId = useRecoilValue(themeIdAtom)
  const setSelectedTheme = useSetRecoilState(selectedThemeAtom)
  const fillCaseImages = useFillCaseImages()

  useEffect(() => {
    _case.id && !_case?.filledImages && fillCaseImages()
    !isEmpty(theme) && setSelectedTheme(theme)
    //eslint-disable-next-line
  }, [_case])

  const pageThemeLayout = camelToSnakeCase(product?.themeLayout)
  const pageHasNoThemeLayout = !pageThemeLayout
  const pageDoesNotMatchTheme =
    pageThemeLayout?.theme_id !== (themeId || themeLayout?.theme_id)

  const pageLayout =
    pageHasNoThemeLayout || pageDoesNotMatchTheme
      ? themeLayout
      : pageThemeLayout

  const editedImageMatchesTheme =
    themeLayout?.theme_id === pageLayout?.background_edits?.theme_id

  // background image url logic
  const backgroundImageUrl =
    pageLayout?.background_edits?.url && editedImageMatchesTheme
      ? pageLayout?.background_edits?.url
      : `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${
          themeLayout?.replacement_image
            ? themeLayout?.replacement_image
            : theme?.image
        }`

  return (
    <S.ProductPrint
      height={`${product?.productSize?.height}px`}
      width={`${product?.productSize?.width}px`}
      padding={product?.padding}
      whiteBorder={product?.whiteBorder}
      theme={theme}
      pageLayout={pageLayout}
      themeLayout={themeLayout}
      backgroundImageUrl={backgroundImageUrl}
      preprinted={pageProperties.paperType === 'preprinted'}
      pageTwo={pageTwo}
      isPageShuffleItem={isPageShuffleItem}
      paperType={pageProperties.paperType}
      {...props}
    >
      {isProof && <ProofWatermark />}
      {!pdfRender &&
        product?.noPrintAreas?.map((noPrint) => (
          <S.NoPrint
            key={noPrint.id}
            left={`${noPrint.x}px`}
            top={`${noPrint.y}px`}
            height={`${noPrint.height}px`}
            width={`${noPrint.width}px`}
          />
        ))}
      {isPMRegisterBook && <S.RBDiv product={product} isEven={isEven} />}
      {product?.dividers?.map(
        (divider, index) =>
          divider?.whiteBorder && (
            <Divider
              key={index}
              left={divider?.left}
              whiteBorder={divider?.whiteBorder}
              padding={product?.padding}
            />
          ),
      )}
      <S.RelativeWrapper>
        <S.PerfGuide
          height={product?.productSize?.height}
          width={product?.productSize?.width}
          padding={product?.padding}
          whiteBorder={product?.whiteBorder}
          overflow="hidden"
        >
          {product?.watermarks?.map((item, i) => {
            const Item =
              Items[`Page${transformToPascalCase(item.type)}`] ||
              Items.PageShape
            return (
              <Item
                {...item}
                key={i}
                {...props}
                print
                images={_case?.filledImages}
              />
            )
          })}
        </S.PerfGuide>

        {product?.items?.map((item, i) => {
          const Item =
            Items[`Page${transformToPascalCase(item.type)}`] || Items.PageShape
          return (
            <Item
              {...item}
              key={i + item?.id}
              {...props}
              print
              images={_case?.filledImages}
            />
          )
        })}
        {children}
      </S.RelativeWrapper>
    </S.ProductPrint>
  )
}
