import { editorPagesAtom, selectedPageAtom, pageLoadingAtom } from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { updatePageItems } from 'hooks'

export default () => {
  const editorPages = useRecoilValue(editorPagesAtom)
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageAtom)
  const [pageLoading, setLoadingPage] = useRecoilState(pageLoadingAtom)
  const updateItems = updatePageItems()

  const navigateToPage = (page) => setSelectedPage(page)
  const nextPage = () =>
    selectedPage !== editorPages.length && navigateToPage(selectedPage + 1)
  const previousPage = () =>
    selectedPage !== 1 && navigateToPage(selectedPage - 1)

  return async (page) => {
    if (!pageLoading) {
      setLoadingPage(true)
      await new Promise((resolve) => setTimeout(resolve, 7))
      updateItems()
      typeof page === 'number' && navigateToPage(page)
      page === 'previous' && previousPage()
      page === 'next' && nextPage()
      setLoadingPage(false)
    }
  }
}
