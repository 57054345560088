import { useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { canvasRefAtom, selectedItemsAtom } from 'atoms'

export const useEditorOutsideClick = (
  ref,
  containerRef,
  active,
  callback,
  resetCallBack,
  type = 'click',
) => {
  const canvasRef = useRecoilValue(canvasRefAtom)
  const setSelectedItems = useSetRecoilState(selectedItemsAtom)

  const isDragging = useRef(false)

  const handleMouseDown = () => {
    isDragging.current = false
  }

  const handleMouseMove = () => {
    isDragging.current = true
  }

  const handleClick = (e) => {
    if (isDragging.current) return

    if (
      active &&
      ref.current &&
      canvasRef &&
      !ref.current.contains(e.target) &&
      canvasRef.contains(e.target) &&
      (!containerRef || !containerRef.current.contains(e.target))
    ) {
      const targetType = e.target.getAttribute('type')

      if (
        targetType === 'bounding-box' ||
        targetType === 'watermark' ||
        !targetType
      ) {
        resetCallBack()
        callback()
      } else {
        setSelectedItems([])
        callback()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener(type, handleClick)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener(type, handleClick)
    }
  })
}
