import React, { useState } from 'react'

import { ModalButton, CasesTableLite } from 'components'

export default ({ buttonText, rowClick, icon, type, importCase, refresh }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <ModalButton
      left
      text={buttonText || 'New Print Package'}
      icon={icon || 'plus'}
      primary
      show={[showModal, setShowModal]}
    >
      <CasesTableLite
        rowClick={rowClick}
        setShowModal={setShowModal}
        type={type}
        importCase={importCase}
        refresh={refresh}
      />
    </ModalButton>
  )
}
