import React from 'react'
import { Flex, FullscreenModal, Text, Button } from 'components'

export default ({ show: [show, setShow] = [null, () => {}], openFile }) => (
  <FullscreenModal show={[show, setShow]}>
    <Flex
      column
      full
      padding="24px 40px"
      align="space-between"
      maxWidth="400px"
    >
      <Text size="34px" weight="600" margin="0 0 12px 0">
        Import failed
      </Text>
      <Text margin="0 0 32px 0">
        Importing the CSV failed, please check that the file is properly
        formatted or contact support.
      </Text>
      <Flex justify="flex-end">
        <Button onClick={() => setShow(false)} margin="0 12px 0 0">
          Close
        </Button>
        <Button primary onClick={() => openFile()}>
          Retry
        </Button>
      </Flex>
    </Flex>
  </FullscreenModal>
)
