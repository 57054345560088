import React, { useEffect, useState } from 'react'
import { Input, HiddenMenu, Icon, Calendar } from 'components'
import { DateUtils } from 'react-day-picker'
import { parse, format } from 'date-fns'

export default ({
  date: [date, setDate] = [null, () => {}],
  items,
  left,
  margin,
  width,
  children,
  disabledDays,
  ...props
}) => {
  const isValidDate = (date) =>
    !(Object.prototype.toString.call(date) === '[object Date]' && isNaN(date))
  if (isValidDate(date)) {
    // initial state
    const [showCalendar, setShowCalendar] = useState(false)
    const [inputValue, setInputValue] = useState('')

    // gets the current day, month, and year
    const currentDate = format(new Date(), 'MM/dd/yyyy')
    const [currentMonth, currentDay, currentYear] = currentDate.split('/')
    const currentShorthandYear = currentYear.slice(-2)

    // confirms the input value is a numeric date
    const isDate = (date) => {
      const cleanDate = date.split('/').join('')
      return +cleanDate === +cleanDate * 1
    }

    // sets the date upon leaving the input, or if no date is set
    useEffect(() => {
      showCalendar === false &&
        setInputValue(date ? format(date, 'MM/dd/yyyy') : '')
      // eslint-disable-next-line
    }, [showCalendar])

    // when the inputValue is a valid date, set the date accordingly & set to default with new case
    useEffect(() => {
      !date && setInputValue('')
      const [month, day, year] = inputValue.split('/')
      const completeDate = month && day && year?.length === 4
      ;(inputValue === '' || completeDate) &&
        date &&
        setInputValue(format(date, 'MM/dd/yyyy'))
      // eslint-disable-next-line
    }, [date])

    // confirms the date is valid
    const parseDate = (dateString, format, locale) => {
      //parse function cannot handle a month 0
      const userStartsMonthWithZero =
        dateString[0] === '0' && dateString[1] === '/'

      if (userStartsMonthWithZero) {
        let originalDateString = dateString
        dateString =
          originalDateString.slice(0, 1) +
          `${currentMonth[1]}` +
          originalDateString.slice(1)
      }

      //parse function cannot handle a day 0
      const userStartsDayWithZero =
        dateString[3] === '0' && dateString[4] === '/'

      if (userStartsDayWithZero) {
        let originalDateString = dateString
        dateString =
          originalDateString.slice(0, 4) + '1' + originalDateString.slice(4)
      }

      const parsed = parse(dateString, format, new Date(), { locale })
      return DateUtils.isDate(parsed) && parsed
    }

    // determines the date upon each onChange within the input
    const determineDate = (value) => {
      // gets the input's day, month, and year
      const [inputMonth, inputDay, inputYear] = value?.split('/')
      // determines if the year is 2 or 4 characters long
      const yearIsShorthand = inputYear?.length === 2
      const yearIsFull = inputYear?.length === 4

      // if the year is 2 characters long, create a 4 character year
      const shorthandYear =
        yearIsShorthand &&
        `${inputYear <= currentShorthandYear ? '20' : '19'}${inputYear}`

      // determine the full year
      let yearResult = currentYear
      if (yearIsShorthand) {
        yearResult = shorthandYear
      } else if (yearIsFull) {
        yearResult = inputYear
      }

      // set the date string
      const incomingDate = `${inputMonth || currentMonth}/${
        inputDay || currentDay
      }/${yearResult}`

      // parse the date string, and confirm it is a valid date
      const parsedDate = parseDate(incomingDate, 'MM/dd/yyyy', 'UTC')

      // set the state accordingly
      setInputValue(value)
      setDate(value && parsedDate)
      setShowCalendar(value && true)
    }

    return (
      <HiddenMenu
        show={[showCalendar, setShowCalendar]}
        left={left}
        width={width}
        margin={margin}
        parentElement={() => (
          <Input
            onChange={({ target }) =>
              isDate(target.value) && determineDate(target.value)
            }
            onEnter={() => setShowCalendar(false)}
            onKeyDown={({ key }) =>
              (key === 'Tab' || key === 'Enter' || key === 'Escape') &&
              setShowCalendar(false)
            }
            onClick={() => date && setShowCalendar(true)}
            placeholder="mm/dd/yyyy"
            padding="12px 36px 12px 12px"
            elementRight={() => (
              <Icon
                icon="calendar"
                fill={showCalendar ? 'gray1' : 'gray2'}
                hoverfill="gray1"
                onClick={() => setShowCalendar(!showCalendar)}
              />
            )}
            value={[inputValue, setInputValue]}
            {...props}
          />
        )}
        hiddenElement={() => (
          <Calendar
            position="absolute"
            top="12px"
            right={left && '0px'}
            date={[date, setDate]}
            show={[showCalendar, setShowCalendar]}
            disabledDays={disabledDays}
          />
        )}
      />
    )
  } else {
    return <></>
  }
}
