import { useEffect } from 'react'
import { themeAtom, userAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'

export default () => {
  const setTheme = useSetRecoilState(themeAtom)

  // gathers the user's current theme
  const user = useRecoilValue(userAtom)
  const selectedTheme = user?.ui_theme
  const isAutomatic = selectedTheme === 'automatic' || !selectedTheme

  // determines the user's operating system theme
  const windowScheme = window?.matchMedia('(prefers-color-scheme: dark)')
  const automaticTheme = windowScheme.matches ? 'dark' : 'light'

  useEffect(() => {
    // sets the theme based on whether it is automatic or not on initial load
    const userTheme = isAutomatic ? automaticTheme : selectedTheme
    setTheme(user ? userTheme : automaticTheme)

    // changeScheme determines whether the user's OS is dark or not
    const changeScheme = (e) => {
      const theme = e.matches ? 'dark' : 'light'
      isAutomatic && setTheme(theme)
    }

    // apply the automatic theme (if enabled) upon the user changing their OS's theme
    user && windowScheme.addEventListener('change', changeScheme)
    return () => {
      windowScheme.removeEventListener('change', changeScheme)
    }

    // eslint-disable-next-line
  }, [user?.ui_theme])
}
