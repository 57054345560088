import { editorPagesAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { updateThemeLayout } from 'utils'

export default () => {
  const [editorPages, setEditorPages] = useRecoilState(editorPagesAtom)

  return (selectedPages, layoutUpdates) => {
    const updatedPages = editorPages.map((originalPage, i) => {
      // determine if the page is even or odd
      const currentPage = i + 1
      const pagePosition = currentPage % 2 === 0 ? 'even' : 'odd'

      // determine if the page will be affected by the changes or not
      const allPagesSelected = selectedPages === 'all'
      const isSelectedPage = allPagesSelected || selectedPages === pagePosition

      // only apply changes to selected pages
      if (isSelectedPage) {
        const updatedPage = updateThemeLayout({
          originalPage,
          layoutUpdates,
        })

        return updatedPage
      } else {
        // return the original page
        return originalPage
      }
    })

    // update the pages atom
    setEditorPages(updatedPages)
  }
}
