import React from 'react'
import { Flex, Text, Button } from 'components'

export default ({ deleteAccount, closeModal }) => (
  <Flex column full padding="32px">
    <Text size="24px" weight="600" margin="0 0 20px 0">
      Delete Account?
    </Text>
    <Text margin="0 0 20px 0">
      Are you sure you want to delete your account?
    </Text>
    {/* LIES */}
    <Text margin="0 0 20px 0">All your information will be deleted.</Text>
    <Flex justify="flex-end" margin="24px 0 0 0">
      <Button primary margin="0 16px 0 0" onClick={closeModal}>
        Cancel
      </Button>
      <Button onClick={deleteAccount}>Delete</Button>
    </Flex>
  </Flex>
)
