import LoadingPlaceholder from 'components/global/LoadingPlaceholder/LoadingPlaceholder'
import { truncate } from 'utils'
import * as S from './Table.styled'
import { Tooltip } from 'components/global/Tooltip/Tooltip'

const CellText = ({ text, truncate: length = 22, ...props }) =>
  text?.length < length || !text ? (
    <S.TableText {...props}>{text}</S.TableText>
  ) : (
    <Tooltip text={text}>
      <S.TableText {...props}>{truncate(text, length)}</S.TableText>
    </Tooltip>
  )

export default ({ loaded, children, ...props }) =>
  loaded ? children || <CellText {...props} /> : <LoadingPlaceholder />
