import React from 'react'
import DayPicker from 'react-day-picker'
import * as S from './Calendar.styled'

export default ({
  date: [date, setDate] = [null, () => {}],
  show: [, setShow] = [null, () => {}],
  disabledDays,
}) => (
  <S.Calendar zindex="1">
    <DayPicker
      selectedDays={date}
      month={date}
      onDayClick={(day) => {
        setDate(day)
        setShow(false)
      }}
      disabledDays={disabledDays}
    />
  </S.Calendar>
)
