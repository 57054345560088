import React from 'react'
import { Flex, Input } from 'components'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'
// import { snapToggleAtom } from 'atoms'
// import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
// import { useSetToast } from "hooks";

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const [layerLevel, setLayerLevel] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'z' }),
  )

  // const [snapToCenter, setSnapToCenter] = useRecoilState(snapToggleAtom)
  // const setToast = useSetToast();

  // const comingSoon = () => {
  //   setToast({
  //     text: "Coming soon!",
  //   });
  // };

  return (
    <Flex column padding="20px 12px">
      {/* <ToggleSwitch
        label="Snap to Alignment"
        value={[snapToCenter, setSnapToCenter]}
        labelLeft
        spaceBetween
        margin="0 0 16px 0"
      /> */}
      {/* PER DUANE THE USERS DO NOT USE THIS, WE SHOULD KEEP IT FOR FUTURE IMPLEMENTATION */}
      {/* <Flex padding="20px 36px" center wrap="wrap">
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="4px 4px"
          icon="alignLeft"
          onClick={() => comingSoon()} //rotateLeft()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="4px 4px"
          icon="alignCenter"
          onClick={() => comingSoon()} //rotateLeft()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="4px 4px"
          icon="alignRight"
          onClick={() => comingSoon()} //rotateLeft()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="4px 4px"
          icon="alignTop"
          onClick={() => comingSoon()} //rotateLeft()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="4px 4px"
          icon="alignBaseline"
          onClick={() => comingSoon()} //rotateLeft()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="4px 4px"
          icon="alignBottom"
          onClick={() => comingSoon()} //rotateLeft()}
        />
      </Flex> */}
      <Input
        label="Layer Level"
        min="0"
        max="99"
        type="number"
        width="50%"
        value={[layerLevel, setLayerLevel]}
      />
    </Flex>
  )
}
