import * as S from './GrayoutZone.styled'

export default (props) => {
  return (
    <>
      <S.TopZone {...props} />
      <S.RightZone {...props} />
      <S.BottomZone {...props} />
      <S.LeftZone {...props} />
    </>
  )
}
