export default () => {
  return (event, pageRef) => {
    let page = pageRef.getBoundingClientRect()
    let unscaledPage = pageRef
    const baseX = event.clientX - page.left
    const baseY = event.clientY - page.top

    const zoomRatio = unscaledPage.clientWidth / page.width

    const x = Math.ceil(baseX * zoomRatio)
    const y = Math.ceil(baseY * zoomRatio)

    return { x, y }
  }
}
