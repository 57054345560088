import styled, { css } from 'styled-components'
import Draggable from 'react-draggable'
import { colors as c } from 'utils'

export const Drag = styled(Draggable)``

export const ItemContainer = styled.div(
  ({ z, isWatermark, disabled }) => css`
    position: absolute;
    z-index: ${z ? `${z + 1}` : 1};
    outline: ${isWatermark && !disabled && `1px solid`};
    outline-color: ${isWatermark && !disabled && c('secondary')};
    pointer-events: ${disabled && 'none'};
  `,
)
