import styled, { css } from 'styled-components'
import { spacing, sizing, text, decorations, positioning } from 'styles'
import type { TextStyledProps, TextStyledComponent } from 'types/text'

/**
 * Styled text component that combines multiple style functions:
 * typography, spacing, sizing, decorations, and positioning.
 */
export const Text: TextStyledComponent = styled.div(
  ({ ...props }: TextStyledProps) => css`
    ${text(props)}
    ${spacing(props)}
    ${sizing(props)}
    ${decorations(props)}
    ${positioning(props)}
  `,
)
