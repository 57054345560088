import applyStylingToNonEditable from 'utils/tinyMCE/plugins/applyStylingToNonEditable'

export default (editorProps) => {
  const editorFunctions = applyStylingToNonEditable(editorProps)
  const { editor } = editorProps

  // Outline stroke sizes for the menu
  const outlineStyles = Array.from({ length: 10 }, (_, i) => `${i + 1}px`)

  // Helper function to get the current text-stroke style of the selection
  const getTextOutline = () => {
    const node = editor.selection.getNode()

    const getStyle = (node, style) => editor.dom.getStyle(node, style)

    const checkNodeStyles = (node) => {
      const stroke = getStyle(node, '-webkit-text-stroke')

      if (stroke) {
        const strokeParts = stroke.split(' ')
        const width = strokeParts[0] || '1px'

        // Extract color from stroke if in rgb format and join into single string
        let color = strokeParts.slice(1).join(' ') || 'black'

        return { width, color }
      }

      let result = null
      Array.from(node.children).forEach((child) => {
        if (!result) {
          result = checkNodeStyles(child)
        }
      })

      return result
    }

    const result = checkNodeStyles(node)

    return result || { width: '1px', color: 'black' }
  }

  const removeTextOutlineFromParent = (node) => {
    let parent = node.closest('p')

    if (parent) {
      removeTextOutlineRecursive(parent)
    } else {
      removeTextOutlineRecursive(node)
      console.log('No <p> tag found in the ancestor chain.')
    }
  }

  // Helper function to recursively remove the text-stroke from all child nodes
  const removeTextOutlineRecursive = (node) => {
    if (node.nodeType === 1) {
      editor.dom.setStyle(node, '-webkit-text-stroke', null)
      editor.dom.setStyle(node, '-webkit-text-fill-color', null)
      Array.from(node.children).forEach(removeTextOutlineRecursive)
    }
  }

  // Apply or remove the text-stroke style
  const applyTextOutline = (outlineWidth, color) => {
    editorFunctions.unlockNonEditableBlocks()
    if (outlineWidth === 'remove') {
      editorFunctions.unlockNonEditableBlocks()
      removeTextOutlineFromParent(editor.selection.getNode())
      editorFunctions.lockNonEditableBlocks()
    } else {
      // Apply both outline width and fill color
      editor.formatter.register('customTextOutline', {
        inline: 'span',
        classes: ['outline'],
        styles: {
          '-webkit-text-stroke': `${outlineWidth} ${color}`,
          //'-webkit-text-fill-color': 'white',
        },
      })
      editor.formatter.apply('customTextOutline')
    }
    editorFunctions.lockNonEditableBlocks()
  }

  // Add a button for removing the outline
  editor.ui.registry.addButton('removeOutline', {
    text: 'Remove Outline',
    onAction: () => {
      applyTextOutline('remove', 'black')
    },
  })

  // Add a menu button to select outline width
  editor.ui.registry.addMenuButton('outline', {
    text: 'Text Outline',
    fetch: (callback) => {
      const currentOutline = getTextOutline()
      const items = outlineStyles.map((outline) => ({
        type: 'menuitem',
        text: `${outline}`,
        onAction: () => applyTextOutline(outline, currentOutline.color),
        active: currentOutline.width === outline,
      }))

      // Add an option to choose a custom outline color
      items.push({
        type: 'menuitem',
        text: 'Choose Outline Color',
        onAction: () => {
          // Open color picker dialog
          editor.windowManager.open({
            title: 'Pick Outline Color',
            body: {
              type: 'panel',
              items: [
                {
                  type: 'colorinput',
                  name: 'outlineColor',
                  label: 'Select Color',
                  value: currentOutline.color,
                },
              ],
            },
            buttons: [
              {
                type: 'cancel',
                name: 'cancel',
                text: 'Cancel',
              },
              {
                type: 'submit',
                name: 'submit',
                text: 'Apply',
                primary: true,
              },
            ],
            onSubmit: (api) => {
              const data = api.getData()
              applyTextOutline(currentOutline.width, data.outlineColor)
              api.close()
            },
          })
        },
      })

      // Add an option to remove the outline
      items.push({
        type: 'menuitem',
        text: 'Remove Outline',
        onAction: () => applyTextOutline('remove', 'black'),
        active: !currentOutline.width,
      })

      callback(items)
    },
  })
}
