import { axios } from 'api'

// NOTE: Endpoint is missing in backend
export const create = async (payload) => {
  try {
    const response = await axios.post(`createTukiosFilm`, payload)
    return response

    //expected payload
    //{
    //   order_id: <uuid>,
    //   case_id: <uuid>,
    //   date_of_birth: <date>,
    //   date_of_death: <date>,
    //   name_of_deceased: <string>
    // }
  } catch (error) {
    throw error
  }
}
