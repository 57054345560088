import React from 'react'
import * as S from './LoadingSpinner.styled'

const LoadingSpinner = () => {
  return (
    <S.SpinnerContainer>
      <S.SpinnerCircle />
    </S.SpinnerContainer>
  )
}

export default LoadingSpinner
