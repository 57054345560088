import {
  themeAtom,
  dateFormatAtom,
  funeralHomeAtom,
  selectedThemeAtom,
} from 'atoms'
import { useRecoilValue } from 'recoil'
import { applyStylingToNonEditable, customToolbarButtons } from 'utils'
import {
  toolbarSettings,
  tinyMCEFonts,
  loadLocalPlugins,
  insertDetailsMenu,
} from '.'
import fontColors from 'components/global/TinyMCE/fontColors'

export default (init = {}, inline = true, caseDetails, isVerse) => {
  const theme = useRecoilValue(themeAtom)
  const dateFormat = useRecoilValue(dateFormatAtom)
  const funeralHomeDetails = useRecoilValue(funeralHomeAtom)
  const selectedTheme = useRecoilValue(selectedThemeAtom)

  const editorData = {
    caseDetails,
    funeralHomeDetails,
    dateFormat,
    selectedTheme,
    isVerse,
  }

  return {
    height: '100%',
    width: '100%',
    browser_spellcheck: true,
    inline: inline,

    menubar: false,
    statusbar: false,
    toolbar: toolbarSettings(inline),
    setup: (editor) => {
      const editorProps = { editor, ...editorData }
      return setupTinyMCE(editorProps)
    },
    fontsize_formats:
      '5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 40pt 42pt 46pt 48pt 50pt 55pt 60pt 65pt 70pt 75pt 80pt 85pt 90pt 95pt 100pt 110pt 120pt 200pt 210pt 220pt 300pt 310pt 320pt 340pt 350pt 400pt 410pt 420pt 430pt 440pt',

    skin: theme === 'dark' ? 'oxide-dark' : 'oxide',
    content_css: theme === 'dark' ? 'dark' : 'default',
    color_map: [...fontColors({ selectedTheme })],

    plugins: [
      'image',
      'template',
      'noneditable',
      'table',
      'paste',
      'hr',
      'casedetails',
    ],
    paste_as_text: true,
    lineheight_formats:
      '0.5 0.75 1 1.25 1.5 1.75 2 2.25 2.5 2.75 3 3.25 3.5 3.75 4',

    font_formats: tinyMCEFonts,

    ...init,
  }
}

const setupTinyMCE = (editorProps) => [
  loadLocalPlugins(editorProps),
  applyStylingToNonEditable(editorProps),
  insertDetailsMenu(editorProps),
  customToolbarButtons(editorProps),
]
