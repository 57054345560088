import { default as _set } from 'lodash/set'
import { produce } from 'immer'

const handleServiceDefault = {
  checking: (variables) => {
    // if checking an unchecked box
    const { name, path, set, newValue, fieldValue, caseValuesAtom } = variables

    const newField = produce(fieldValue, (draft) => {
      // set all checkboxes to false
      const services = fieldValue.value
      services.forEach((_, i) => _set(draft, `value[${i}].default`, false))

      // set clicked checkbox to true
      _set(draft, path, newValue)
    })

    // set the checkboxes accordingly
    set(caseValuesAtom(name), newField)
  },

  unchecking: (variables) => {
    // if unchecking a checked box
    const { name, path, set, newValue, fieldValue, caseValuesAtom } = variables

    // ignore the logic below if there is only one default checkbox available
    const hasOnlyOneService = fieldValue.value.length === 1
    if (hasOnlyOneService) return

    const newField = produce(fieldValue, (draft) => {
      // determine the index of the checkbox that the user is unchecking
      const serviceIndex = path[6]

      // determine if it is the first checkbox or not
      const isUncheckingFirstCheckbox = serviceIndex === '0'

      // place the new checked default on the first or second checkbox
      // depending on if the first is already checked or not
      const newDefaultCheckbox = isUncheckingFirstCheckbox
        ? 'value[1].default'
        : 'value[0].default'

      // set the new checked checkbox
      _set(draft, newDefaultCheckbox, true)

      // set the box being unchecked to false
      _set(draft, path, newValue)
    })

    // set the checkboxes accordingly
    set(caseValuesAtom(name), newField)
  },
}

export default (variables) => {
  const { name, path, newValue, setCaseDetail } = variables

  // determining if the case detail being set is a service checkbox
  const isServices = name === 'services'
  const isCheckBox = path.includes('default')
  const isServiceCheckbox = isServices && isCheckBox

  // determine if the user is checking or unchecking a service checkbox
  const checkingDefaultService = isServiceCheckbox && newValue
  const uncheckingDefaultService = isServiceCheckbox && !newValue

  // determine action needed
  checkingDefaultService && handleServiceDefault.checking(variables)
  uncheckingDefaultService && handleServiceDefault.unchecking(variables)

  // if it is not a service checkbox, proceed as usual
  !isServiceCheckbox && setCaseDetail()
}
