import React from 'react'
import { Flex, Text, Player, Button, Link } from 'components'

export default () => (
  <Flex full column>
    <Text size="24px" weight="600" margin="0 0 8px">
      Videos
    </Text>
    <Player url="https://vimeo.com/248145490" height="296px" width="544px" />
    <Text margin="8px 0 48px" weight="500">
      Creating a Print Package - Full Walk Through
    </Text>
    <Flex margin="0 0 32px">
      <Flex column margin="0 16px 0 0">
        <Player
          url="https://vimeo.com/showcase/4972698"
          height="144px"
          width="256px"
        />
        <Text margin="8px 0" weight="500">
          Lifetime Expressions Printing Videos
        </Text>
        <Text>12 Videos | 36:16</Text>
      </Flex>
      <Flex column margin="0 0 0 16px">
        <Player
          url="https://vimeo.com/showcase/4972694"
          height="144px"
          width="256px"
        />
        <Text margin="8px 0" weight="500">
          Reflections Of Life Printing Videos
        </Text>
        <Text>8 Videos | 22:35</Text>
      </Flex>
    </Flex>
    <Flex margin="0 0 48px">
      <Flex column margin="0 16px 0 0">
        <Player
          url="https://vimeo.com/showcase/4970379"
          height="144px"
          width="256px"
        />
        <Text margin="8px 0" weight="500">
          Precious Memories Printing Videos
        </Text>
        <Text>23 Videos | 1:14:51</Text>
      </Flex>
      <Flex column margin="0 0 0 16px">
        <Player
          url="https://vimeo.com/showcase/4966414"
          height="144px"
          width="256px"
        />
        <Text margin="8px 0" weight="500">
          Director's Print Suite Tutorials
        </Text>
        <Text>18 Videos | 1:44:53</Text>
      </Flex>
    </Flex>
    <Flex width="544px" margin="0 0 48px" justify="center">
      <Link href="https://vimeo.com/bassmollett/albums">
        <Button primary icon="externalLink">
          See all videos on Vimeo
        </Button>
      </Link>
    </Flex>
  </Flex>
)
