import { css } from 'styled-components'
import { colors as c } from 'utils'
import { svgFill, disabled } from 'styles'
import type { CSS, ElementStyles, StyledFunction } from 'types/style'
import { DisabledProps } from 'styles/disabled'

const common: CSS = css<DisabledProps>`
  border-radius: 2px;
  ${disabled as StyledFunction<DisabledProps>}
`

const elements: ElementStyles = {
  default: css`
    ${common}
    ${svgFill(c('gray1'))}
    background: ${c('white')};
    color: ${c('gray1')};
    border: 1px solid ${c('gray4')};
    &:hover {
      background: ${c('gray5')};
    }
  `,

  primary: css`
    ${common}
    ${svgFill(c('lockedWhite'))}
    background: ${c('brand')};
    color: ${c('lockedWhite')};
    border: 1px solid ${c('brand')};
    &:hover {
      background: ${c('brand', 90)};
    }
  `,

  secondary: css`
    ${common}
    ${svgFill(c('lockedWhite'))}
    background: ${c('secondary')};
    color: ${c('lockedWhite')};
    border: 1px solid ${c('secondary')};
    &:hover {
      background: ${c('secondary', 90)};
    }
  `,

  outline: css`
    ${common}
    ${svgFill(c('lockedWhite'))}
    background: transparent;
    color: ${c('lockedWhite')};
    border: 1px solid ${c('lockedGray2')};
    &:hover {
      background: ${c('lockedWhite', 10)};
    }
  `,

  destructive: css`
    ${common}
    ${svgFill(c('lockedWhite'))}
    background: ${c('error')};
    color: ${c('lockedWhite')};
    border: 1px solid ${c('error')};
    &:hover {
      background: ${c('error', 90)};
    }
  `,
}

export default elements
