import styled, { css } from 'styled-components'
import { disabled } from 'styles'
import { Flex } from 'components'

export const ToggleSwitch = styled(Flex)(
  ({ labelRight }) => css`
    ${disabled}
    ${labelRight ? 'flex-direction: row-reverse;' : ''}
  `,
)
