import React from 'react'
import { FileDropzone, Flex } from 'components'

export default () => {
  return (
    <>
      <Flex column align="center">
        <FileDropzone
          text="Drag and drop case images here"
          onDrop={(files) => console.log(files)}
          fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
          allowMultiple
        />
      </Flex>
    </>
  )
}
