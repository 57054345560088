import { css } from 'styled-components'
import type { StyledFunction } from 'types/style'

type ImageStyleProps = {
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  objectPosition?: string
  filter?: string
}

/**
 * Image styling utility for styled-components
 * @example
 * ```tsx
 * const StyledImage = styled.img`
 *   ${image({
 *     objectFit: 'contain',
 *     objectPosition: 'center',
 *     filter: 'brightness(0.8)'
 *   })}
 * `
 * // Usage in component:
 * <Image
 *   src="/image.jpg"
 *   objectFit="contain"
 *   objectPosition="center"
 *   filter="brightness(0.8)"
 * />
 * ```
 */
const image: StyledFunction<ImageStyleProps> = ({
  objectFit,
  objectPosition,
  filter,
}) => css`
  object-fit: ${objectFit};
  object-position: ${objectPosition};
  filter: ${filter};
`

export default image
