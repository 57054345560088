import { css } from 'styled-components'
import { featheredShape } from 'styles'
import type { CSS } from 'types/style'

export type FeatherProps = {
  edits: {
    feather?: boolean
    featherStrength?: number
    circle?: boolean
    borderRadius?: number
  }
  width?: number
  height?: number
}

const feather = ({ edits, width, height }: FeatherProps): CSS => css`
  mask: url(${`"${featheredShape(edits, width, height)}"`});
  background-size: cover;
  mask-size: 100% 100%;
  border-radius: 0;
  border: none;
`

export default (edits: FeatherProps['edits']) => edits?.feather && feather
