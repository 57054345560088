import React from 'react'
import { Flex, Text } from 'components'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { useRecoilState, useRecoilValue } from 'recoil'
import { itemSelector } from 'atoms'
import { roleAtom } from 'atoms'

export const LockAsset = ({ selectedItem }) => {
  const userRole = useRecoilValue(roleAtom)
  const [assetLocked, setAssetLocked] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'assetLocked' }),
  )

  if (userRole !== 'messenger-user') {
    return null
  }

  return (
    <>
      <Text margin="0 0 16px">Lock asset</Text>
      <Flex margin="0 0 24px">
        <ToggleSwitch
          label="Prevents user from editing"
          value={[assetLocked, setAssetLocked]}
        />
      </Flex>
    </>
  )
}
