import {
  firstLetterTemplate,
  serviceTemplate,
  yearTemplate,
} from 'utils/tinyMCE/templates'

export default (editorProps) => {
  const templates = []

  const insertableProps = { ...editorProps, templates }

  const handlers = [
    // handleAdditionalFamily,
    // handleMemorialContributions,
    // handleOrganizations,
    handleServices,
    handleBirthYear,
    handleDeathYear,
    handleFirstLetterFirstName,
    handleFirstLetterMiddleName,
    handleFirstLetterLastName,
  ]

  handlers.forEach((handler) => handler(insertableProps))

  return templates
}

const createYearHandler = (fieldName, menuText) => (insertableProps) => {
  const { editor, caseDetails, templates } = insertableProps
  const date = caseDetails[fieldName]
  const isDate = date instanceof Date
  if (!date || !isDate) return

  const templateProps = {
    fieldName,
  }

  const yearHtml = yearTemplate({
    editorProps: insertableProps,
    templateProps,
    firstInsertion: true,
  })

  templates.push({
    type: 'menuitem',
    text: menuText,
    onAction: () => editor.execCommand('mceInsertTemplate', false, yearHtml),
  })
}

const createNameLetterHandler = (fieldName, menuText) => (insertableProps) => {
  const { editor, caseDetails, templates } = insertableProps
  const name = caseDetails[fieldName]
  if (!name || typeof name !== 'string' || name.trim() === '') return

  const templateProps = {
    fieldName,
  }

  const letterHtml = firstLetterTemplate({
    editorProps: insertableProps,
    templateProps,
    firstInsertion: true,
  })

  templates.push({
    type: 'menuitem',
    text: menuText,
    onAction: () => editor.execCommand('mceInsertTemplate', false, letterHtml),
  })
}

// const handleAdditionalFamily = (insertableProps) => {
//   const { editor, caseDetails, templates } = insertableProps
//   const additionalFamily = caseDetails.additional_family || []
//   if (additionalFamily.length === 0) return

//   additionalFamily.forEach((family, i) => {
//     const templateProps = {
//       familyIndex: i,
//     }

//     const familyName = family.name || `Family ${i + 1}`

//     const familyHtml = serviceTemplate({
//       editorProps: insertableProps,
//       templateProps,
//     })

//     templates.push({
//       type: 'menuitem',
//       text: familyName,
//       onAction: () =>
//         editor.execCommand('mceInsertTemplate', false, familyHtml),
//     })
//   })
// }

// const handleMemorialContributions = (insertableProps) => {
//   const { editor, caseDetails, templates } = insertableProps
//   const memorialContributions = caseDetails.memorial_contributions || []
//   if (memorialContributions.length === 0) return

//   memorialContributions.forEach((contribution, i) => {
//     const templateProps = {
//       contributionIndex: i,
//     }

//     const contributionName = contribution.name || `Contribution ${i + 1}`

//     const contributionHtml = serviceTemplate({
//       editorProps: insertableProps,
//       templateProps,
//     })

//     templates.push({
//       type: 'menuitem',
//       text: contributionName,
//       onAction: () =>
//         editor.execCommand('mceInsertTemplate', false, contributionHtml),
//     })
//   })
// }

// const handleOrganizations = (insertableProps) => {
//   const { editor, caseDetails, templates } = insertableProps
//   const organizations = caseDetails.organizations || []
//   if (organizations.length === 0) return

//   organizations.forEach((organization, i) => {
//     const templateProps = {
//       organizationIndex: i,
//     }

//     const organizationName = organization.name || `Organization ${i + 1}`

//     const organizationHtml = serviceTemplate({
//       editorProps: insertableProps,
//       templateProps,
//     })

//     templates.push({
//       type: 'menuitem',
//       text: organizationName,
//       onAction: () =>
//         editor.execCommand('mceInsertTemplate', false, organizationHtml),
//     })
//   })
// }

const handleServices = (insertableProps) => {
  const { editor, caseDetails, templates } = insertableProps
  const services = caseDetails.services || []
  if (services.length === 0) return

  services.forEach((service, i) => {
    const templateProps = {
      serviceIndex: i,
    }

    const location = service.location
    const serviceName = location ? `Service: ${location}` : `Service ${i + 1}`

    const serviceHtml = serviceTemplate({
      editorProps: insertableProps,
      templateProps,
      firstInsertion: true,
    })

    templates.push({
      type: 'menuitem',
      text: serviceName,
      onAction: () =>
        editor.execCommand('mceInsertTemplate', false, serviceHtml),
    })
  })
}

const handleBirthYear = createYearHandler('date_of_birth', 'Year of Birth')
const handleDeathYear = createYearHandler('date_of_death', 'Year of Death')

const handleFirstLetterFirstName = createNameLetterHandler(
  'first_dec',
  'First Letter of First Name',
)

const handleFirstLetterMiddleName = createNameLetterHandler(
  'middle_dec',
  'First Letter of Middle Name',
)

const handleFirstLetterLastName = createNameLetterHandler(
  'lastdec',
  'First Letter of Last Name',
)
