import { calculateCaseAge, isoStringToDate, convertObjectOfDates } from 'utils'
import { caseDates as dates } from 'mocks'
import type { Case, CaseDate } from 'types/case'

type Service = {
  [key: string]: any
}

const sanitizeCaseDates = (originalCase: Case): Case => {
  const _case = JSON.parse(JSON.stringify(originalCase))

  // calculate the ages, and add it to the case object
  calculateCaseAge(_case as Case)

  // convert all dates in the case object
  convertObjectOfDates(dates.case as CaseDate[], _case as Case, isoStringToDate)

  // convert all dates in the case's service array
  _case?.services?.forEach((service: Service) =>
    convertObjectOfDates(dates.service, service, isoStringToDate),
  )

  return _case
}

export default sanitizeCaseDates
