import { handleHtmlData } from 'utils/global'
import getThemeStylesForTemplate from 'utils/tinyMCE/templates/getThemeStylesForTemplate'

export default ({ editorProps, templateProps, firstInsertion = false }) => {
  const { caseDetails, selectedTheme } = editorProps
  const { fieldName } = templateProps

  const templateData = { fieldName }
  const encodedProperties = handleHtmlData.encode(templateData)

  const date = caseDetails[fieldName]

  if (!date) return ''

  const formattedDate = new Date(date)
  const fullYear = formattedDate.getFullYear()
  const yearParagraph = `<p><span class="mceNonEditable">${fullYear}</span></p>`

  let themeStyles = ''

  /* Apply theme styling if:
   * - This is a new template insertion
   * - Theme object is not null
   */
  if (firstInsertion && selectedTheme) {
    themeStyles = getThemeStylesForTemplate({ selectedTheme })
  }

  const html = `
    <div data-template="${fieldName}_year" data-properties="${encodedProperties}" class="mceNonEditable" style="${themeStyles}">
      ${yearParagraph}
    </div>
  `

  return html
}
