import { caseDetailDiv } from '.'
import { replaceInitialValuesAndDates } from 'utils'
import startCase from 'lodash/startCase'
import getThemeStylesForTemplate from 'utils/tinyMCE/templates/getThemeStylesForTemplate'

export default (
  editor,
  caseDetails,
  key,
  templates,
  dateFormat,
  label,
  selectedTheme,
) => {
  let themeStyles = ''

  /* Apply theme styling if:
   * - This is a new obit insertion
   * - Theme object is not null
   */
  if (selectedTheme) {
    themeStyles = getThemeStylesForTemplate({ selectedTheme })
  }

  const content = replaceInitialValuesAndDates(
    caseDetailDiv(key, themeStyles),
    caseDetails,
    dateFormat,
  )

  const menuItem = {
    type: 'menuitem',
    text: label ?? `${startCase(key)}`,
    onAction: () => editor.execCommand('mceInsertTemplate', false, content),
  }

  templates.push(menuItem)
}
