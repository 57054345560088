const base = {
  x: 15,
  y: 15,
  z: 1,
  height: 200,
  width: 200,
  properties: {},
  manipulated: {
    userInserted: true,
    initialLoad: true,
  },
  edits: {},
}

export default {
  image: {
    ...base,
    type: 'image',
    image: {
      url: '',
      editedUrl: '',
      edits: {},
    },
  },
  designAsset: {
    ...base,
    type: 'image',
    assetLocked: true,
    image: {
      url: '',
      editedUrl: '',
      edits: {},
    },
  },
  text: {
    ...base,
    type: 'text',
    z: 20,
    text: {
      value: 'New Text Box',
    },
  },
  shape: {
    ...base,
    type: 'shape',
    edits: {
      backgroundColor: '#f4f8f8',
      opacity: '0.65',
      feather: true,
      featherStrength: 6,
    },
  },
  line: { ...base, type: 'line', height: 2 },
  qrCode: {
    ...base,
    type: 'qrCode',
    height: 200,
    width: 200,
    edits: {
      opacity: '1',
    },
  },
}
