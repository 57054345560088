import styled, { css } from 'styled-components'
import { Flex } from 'components'
import { colors as c } from 'utils'

export const Tab = styled(Flex)(
  ({ selected }) => css`
    background-color: ${selected && c('paleSecondary')};
    &:hover {
      background-color: ${c('paleSecondary')};
    }
  `,
)

export const Thumbnail = styled(Flex)(
  ({ thumbnail }) => css`
    background-image: url('${thumbnail}');
    background-size: contain;
    background-repeat: no-repeat;
  `,
)

export const TabScrollContainer = styled(Flex)`
  overflow-y: scroll;
`
