import React, { useState } from 'react'
import { CustomVerseEditorMenu, InsertCustomVerseMenu } from './CustomVerses'

export default ({ setShow }) => {
  const [creatingVerse, setCreatingVerse] = useState(false)

  return creatingVerse ? (
    <CustomVerseEditorMenu setCreatingVerse={setCreatingVerse} />
  ) : (
    <InsertCustomVerseMenu
      setShow={setShow}
      setCreatingVerse={setCreatingVerse}
    />
  )
}
