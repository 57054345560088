import styled, { css } from 'styled-components'
import { colors as c } from 'utils'
import { Flex } from 'components'

const activeBorder = css`
  border-bottom: 2px solid ${c('secondary')};
`

export const NavItem = styled(Flex)(
  ({ selected }) => css`
    border-bottom: 2px solid transparent;
    transition: 0.15s;

    ${selected && activeBorder}
    &:hover {
      ${activeBorder}
    }
  `,
)
