import React from 'react'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'

const DesignerGate = ({ children }) => {
  const userRole = useRecoilValue(roleAtom)
  if (!userRole || userRole !== 'messenger-user') return null

  return <>{children}</>
}

export default DesignerGate
