import { useMemo, useState, useEffect } from 'react'
import * as S from './Rulers.styled'
import { Flex } from 'components'
import { useRecoilValue } from 'recoil'
import { showRulerAtom, insetRulerAtom, pageZoomAtom } from 'atoms'

export default (props) => {
  const { width, height } = props

  const zoom = useRecoilValue(pageZoomAtom) / 100
  const horizontalInches = useMemo(() => createInchesArray(width), [width])
  const verticalInches = useMemo(() => createInchesArray(height), [height])

  const rulerInset = useRecoilValue(insetRulerAtom)
  const rulerProps = { rulerInset }

  const showRuler = useRecoilValue(showRulerAtom)

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const horizontalRuler = document.getElementById('horizontal-ruler')
    const verticalRuler = document.getElementById('vertical-ruler')

    const handleMouseMove = (event) => {
      const xOffset = horizontalRuler
        ? horizontalRuler.getBoundingClientRect().left
        : 0
      const yOffset = verticalRuler
        ? verticalRuler.getBoundingClientRect().top
        : 0

      const { clientX, clientY } = event
      setCursorPosition({
        x: (clientX - xOffset) / zoom,
        y: (clientY - yOffset) / zoom,
      })
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [zoom, showRuler])

  const showMark = (mark) => {
    const markIsZero = mark === 0
    if (markIsZero && rulerInset) return false
    return true
  }

  if (!showRuler) return null

  return (
    <>
      <S.HorizontalRuler {...props} {...rulerProps} id="horizontal-ruler">
        {horizontalInches.map((mark) => (
          <S.HorizontalInch mark={mark} {...rulerProps} key={mark}>
            <S.HorizontalQuarterInch {...rulerProps} />
            <S.HorizontalHalfInch {...rulerProps} />
            <S.HorizontalQuarterInch {...rulerProps} />
            <Flex padding="0 0 0 5px" position="absolute">
              {showMark(mark) && mark}
            </Flex>
          </S.HorizontalInch>
        ))}
        <S.HorizontalCursorLine
          style={{
            left: `${cursorPosition.x}px`,
          }}
        />
      </S.HorizontalRuler>

      <S.RulerCorner {...props} {...rulerProps} />

      <S.VerticalRuler {...props} {...rulerProps} id="vertical-ruler">
        {verticalInches.map((mark) => (
          <S.VerticalInch mark={mark} {...rulerProps} key={mark}>
            <S.VerticalQuarterInch {...rulerProps} />
            <S.VerticalHalfInch {...rulerProps} />
            <S.VerticalQuarterInch {...rulerProps} />
            <Flex padding="0 0 5px 0" position="absolute">
              {showMark(mark) && mark}
            </Flex>
          </S.VerticalInch>
        ))}
        <S.VerticalCursorLine
          style={{
            top: `${cursorPosition.y}px`,
          }}
        />
      </S.VerticalRuler>
    </>
  )
}

const createInchesArray = (dimensionSize) => {
  const oneInch = 96
  const inches = Math.floor(dimensionSize / oneInch)
  return [0, ...Array.from({ length: inches }, (_, i) => i + 1)]
}
