import React, { useState } from 'react'
import { determineDropdown } from 'utils'
import { HiddenMenu, Text, Menu, DropIcon } from 'components'
import * as S from './Dropdown.styled'

export default ({
  width,
  full,
  margin,
  label,
  color,
  selected: [selected, setSelected] = ['', null],
  placeholder,
  items,
  valueKey,
  textKey,
  fontMenu,
  useKeyAsValue,
  disabled,
  children,
  customLayout,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState()

  const dropdown = determineDropdown(
    items,
    valueKey,
    textKey,
    useKeyAsValue,
    selected,
    setSelected,
    customLayout,
  )

  return (
    <>
      <HiddenMenu
        full
        width={width}
        show={[menuOpen, setMenuOpen]}
        margin={margin}
        parentElement={() => (
          <S.DropdownWrapper column disabled={disabled}>
            {label && (
              <Text color={color} size="12px" margin="0 0 4px 0" weight="500">
                {label}
              </Text>
            )}
            <S.Dropdown
              full
              clickable
              padding="12px 16px"
              height="42px"
              minWidth="200px"
              justify="space-between"
              align="center"
              onClick={() => items && setMenuOpen(!menuOpen)}
              {...props}
            >
              <S.DropdownText full whiteSpace="nowrap">
                {selected && dropdown?.selectText
                  ? dropdown.selectText
                  : placeholder}
              </S.DropdownText>
              <DropIcon margin="0 0 0 8px" up={menuOpen} />
            </S.Dropdown>
          </S.DropdownWrapper>
        )}
        hiddenElement={() => (
          <Menu
            fontMenu={fontMenu}
            full
            items={dropdown?.items}
            show={[menuOpen, setMenuOpen]}
          >
            {children}
          </Menu>
        )}
      />
    </>
  )
}
