import { axios } from 'api'

export const getPages = async (pageset_id) => {
  try {
    const response = await axios.get(`pages?page_set_id=${pageset_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const createPage = async (payload) => {
  try {
    const response = await axios.post(`createPage`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const updatePage = async (page_id, payload) => {
  try {
    const response = await axios.patch(`pages/${page_id}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

// This endpoint is not used
export const archivePage = async (page_id) => {
  try {
    const response = await axios.patch(`archivePage/${page_id}`)
    return response
  } catch (error) {
    throw error
  }
}
