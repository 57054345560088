import React from 'react'
import { Flex } from 'components'
import RightContentHeader from './RightContentHeader'
import FamilyUploadTabs from './FamilyUploadTabs'

export default ({ _case }) => {
  return (
    <Flex
      width="100%"
      height="100vh"
      center
      padding="80px 0 0 0"
      overflow="auto"
    >
      <Flex column width="80%" height="100%">
        <RightContentHeader _case={_case} />
        <FamilyUploadTabs />
      </Flex>
    </Flex>
  )
}
