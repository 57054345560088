import { useRecoilValue, useRecoilCallback } from 'recoil'
import { selectedItemAtom, itemAtomFamily, itemIdAtom } from 'atoms'
import { v4 as uuidv4 } from 'uuid'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const itemIds = useRecoilValue(itemIdAtom)
  const item = useRecoilValue(itemAtomFamily(selectedItem))

  const duplicateItem = () => {
    const duplicatedItem = { ...item }
    const uuid = uuidv4()

    duplicatedItem.id = uuid
    duplicatedItem.x = parseInt(duplicatedItem.x) + 10
    duplicatedItem.y = parseInt(duplicatedItem.y) + 10
    addItem(duplicatedItem)
  }

  const addItem = useRecoilCallback(({ set }) => (duplicatedItem) => {
    set(itemAtomFamily(duplicatedItem.id), duplicatedItem)
    set(itemIdAtom, [...itemIds, duplicatedItem.id])
  })

  return () => duplicateItem()
}
