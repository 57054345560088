import { TableCell, TableActions } from 'components'

const rightAligned = { display: 'flex', justifyContent: 'flex-end' }

export default (itemsLoaded, archiveUser, resendEmail) => [
  // Deceased Name
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => (
      <TableCell text={value || '---'} loaded={itemsLoaded} />
    ),
  },

  // Stationery
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }) => <TableCell text={value} loaded={itemsLoaded} />,
  },

  // Stationery
  {
    Header: 'General Manager',
    accessor: 'manager',
    Cell: ({ value }) => (
      <TableCell text={value && 'Yes'} loaded={itemsLoaded} />
    ),
  },

  // Actions
  {
    Header: 'Actions',
    style: rightAligned,
    accessor: '',
    disableSortBy: true,
    Cell: ({ row }) => (
      <TableActions
        loaded={itemsLoaded}
        actions={[
          {
            text: 'Remove user',
            icon: 'archive',
            onClick: () => archiveUser(row.original.id),
          },
          {
            text: 'Resend email',
            icon: 'email',
            onClick: () => resendEmail(row.original.id),
          },
        ]}
      />
    ),
  },
]
