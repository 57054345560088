import {
  orderAtom,
  collectionsAtom,
  selectedProductLineAtom,
  selectedProductsAtom,
  selectedThemeAtom,
  caseAtom,
  caseFieldsAtom,
} from 'atoms'
import { useResetRecoilState } from 'recoil'

export default () => {
  const resetSelectedProductLine = useResetRecoilState(selectedProductLineAtom)
  const resetSelectedProducts = useResetRecoilState(selectedProductsAtom)
  const resetSelectedTheme = useResetRecoilState(selectedThemeAtom)
  const resetCase = useResetRecoilState(caseAtom)
  const resetOrder = useResetRecoilState(orderAtom)
  const resetCollection = useResetRecoilState(collectionsAtom)
  const resetCaseFieldsAtom = useResetRecoilState(caseFieldsAtom)

  return (skipCaseReset) => {
    resetSelectedProductLine()
    resetSelectedProducts()
    resetSelectedTheme()
    !skipCaseReset && resetCase()
    !skipCaseReset && resetCaseFieldsAtom()
    resetOrder()
    resetCollection()
  }
}
