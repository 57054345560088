import { isoStringToDate, convertObjectOfDates } from 'utils'
import { caseDates as dates } from 'mocks'
import type { StationDate } from 'types/case'

/**
 * Converts array of station data dates using the following methods: @link{convertObjectOfDates} @link{isoStringToDate}
 *
 * @param {(string[] | null | undefined)} stations - Array of stringified station data, see shape here: @link{StationDate}
 * @returns {StationDate[]} Array of station data with parsed and date-converted dates.
 *
 * @example
 * ```typescript
 * const us_stations_where_served = ['{"departure_date": "2023-01-01T00:00:00Z", ...}']
 * const sanitizedStations = sanitizeCaseStations(us_stations_where_served)
 * // Returns: [{ departure_date: Date object, ... }]
 * ```
 *
 * @description
 * 1. Handles null/undefined input by returning an empty array
 * 2. Parses StationDate data into the same with dates being sanitized.
 * 3. Returns array of properly formatted case station data.
 */
const sanitizeCaseStations = (
  stations: string[] | null | undefined,
): StationDate[] => {
  if (!stations) return []
  if (stations?.length > 0) {
    return stations.map((station: string) => {
      const parsedStation = JSON.parse(station)
      convertObjectOfDates(dates.station, parsedStation, isoStringToDate)
      return parsedStation
    })
  }
  return []
}

export default sanitizeCaseStations
