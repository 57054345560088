import styled from 'styled-components'
import {
  flex,
  sizing,
  spacing,
  positioning,
  borders,
  decorations,
  gap,
} from 'styles'
import type { FlexStyledComponent, FlexStyledProps } from 'types/layout'

export const Flex: FlexStyledComponent = styled.div<FlexStyledProps>`
  ${flex}
  ${sizing}
  ${spacing}
  ${positioning}
  ${borders}
  ${decorations}
  ${gap}
`
