import React, { useEffect, useState } from 'react'
import { Navigation } from 'components'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'hooks'
import { SupportDashboard, SupportHeader } from './'
import capitalize from 'lodash/capitalize'

export default () => {
  let { tab } = useParams()
  const [activeTab, setActiveTab] = useState(tab)
  const navigate = useNavigate()

  useEffect(() => {
    setActiveTab(tab)
  }, [tab])

  useEffect(() => {
    navigate(`/support/${activeTab.toLowerCase()}`)
    // eslint-disable-next-line
  }, [activeTab])

  return (
    <>
      <Navigation />
      <SupportHeader />
      <SupportDashboard
        width="310px"
        activeTab={[capitalize(activeTab), setActiveTab]}
      />
    </>
  )
}
