import {
  insertableCaseDetails,
  insertableCaseDetailTables,
  insertableFuneralHomeDetails,
  insertableTemplates,
} from 'utils'

export default (editorProps) => {
  const { editor } = editorProps
  editor.ui.registry.addMenuButton('dynamicText', {
    text: 'Insert detail',
    fetch: (callback) => callback(populateInsertMenu(editorProps)),
  })
}

const populateInsertMenu = (editorProps) => {
  const templates = insertableTemplates(editorProps)
  const caseDetails = insertableCaseDetails(editorProps)
  const caseDetailTables = insertableCaseDetailTables(editorProps)
  const funeralHomeDetails = insertableFuneralHomeDetails(editorProps)

  return [
    ...caseDetails,
    ...caseDetailTables,
    ...funeralHomeDetails,
    ...templates,
  ]
}
