import React from 'react'
import { Flex } from 'components'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { useRecoilState } from 'recoil'
import {
  showRulerAtom,
  insetRulerAtom,
  outsideCanvasItemsVisibleAtom,
  showRedMarginAtom,
} from 'atoms'

export default () => {
  const [showRuler, setShowRuler] = useRecoilState(showRulerAtom)
  const [showRedMargin, setShowRedMargin] = useRecoilState(showRedMarginAtom)
  const [insetRuler, setInsetRuler] = useRecoilState(insetRulerAtom)
  const [outsideCanvasItemsVisible, setOutsideCanvasItemsVisible] =
    useRecoilState(outsideCanvasItemsVisibleAtom)

  return (
    <Flex column gap="32px" padding="0px 24px">
      <Flex column gap="12px">
        <ToggleSwitch
          value={[showRuler, setShowRuler]}
          label="Show ruler"
          labelRight
        />
        <ToggleSwitch
          value={[insetRuler, setInsetRuler]}
          label="Inset ruler"
          labelRight
          disabled={!showRuler}
        />
      </Flex>
      <ToggleSwitch
        value={[outsideCanvasItemsVisible, setOutsideCanvasItemsVisible]}
        label="Show Design Canvas"
        labelRight
      />
      <ToggleSwitch
        value={[showRedMargin, setShowRedMargin]}
        label="Show Red Margin"
        labelRight
      />
    </Flex>
  )
}
