export const alignBaseline = require('./alignBaseline.svg').default
export const alignBottom = require('./alignBottom.svg').default
export const alignCenter = require('./alignCenter.svg').default
export const alignLeft = require('./alignLeft.svg').default
export const alignRight = require('./alignRight.svg').default
export const alignTop = require('./alignTop.svg').default
export const anchorArrow = require('./anchorArrow.svg').default
export const archive = require('./archive.svg').default
export const arrowDown = require('./arrowDown.svg').default
export const arrowUp = require('./arrowUp.svg').default
export const calendar = require('./calendar.svg').default
export const check = require('./check.svg').default
export const chevronDown = require('./chevronDown.svg').default
export const chevronLeft = require('./chevronLeft.svg').default
export const chevronRight = require('./chevronRight.svg').default
export const chevronUp = require('./chevronUp.svg').default
export const clock = require('./clock.svg').default
export const deleteOutline = require('./deleteOutline.svg').default
export const deleteSolid = require('./deleteSolid.svg').default
export const designAssets = require('./designAssets.svg').default
export const duplicate = require('./duplicate.svg').default
export const editorImage = require('./editorImage.svg').default
export const editorLine = require('./editorLine.svg').default
export const editorNextPage = require('./editorNextPage.svg').default
export const editorOvalImage = require('./editorOvalImage.svg').default
export const editorPreviousPage = require('./editorPreviousPage.svg').default
export const editorRedo = require('./editorRedo.svg').default
export const editorShape = require('./editorShape.svg').default
export const editorShuffler = require('./editorShuffler.svg').default
export const editorSquareImage = require('./editorSquareImage.svg').default
export const editorText = require('./editorText.svg').default
export const editorUndo = require('./editorUndo.svg').default
export const editorZoomIn = require('./editorZoomIn.svg').default
export const editorZoomOut = require('./editorZoomOut.svg').default
export const email = require('./email.svg').default
export const exitModal = require('./exitModal.svg').default
export const externalLink = require('./externalLink.svg').default
export const film = require('./film.svg').default
export const flipHorizontal = require('./flipHorizontal.svg').default
export const flipVertical = require('./flipVertical.svg').default
export const importCase = require('./importCase.svg').default
export const info = require('./info.svg').default
export const ellipses = require('./ellipses.svg').default
export const pageShuffleDrag = require('./pageShuffleDrag.svg').default
export const pencil = require('./pencil.svg').default
export const pdf = require('./pdf.svg').default
export const phone = require('./phone.svg').default
export const plus = require('./plus.svg').default
export const qrCode = require('./qrCode.svg').default
export const makeDefault = require('./makeDefault.svg').default
export const reset = require('./reset.svg').default
export const rotateLeft = require('./rotateLeft.svg').default
export const rotateRight = require('./rotateRight.svg').default
export const schedule = require('./schedule.svg').default
export const search = require('./search.svg').default
export const semiCheck = require('./semiCheck.svg').default
export const speechBubble = require('./speechBubble.svg').default
export const support = require('./support.svg').default
export const thumbtack = require('./thumbtack.svg').default
export const upload = require('./upload.svg').default
export const videoCall = require('./videoCall.svg').default
export const welcomeEmail = require('./welcomeEmail.svg').default
export const welcomePhone = require('./welcomePhone.svg').default
export const swap = require('./swap.svg').default
