import React, { useState, useEffect } from 'react'
import { Flex, Text, Button, FileDropzone, Dropdown } from 'components'
import * as S from './ThemeEditorContent.styled'
import { getImageDimensions } from 'utils'
import {
  uploadOptimizedThemeImage,
  uploadOptimizedReplacementImage,
  updateThemeLayout,
} from 'api/customThemes'
import { useSetToast } from 'hooks'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ product, theme, getCustomThemeLayouts, main }) => {
  const handleError = useErrorHandler()
  const [replace, setReplace] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [layout, setLayout] = useState(null)
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [imageDimensions, setImageDimensions] = useState({
    width: '650px',
    height: '450px',
  })
  const setToast = useSetToast()

  const getDimensions = async (imgSrc) => {
    const d = await getImageDimensions(imgSrc)
    let dimensions = {}
    if (d.width > d.height) {
      dimensions = {
        width: '650px',
        height: `${650 * (d.height / d.width)}px`,
      }
    } else if (d.width < d.height) {
      dimensions = {
        width: `${450 * (d.width / d.height)}px`,
        height: '450px',
      }
    }
    setImageDimensions(dimensions)
    return d
  }

  const uploadMainThemeImage = async (file) => {
    try {
      setUploading(true)
      let fd = new FormData()
      fd.append('file', file[0], 'blob')
      const resp = await uploadOptimizedThemeImage(theme.id, fd)
      theme.image = resp.data
      if (!theme.image) {
        setUploading(false)
        return
      }
      setReplace(false)
      const d = await getDimensions(
        `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${theme.image}`,
      )
      await Promise.all(
        product?.layouts.map(async (layout) => {
          const imgRatio = parseInt(d.width) / parseInt(d.height)
          const height = parseFloat(layout.theme_height) * 96
          const width = parseFloat(layout.theme_width) * 96
          const containRatio = width / height
          if (
            !isNaN(containRatio) &&
            (!layout.background_fill ||
              layout?.background_fill?.indexOf('105') > -1)
          ) {
            layout.background_fill =
              containRatio >= imgRatio ? '105% auto' : 'auto 105%'

            layout.background_left = !layout.background_left
              ? '0px'
              : layout.background_left

            layout.background_top = !layout.background_top
              ? '0px'
              : layout.background_top
          }
          const layoutPayload = {
            background_fill: layout.background_fill,
            background_top: layout.background_top,
            background_left: layout.background_left,
          }
          await updateThemeLayout(layout.id, layoutPayload)
          return product
        }),
      )
      main && getCustomThemeLayouts()
      setUploading(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const uploadReplacementImage = async (file) => {
    try {
      setUploading(true)
      let fd = new FormData()
      fd.append('file', file[0], 'blob')
      const resp = await uploadOptimizedReplacementImage(selectedLayout.id, fd)
      if (!resp) {
        setUploading(false)
        return
      }
      const d = await getImageDimensions(
        `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${resp.data}`,
      )
      const imgRatio = parseInt(d.width) / parseInt(d.height)
      const height = parseFloat(selectedLayout.theme_height) * 96
      const width = parseFloat(selectedLayout.theme_width) * 96
      const containRatio = width / height

      if (
        !isNaN(containRatio) &&
        (!selectedLayout.background_fill ||
          selectedLayout?.background_fill?.indexOf('105') > -1)
      ) {
        selectedLayout.background_fill =
          containRatio >= imgRatio ? '105% auto' : 'auto 105%'
      }
      const layoutPayload = {
        background_fill: selectedLayout.background_fill,
      }
      await updateThemeLayout(selectedLayout.id, layoutPayload)

      selectedLayout.replacement_image = resp.data

      product.layouts.forEach((layout) => {
        if (layout.id === selectedLayout.id)
          layout.replacement_image = resp.data
      })
      setUploading(false)
      setReplace(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const setComingSoon = () => {
    setToast({ text: 'Coming Soon!' })
  }

  useEffect(() => {
    theme?.image &&
      getDimensions(
        `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${theme.image}`,
      )
    //eslint-disable-next-line
  }, [theme])

  useEffect(() => {
    product?.layouts.length > 0 && setLayout(product.layouts[0].id)
    //eslint-disable-next-line
  }, [product])

  useEffect(() => {
    setSelectedLayout(product.layouts.find((i) => i.id === layout))
    //eslint-disable-next-line
  }, [layout])

  return (
    <Flex column>
      <Flex justify="space-between" margin="0 24px 24px 0">
        <Flex align="center">
          <Flex column>
            <Text size="24px" color="gray1" weight="600" margin="0 0 10px 0">
              {product.name}
            </Text>
            {main && (
              <Text margin="0 0 10px 0">
                This image will be applied to all products until individual
                products are customized.
              </Text>
            )}
          </Flex>
          {!main && (
            <Dropdown
              selected={[layout, setLayout]}
              placeholder="Layout:"
              textKey="name"
              valueKey="id"
              items={product.layouts}
              margin="0 16px 0 16px"
            />
          )}
        </Flex>
        <Flex align="center">
          {!main && (
            <>
              <Button margin="0 16px 0 0" onClick={() => setComingSoon()}>
                Crop & Resize
              </Button>
              {/* hide watermarks button for now per Duane 2021/11/02 */}
              {/* <Button
                margin="0 16px 0 0"
                onClick={() => console.log("watermarks modal")}
              >
                Watermarks
              </Button> */}
            </>
          )}
          <Button margin="0 16px 0 0" onClick={() => setComingSoon()}>
            Edit
          </Button>
          <Button margin="0 16px 0 0" onClick={() => setReplace(!replace)}>
            Replace
          </Button>
        </Flex>
      </Flex>
      <Flex full justify="center">
        {(!theme?.image || replace) && (
          <FileDropzone
            width="650px"
            text={`Drag and drop file here to edit`}
            fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
            disabled={uploading}
            onDrop={(file) => {
              main ? uploadMainThemeImage(file) : uploadReplacementImage(file)
            }}
          />
        )}

        {theme?.image && !replace && (
          <>
            {selectedLayout && !main && (
              <S.Theme
                theme={theme}
                main={main}
                layout={selectedLayout}
                width={`${selectedLayout.theme_width * 96 * 0.6}px`}
                height={`${selectedLayout.theme_height * 96 * 0.6}px`}
              />
            )}
            {main && (
              <S.Theme
                theme={theme}
                main={main}
                width={imageDimensions.width}
                height={imageDimensions.height}
                maxWidth="650px"
                maxHeight="450px"
                overflow="auto"
              />
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
