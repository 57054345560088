import styled, { css } from 'styled-components'
import { colors as c } from 'utils'

export const SortableItem = styled.li(
  ({ isDragged, isSelected, currentPage }) => css`
    padding: 16px;
    height: 125px;
    margin: 16px 16px;
    list-style-type: none;
    z-index: 11;
    cursor: ${isDragged && 'grabbing'};
    border: 2px solid ${currentPage ? c('brand') : c('gray4')};
    background-color: ${isDragged || isSelected
      ? c('palePrimary')
      : c('white')};
    &:hover {
      border: 2px solid ${c('gray3')};
    }
  `,
)

export const SortableList = styled.ul(
  ({ isDragged }) => css`
    padding: 0;
    cursor: ${isDragged ? 'grabbing' : undefined};
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
)
