import React, { useState } from 'react'
import { Flex, Text, DateInput } from 'components'
import { format } from 'date-fns'
import { convertTimeZones } from 'utils'

export default () => {
  const dateFormat = 'MM/dd/yyyy hh:mmaaa'
  const today = new Date()
  const todayUTC = convertTimeZones.toUTC(today)
  const todayUTCReverted = convertTimeZones.toLocal(todayUTC)

  const [date, setDate] = useState(today)

  return (
    <Flex margin="0 0 128px 0">
      <Flex
        column
        center
        full
        padding="48px"
        margin="0 48px 48px 0"
        radius="4px"
        background="paleSecondary"
      >
        <Text margin="0 0 48px 0">
          The playground is meant to expirament with components. Please clean it
          up before making a PR and have fun!
        </Text>

        {/* code here */}
        <DateInput date={[date, setDate]} left margin="0 0 24px 0" />
        <Flex column>
          <Text margin="0 0 12px 0">
            <b>Default new Date():</b> {format(today, dateFormat)}
          </Text>
          <Text margin="0 0 12px 0">
            <b>UTC new Date():</b> {format(todayUTC, dateFormat)}
          </Text>
          <Text>
            <b>UTC to local time:</b> {format(todayUTCReverted, dateFormat)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
