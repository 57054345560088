import { axios } from 'api'

/* Assets are structured into Categories (ie Clip Art), then SubCategories (ie Hobbies), then Asset itself (ie Ballet Shoes) 
the asset will have an subcategory_id -> the subcategory will have a category_id*/

//Asset Category
// NOTE: Endpoint not used
export const getAssetCategories = async () => {
  try {
    const response = await axios.get(`/assetCategories`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const getAssetCategoryById = async (asset_category_id) => {
  try {
    const response = await axios.get(`/assetCategories/${asset_category_id}`)
    return response
  } catch (error) {
    throw error
  }
}

//Asset Subcategory

//potential to get by name on this endpoint, we can add as needed
// NOTE: Endpoint not used
export const getAssetSubcategories = async () => {
  try {
    const response = await axios.get(`/assetSubcategories`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const getAssetSubcategoryById = async (asset_subcategory_id) => {
  try {
    const response = await axios.get(
      `/assetSubcategories/${asset_subcategory_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const getAssetsByCategory = async (asset_category_id) => {
  try {
    const response = await axios.get(
      `/assetSubcategories?category_id=${asset_category_id}&get_all_assets=true`,
    )
    return response
  } catch (error) {
    throw error
  }
}

//Assets

export const getAssetsByName = async (name) => {
  try {
    const response = await axios.get(`/assets?name=${name}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const getAssetsBySubCategory = async (subcategory_id) => {
  try {
    const response = await axios.get(`/assets?subcategory_id=${subcategory_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const getAssetById = async (asset_id) => {
  try {
    const response = await axios.get(`/assets/${asset_id}`)
    return response
  } catch (error) {
    throw error
  }
}
