import lockedPalette from './lockedPalette'
import type { Palette } from 'types/style'

const palettes: Palette = {
  brand: {
    default: '#1A6CAB',
    dark: '#1A6CAB',
  },
  secondary: {
    default: '#D7B566',
    dark: '#D7B566',
  },
  white: {
    default: '#FFFFFF',
    dark: '#1C2125',
  },
  gray1: {
    default: '#2F3133',
    dark: '#F3F5F9',
  },
  gray2: {
    default: '#797E83',
    dark: '#DBDEE6',
  },
  gray3: {
    default: '#B0B6BC',
    dark: '#B0B6BC',
  },
  gray4: {
    default: '#DBDEE6',
    dark: '#797E83',
  },
  gray5: {
    default: '#F3F5F9',
    dark: '#2F3133',
  },
  almostBlack: {
    default: '#1C2125',
    dark: '#FFFFFF',
  },
  palePrimary: {
    default: '#F4F8FB',
    dark: '#21272B',
  },
  paleSecondary: {
    default: '#FAF9F8',
    dark: '#171B1F',
  },
  error: {
    default: '#cc0000',
    dark: '#db4c4c',
  },
  FloatingMenu: {
    default: '#FFFFFF',
    dark: '#21272B',
  },
  // Values for these properties need to be supplied in RGB format without the rgb() function https://pqina.nl/pintura/docs/v8/api/style/
  pinturaBackground: {
    default: '255, 255, 255',
    dark: '28, 33, 37',
  },
  pinturaForeground: {
    default: '28, 33, 37',
    dark: '255, 255, 255',
  },
  ...lockedPalette,
}

export default palettes
