import { axios } from 'api'

export const getOrderProducts = async (collection_id, order_id) => {
  try {
    const response = await axios.get(
      `orderProductDetails?collection_id=${collection_id}&order_id=${order_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

export const createOrderProduct = async (payload) => {
  try {
    const response = await axios.post(`createOrderProductDetails`, payload)
    return response

    // expected payload (ALL REQUIRED):
    // {
    //   name: <String>,
    //   collection_id: <UUID>,
    //   product_id: <UUID>,
    //   order_id: <UUID>,
    //   thumbnail:<String (image-url)>,
    //   selected: <Boolean>,
    // }
  } catch (error) {
    throw error
  }
}

export const updateOrderProduct = async (order_product_id, payload) => {
  try {
    const response = await axios.patch(
      `orderProductDetails/${order_product_id}`,
      payload,
    )
    return response

    // expected payload (ALL OPTIONAL):
    // {
    //   name: <String>,
    //   collection_id: <UUID>,
    //   order_id: <UUID>,
    //   thumbnail:<String (image-url)>,
    //   selected: <Boolean>,
    // }
  } catch (error) {
    throw error
  }
}
