import React from 'react'
import { Text, Icon } from 'components'
import * as S from './Check.styled'

export default ({ checked, text, color, icon, ...props }) => (
  <S.Check clickable {...props}>
    <S.Box checked={checked} text={text} center>
      {checked && <Icon fill="white" icon={icon || 'check'} />}
    </S.Box>
    <Text color={color}>{text}</Text>
  </S.Check>
)
