const hasDecoration = (type, textDecorationMain, textDecorationSecond) =>
  [textDecorationMain, textDecorationSecond].some((decoration) =>
    decoration.includes(type),
  )

const applyFormat = (editor, condition, command, options = null) => {
  if (!condition) return
  try {
    if (options) {
      editor.formatter.apply(command, options)
    } else {
      editor.formatter.apply(command)
    }
  } catch (err) {
    console.error(`Failed to apply ${command}:`, err.message)
  }
}

const execCommand = (editor, condition, command, ...args) => {
  if (!condition) return
  try {
    editor.execCommand(command, ...args)
  } catch (err) {
    console.error(`Failed to execute ${command}:`, err.message)
  }
}

const hasAttribute = (element, attributes) => {
  if (!element || !Array.isArray(attributes)) return false
  const hasElementCaseDetailsAttribute = attributes.some((attr) =>
    element.hasAttribute(attr),
  )
  return hasElementCaseDetailsAttribute
}

export default (editorProps) => {
  const { editor, newBlockEvent } = editorProps

  const currentBlock = newBlockEvent.newBlock

  // Guard to validate if we have previous element
  if (
    !currentBlock.previousElementSibling ||
    !currentBlock.previousElementSibling.firstElementChild
  )
    return

  const previousSibling = currentBlock.previousElementSibling
  const siblingChild = previousSibling.firstElementChild

  const requiredAttributesToEvaluate = ['data-case-detail', 'data-template']

  const hasSiblingCaseDetailsAttribute = hasAttribute(
    previousSibling,
    requiredAttributesToEvaluate,
  )
  const hasSiblingChildCaseDetailsAttribute = hasAttribute(
    siblingChild,
    requiredAttributesToEvaluate,
  )

  // We only look for the case details elements to proceed
  if (!hasSiblingChildCaseDetailsAttribute && !hasSiblingCaseDetailsAttribute)
    return

  // There are cases that 2 spans are created when you handle underline AND strikethrough in a same text box
  const mainSpan = siblingChild?.querySelector('span')
  const secondSpan = mainSpan?.querySelector('span')

  const mainSpanStyle = mainSpan?.style ?? {}
  const secondSpanStyle = secondSpan?.style ?? {}

  const fontName = mainSpanStyle.fontFamily || null
  const fontSize = mainSpanStyle.fontSize || null
  const foreColor = mainSpanStyle.color || null
  const backColor = mainSpanStyle.backgroundColor || null

  // We get textAlign and lineHeight from sibling element since these props are always applied here (make sense)
  const textAlign = previousSibling.style.textAlign || null
  const lineHeight = previousSibling.style.lineHeight || null

  // Here we validate both text decoration possibilities (underline and striketrough). Both live in different spans
  const textDecorationMain = mainSpanStyle.textDecoration || ''
  const textDecorationSecond = secondSpanStyle.textDecoration || ''

  const textTransform = mainSpanStyle.textTransform || null

  const decorationElements = {
    bold: siblingChild.querySelector('strong'),
    italic: siblingChild.querySelector('em'),
  }

  const textStyles = {
    hasBold: !!decorationElements.bold,
    hasItalic: !!decorationElements.italic,
    hasUnderline: hasDecoration(
      'underline',
      textDecorationMain,
      textDecorationSecond,
    ),
    hasStrikethrough: hasDecoration(
      'line-through',
      textDecorationMain,
      textDecorationSecond,
    ),
  }

  applyFormat(editor, textStyles.hasBold, 'bold')
  applyFormat(editor, textStyles.hasItalic, 'italic')
  applyFormat(editor, textStyles.hasUnderline, 'underline')
  applyFormat(editor, textStyles.hasStrikethrough, 'strikethrough')
  applyFormat(editor, fontSize, 'fontsize', { value: fontSize })
  applyFormat(editor, fontName, 'fontname', { value: fontName })
  execCommand(editor, lineHeight, 'LineHeight', false, lineHeight)
  applyFormat(editor, foreColor, 'forecolor', { value: foreColor })
  applyFormat(editor, backColor, 'hilitecolor', { value: backColor })
  applyFormat(editor, textAlign, `align${textAlign}`)
  applyFormat(editor, textTransform, 'customTextTransform', {
    value: textTransform,
  })
}
