import styled, { css } from 'styled-components'
import { Flex, Text } from 'components'
import { colors as c } from 'utils'

export const UploadText = styled(Text)``

export const UploadSpan = styled.span`
  color: ${c('brand')};
  cursor: ${'pointer'};
`

export const FileDropzone = styled(Flex)(
  ({ isDragActive, disabled }) => css`
    opacity: ${disabled ? '.5' : '1'};
    pointer-events: ${disabled && 'none'};
    border: 1px dashed ${c(isDragActive ? 'secondary' : 'gray4')};
    &:active {
      border: 1px dashed ${c('secondary')};
    }
    &:active ${UploadText} {
      color: ${c('secondary')};
    }
  `,
)

export const HiddenInput = styled.input`
  outline: none;
`
