import React, { useRef } from 'react'
import { Flex, Input, TinyMCE } from 'components'

export default ({ reference, setReference, value }) => {
  const TinyMCERef = useRef(null)

  return (
    <Flex column margin="24px" align="flex-start">
      <Input
        margin="12px 0"
        label="Reference"
        value={[reference, setReference]}
      />
      <Flex
        border="1px solid"
        borderColor="gray4"
        radius="2px"
        minHeight="200px"
        margin="0 0 12px 0"
        padding="12px"
        full
      >
        <TinyMCE
          placeholder="Type text here"
          setRef={(ref) => (TinyMCERef.current = ref)}
          value={value}
        />
      </Flex>
    </Flex>
  )
}
