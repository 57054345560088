import React, { useState } from 'react'
import { Flex, Text, DropIcon, AnimatedHeight } from 'components'
import capitalize from 'lodash/capitalize'

export default ({
  title,
  subtitle,
  startOpen = false,
  children,
  doNotCapitalize,
  ...props
}) => {
  const [open, setOpen] = useState(startOpen)

  return (
    <Flex borderBottom="1px solid" borderColor="gray4" column {...props}>
      <Flex
        justify="space-between"
        align="center"
        height="54px"
        padding="0 12px"
        open={open}
        onClick={() => setOpen(!open)}
        clickable
      >
        <Flex>
          <Text weight="600">
            {doNotCapitalize ? `${title}` : capitalize(title)}
          </Text>
          {subtitle && (
            <Text margin="0 0 0 6px" color="gray2">
              {capitalize(subtitle)}
            </Text>
          )}
        </Flex>
        <DropIcon fill="gray1" up={open} />
      </Flex>
      <AnimatedHeight duration={250} height={open ? 'auto' : 0}>
        {open && <Flex column>{children}</Flex>}
      </AnimatedHeight>
    </Flex>
  )
}
