import { axios } from 'api'

export const uploadDesignAsset = async (theme_id, payload) => {
  try {
    const response = await axios.post(
      `createDesignAsset/${theme_id}`,
      payload,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getDesignAssets = async (theme_id) => {
  try {
    const response = await axios.get(`designAssets/${theme_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteDesignAsset = async (design_asset_id) => {
  try {
    const response = await axios.delete(`designAssets/${design_asset_id}`)
    return response
  } catch (error) {
    throw error
  }
}
