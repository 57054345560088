import styled, { css } from 'styled-components'
import { Flex } from 'components'
import { colors as c } from 'utils'

export const Tab = styled(Flex)(
  ({ selected }) => css`
    background-color: ${selected && c('paleSecondary')};
    &:hover {
      background-color: ${c('paleSecondary')};
    }
  `,
)
