export default `
<ul>
  <li>
    &#8202;<span class="mceNonEditable" data-case-detail="name_of_deceased">{$name_of_deceased}</span>&#8202;
  </li>
  <li>
    &#8202;<span class="mceNonEditable" data-case-detail="place_of_birth">{$place_of_birth}</span>&#8202;
  </li>
  <li>
    &#8202;<span class="mceNonEditable" data-case-detail="place_of_death">{$place_of_death}</span>&#8202;
  </li>
</ul>
`
