import React from 'react'
import { Flex } from 'components'
import { useRecoilValue } from 'recoil'
import { pageLoadingAtom } from 'atoms'
import { Grid } from 'react-loader-spinner'

export default () => {
  const pageLoading = useRecoilValue(pageLoadingAtom)

  return (
    pageLoading && (
      <Flex
        blur
        full
        center
        height="100%"
        top="0"
        right="0"
        bottom="0"
        left="0"
        position="absolute"
        zindex="100"
      >
        <Grid color="lockedGray5" height={100} width={100} />
      </Flex>
    )
  )
}
