import { useEffect } from 'react'

export default ({
  isItemSelected,
  dragDisabled,
  isEditing,
  controlledX,
  controlledY,
  setControlledX,
  setControlledY,
  setThisItemX,
  setThisItemY,
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isItemSelected || dragDisabled || isEditing) return

      const nudgeAmount = event.shiftKey ? 10 : 1 // Check if "Shift" is pressed

      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault()
          setControlledY((prevY) => prevY - nudgeAmount)
          setThisItemY(controlledY - nudgeAmount)
          break
        case 'ArrowDown':
          event.preventDefault()
          setControlledY((prevY) => prevY + nudgeAmount)
          setThisItemY(controlledY + nudgeAmount)
          break
        case 'ArrowLeft':
          event.preventDefault()
          setControlledX((prevX) => prevX - nudgeAmount)
          setThisItemX(controlledX - nudgeAmount)
          break
        case 'ArrowRight':
          event.preventDefault()
          setControlledX((prevX) => prevX + nudgeAmount)
          setThisItemX(controlledX + nudgeAmount)
          break
        default:
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    isItemSelected,
    dragDisabled,
    isEditing,
    controlledX,
    controlledY,
    setControlledX,
    setControlledY,
    setThisItemX,
    setThisItemY,
  ])
}
