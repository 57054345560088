import styled, { css } from 'styled-components'
import { Flex } from 'components'
import { flex } from 'styles'

export const Div = styled.div(
  ({ ...props }) => css`
    body {
      margin: 0;
    }
  `,
)
export const Page = styled(Flex)(
  ({ height, width, padding }) => css`
    ${flex}
    height: ${height}px;
    width: ${width}px;
    padding-top: ${padding.top}px;
    padding-bottom: ${padding.top}px;
    padding-left: ${padding.left}px;
    padding-right: ${padding.right ? padding.right : '0'};
  `,
)
