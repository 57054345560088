import { css } from 'styled-components'
import type { Sizes, MediaQueries } from 'types/style'

export const sizes: Sizes = {
  phonePlus: 440,
  tablet: 768,
  tabletPlus: 960,
  laptop: 1024,
  laptopPlus: 1200,
  desktop: 1230,
  desktopPlus: 1380,
  monitor: 1500,
}

const media = Object.keys(sizes).reduce<MediaQueries>((acc, label) => {
  acc[label as keyof Sizes] = (...args: Parameters<typeof css>) => css`
    @media ${label === 'laptop' ? 'screen and ' : ''} (max-width: ${sizes[
        label as keyof Sizes
      ]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {} as MediaQueries)

export default media
