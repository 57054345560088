import React, { useState, useEffect } from 'react'
import {
  FullscreenModal,
  Flex,
  Text,
  Divider,
  Link,
  Image,
  Button,
} from 'components'
import * as S from './Package.styled'

export default ({
  show: [show, setShow] = ['', null],
  selectedLayout,
  productInfo,
  productLineInfo,
}) => {
  const [guideInfo, setGuideInfo] = useState({})

  useEffect(() => {
    let guideModalInfo = {}
    guideModalInfo.title = `${productLineInfo?.name}: ${productInfo?.name}`
    guideModalInfo.paperSize = `${selectedLayout?.paper_height} x ${selectedLayout?.paper_width}`
    if (
      parseInt(selectedLayout?.paper_width) <=
      parseInt(selectedLayout?.paper_height)
    ) {
      guideModalInfo.orient = 'Portrait'
      guideModalInfo.duplex = 'Long-Edge binding'
    } else {
      guideModalInfo.orient = 'Landscape'
      guideModalInfo.duplex = 'Short-Edge binding'
    }
    const singles = [
      'Large Candle',
      'Square Candles',
      'Designer Square Candle',
      'Directory Signs',
      'Grave Markers',
      'Small Candle',
      'Small Candles',
      'Picture Frame',
    ]
    if (singles.indexOf(productInfo?.name) !== -1) {
      guideModalInfo.duplex = 'One-sided / Off'
    }
    guideModalInfo.paperType = productLineInfo?.paper_type
    guideModalInfo.thumbnail = productInfo?.thumbnail
    setGuideInfo(guideModalInfo)
  }, [selectedLayout, productInfo, productLineInfo])

  return (
    <FullscreenModal show={[show, setShow]}>
      {show && (
        <Flex column width="800px" padding="24px">
          <Text
            align="center"
            size="24px"
            color="brand"
            weight="600"
            margin="0 0 24px"
          >
            {guideInfo?.title}
          </Text>
          <Divider fill="gray4" />
          <Flex padding="0 32px">
            <Image src={guideInfo?.thumbnail} />
            <Flex column width="65%" margin="16px 0 0 24px">
              <Text
                align="center"
                size="16px"
                color="brand"
                weight="600"
                margin="0 0 16px"
              >
                Paper and Print settings
              </Text>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Paper Size:</Text>
                <Text>{guideInfo?.paperSize}</Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Orientation:</Text>
                <Text>{guideInfo?.orient}</Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Two-Sided:</Text>
                <Text>{guideInfo?.duplex}</Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Paper Type:</Text>
                <Text transform="capitalize">{guideInfo?.paperType}</Text>
              </Flex>
              <Divider fill="gray4" />
              <Text size="12px" padding="8px 0">
                *Some printers allow the saving of printer presets, to allow all
                these settings to be changes in one step, consult your printer
                documentation or call our support team for help.
              </Text>
            </Flex>
          </Flex>
          <Divider fill="gray4" />
          <Flex padding="0 32px" margin="0 0 24px">
            <Flex column padding="16px 0 0">
              <Image
                height="240px"
                width="288px"
                src="https://image1.cc-inc.com/pcm/marketing/adobe/BrandPage/AcrobatDCimg.png"
              />
              <Text weight="600" margin="0 0 16px">
                Video tutorials on print settings are available:
              </Text>
              <Link href="https://vimeo.com/212907676">
                <Text
                  size="12px"
                  weight="600"
                  color="brand"
                  decoration="underline"
                  margin="0 0 8px 16px"
                >
                  Printing with Adobe Acrobat
                </Text>
              </Link>
              <Link href="https://vimeo.com/217827932">
                <Text
                  size="12px"
                  weight="600"
                  color="brand"
                  decoration="underline"
                  margin="0 0 0 16px"
                >
                  Advanced Print Settings in Adobe Acrobat
                </Text>
              </Link>
            </Flex>
            <Flex column width="70%" margin="16px 0 0 24px">
              <Text
                align="center"
                size="16px"
                color="brand"
                weight="600"
                margin="0 0 16px"
              >
                Adobe Reader instructions
              </Text>
              <Text size="12px" margin="0 0 8px">
                We recommend opening your file in Adobe Reader for the best
                print results.
              </Text>
              <S.InteralLinkText size="12px" margin="0 0 8px">
                If you do not have Adobe Reader you can download it here:{' '}
                <Link href="https://get.adobe.com/reader/">Get Adobe</Link>
              </S.InteralLinkText>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text
                  margin="0 32px 0 0"
                  width="100%"
                  align="center"
                  weight="600"
                >
                  Adobe Print Settings
                </Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Page Sizing:</Text>
                <Text>Actual Size</Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Orientation:</Text>
                <Text>Auto protrait / landscape</Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text
                  margin="0 32px 0 0"
                  width="100%"
                  align="center"
                  weight="600"
                >
                  Advanced Adobe Print Menus
                </Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Page Setup:</Text>
                <Text>Paper Size and Orientation</Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Printer (Mac):</Text>
                <Text transform="capitalize">Two-sided and flip / binding</Text>
              </Flex>
              <Divider fill="gray4" />
              <Flex padding="8px 0">
                <Text margin="0 32px 0 0">Properties (Win):</Text>
                <Text transform="capitalize">Two-sided and flip / binding</Text>
              </Flex>
              <Divider fill="gray4" />
            </Flex>
          </Flex>
          <Divider fill="gray4" />
          <Flex column center>
            <Button margin="24px 0 0" onClick={() => setShow(false)}>
              Got it
            </Button>
          </Flex>
        </Flex>
      )}
    </FullscreenModal>
  )
}
