import React from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'
import { Flex, Slider } from 'components'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)

  const width = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'width' }),
  )
  const height = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'height' }),
  )
  const [feather, setFeather] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.feather' }),
  )
  const [featherStrength, setFeatherStrength] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.featherStrength' }),
  )

  const largestDimension = width > height ? width : height
  const featherDiffusion = 6
  const maxFeathering = largestDimension / featherDiffusion

  return (
    <Flex column padding="0 12px">
      <Flex justify="flex-start">
        <ToggleSwitch
          label="Feathered"
          value={[feather, setFeather]}
          margin="0 0 20px 0"
        />
      </Flex>
      <Slider
        sliderState={[featherStrength, setFeatherStrength]}
        min="0"
        max={maxFeathering}
        margin="0 0 16px"
        initialValue={featherStrength || 0}
        disabled={!feather}
      />
    </Flex>
  )
}
