import type { CheerioElement } from 'types/global'
import type { CSSObject } from 'styled-components'

const preserveStylingUtilities = () => ({
  extractCurrentStyle: (element: CheerioElement): string | null => {
    try {
      if (!element) {
        console.error('extractCurrentStyle: Element is undefined or null')
        return null
      }
      if (element.is('span[style]')) {
        return element.attr('style') || null
      }
      const styledParagraph = element.find('p[style]').attr('style') || ''
      const styledSpan =
        element.find('span[style]')?.first()?.attr('style') || ''
      return `${styledParagraph} ${styledSpan}`.trim() || null
    } catch (error) {
      console.error('Error extracting style:', {
        error,
        elementType: element?.prop('tagName'),
        elementHtml: element?.html(),
      })
      return null
    }
  },

  /* Create an object based on inline css styles */
  parseCSS: (css: string | null): CSSObject => {
    try {
      if (!css) return {}
      const cssString: string = css

      return Object.fromEntries(
        cssString
          .split(';')
          .map((rule) => {
            try {
              const [key, value] = rule.split(':').map((str) => str.trim())
              return key && value ? ([key, value] as [string, string]) : null
            } catch (error) {
              console.error('Error parsing CSS rule:', { rule, error })
              return null
            }
          })
          .filter((pair): pair is [string, string] => Boolean(pair)),
      )
    } catch (error) {
      console.error('Error parsing CSS string:', { css, error })
      return {}
    }
  },

  /* Create an inline style based on a css object */
  stringifyCSS: (cssObject: CSSObject): string => {
    try {
      return Object.entries(cssObject)
        .map(([key, value]) => `${key}: ${value}`)
        .join('; ')
    } catch (error) {
      console.error('Error stringifying CSS:', error)
      return ''
    }
  },
})

export default preserveStylingUtilities
