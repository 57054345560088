import React, { useEffect } from 'react'
import { Flex, Input } from 'components'
import * as S from './Slider.styled'

export default ({
  sliderState: [value, setValue] = [null, () => {}],
  min,
  max,
  initialValue,
  ...rest
}) => {
  useEffect(() => {
    !value && setValue(initialValue)
    // eslint-disable-next-line
  }, [initialValue])

  const handleSliderChange = (e) => {
    let { value } = e.target
    setValue(value)
  }

  const handleInputChange = (e) => {
    let inputValue = e.target.value
    const isEmpty = inputValue === ''
    !isEmpty && (inputValue *= 1)
    const isNumber = typeof inputValue === 'number'
    const over = inputValue !== '' && inputValue > max * 1
    const under = inputValue !== '' && inputValue < min * 1
    over && (inputValue = max * 1)
    under && (inputValue = min * 1)
    if (isNumber || isEmpty) setValue(inputValue)
  }

  return (
    <Flex full align="center" {...rest}>
      <S.Slider
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleSliderChange}
      />
      <Input
        width="84px"
        margin="0 0 0 16px"
        value={[parseInt(value)]}
        onChange={handleInputChange}
        type="number"
      />
    </Flex>
  )
}
