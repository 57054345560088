import { Route as ReactRouterRoute, RouteProps } from 'react-router-dom'

export const Route = ({ ...props }: RouteProps) => (
  <ReactRouterRoute {...props} />
)

export const DevRoute = ({ ...props }: RouteProps) => (
  <ReactRouterRoute {...props} />
)

export const AdminRoute = ({ ...props }: RouteProps) => (
  <ReactRouterRoute {...props} />
)
