// this utility determines what buttons are shown on tinyMCE's toolbar and in what order
// see documentation for toolbar options: https://www.tiny.cloud/docs/configure/editor-appearance/#toolbar
// see documentation for available buttons: https://www.tiny.cloud/docs/advanced/available-toolbar-buttons/#thecoretoolbarbuttons

const firstLayer =
  'bold italic underline strikethrough |' +
  'fontsizeselect | ' +
  'fontselect | ' +
  'lineheight | ' +
  'table |' +
  'hr |' +
  'selectall |'

const secondLayer =
  'forecolor backcolor |' +
  'alignleft aligncenter alignright alignjustify | ' +
  'dynamicText | casing | outline | outlineColor | ' +
  'undo redo | ' +
  'removeformat'

export default (inline) =>
  inline ? [firstLayer, secondLayer] : `${firstLayer}${secondLayer}`
