import React from 'react'
import MenuItem from './MenuItem'
import * as S from './Menu.styled'

export default ({
  children,
  items,
  full,
  fontMenu,
  show: [show, setShow] = [false, () => {}],
  ...props
}) => (
  <S.Menu
    column
    minWidth="224px"
    maxHeight="250px"
    padding="4px"
    full={full}
    {...props}
  >
    {children}
    {items?.map((props, i) => (
      <MenuItem fontMenu={fontMenu} key={i} setShow={setShow} {...props} />
    ))}
  </S.Menu>
)
