import React from 'react'
import { Text } from 'components'
import * as S from './BetaWatermark.styled'

export default () => (
  <S.Watermark
    width="100%"
    height="70px"
    position="fixed"
    top="0"
    left="0"
    zIndex="10"
    justify="space-around"
    align="center"
  >
    <Text color="lockedWhite" size="16px">
      PUBLIC TESTING
    </Text>
    <Text color="lockedWhite" size="16px">
      PUBLIC TESTING
    </Text>
  </S.Watermark>
)
