import styled, { css } from 'styled-components'
import { colors as c } from 'utils'
import { Text, Flex, DropIcon } from 'components'

export const HeaderArrow = styled(DropIcon)(
  ({ sorted }) => `
  opacity: ${sorted ? '1' : '0'};
`,
)

export const TableText = styled(Text)`
  color: ${c('gray1')};
  white-space: nowrap;
  transition: 0.25s;
`

export const Actions = styled(Flex)`
  display: none;
`

export const Ellipses = styled(Flex)``

export const Table = styled.div(
  ({ defaultClick }) => css`
    background-color: ${c('white')};
    display: block;
    max-width: 100%;
    width: 100%;

    .tableWrap {
      display: block;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    table {
      width: 100%;
      border-spacing: 0;
      text-align: left;
      height: 532px;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.75rem;
        border-bottom: 1px solid ${c('gray5')};
        width: 1%;
        transition: 0.25s;
        display: flex;
        align-items: center;
        height: 48px;

        :first-child {
          padding: 0.75rem 0rem;
        }

        :last-child {
          border-right: 0;
        }
      }

      thead tr {
        border-bottom: 1px solid ${c('gray4')};
      }

      thead tr th {
        font-weight: 500;
        color: ${c('gray1')};
        :hover {
          ${HeaderArrow} {
            opacity: 1;
          }
        }
      }
      tbody tr {
        &:hover ${TableText} {
          color: ${defaultClick && c('brand')};
        }

        &:hover {
          background-color: ${c('palePrimary')};
          ${Actions} {
            display: Flex;
            animation: fadeIn 0.25s;
          }
          ${Ellipses} {
            display: none;
            animation: fadeOut 0.25s;
          }
        }
        transition: 0.25s;
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  `,
)
