import React, { useState } from 'react'
import {
  Flex,
  Button,
  Text,
  Icon,
  SavedStatus,
  BetaWatermark,
  FullscreenModal,
  CaseDetails,
} from 'components'
import * as S from './EditorHeader.styled'

export default ({ back, update, assetDetails, ...props }) => {
  const [showCaseDetailsModal, setShowCaseDetailsModal] = useState(false)

  return (
    <>
      <Flex
        blur
        full
        background="lockedAlmostBlack"
        bgOpacity="80"
        height="70px"
        position="fixed"
        zindex="2"
      />
      <S.Navigation
        full
        justify="space-between"
        align="center"
        height="70px"
        padding="0 36px"
        zindex="2"
        position="fixed"
      >
        <BetaWatermark />
        <Flex align="center">
          <Button outline iconLeft="chevronLeft" onClick={() => back()}>
            Back
          </Button>
          <SavedStatus />
        </Flex>
        <Flex>
          <Text color="lockedWhite" margin="0 8px 0 0">
            {assetDetails && assetDetails}
          </Text>
          <Icon icon="info" fill="gray3" />
        </Flex>
        <Flex>
          <Button primary onClick={() => update()}>
            Save changes
          </Button>
        </Flex>
      </S.Navigation>
      <Flex full height="70px" background="lockedAlmostBlack" {...props} />

      <FullscreenModal show={[showCaseDetailsModal, setShowCaseDetailsModal]}>
        <CaseDetails />
      </FullscreenModal>
    </>
  )
}
