import styled, { css } from 'styled-components'
import { disabled } from 'styles'
import { Flex } from 'components'

const showHide = ({ show }) =>
  show
    ? css`
        opacity: 1;
        top: 12px;
        transition: 0.25s;
      `
    : css`
        opacity: 0;
        top: 0px;
        pointer-events: none;
        transition: 0.25s;
      `

export const RefWrapper = styled.div(
  ({ show }) => css`
    pointer-events: ${!show && 'none'};
  `,
)

export const HiddenMenu = styled(Flex)`
  ${disabled}
  ${showHide}
`
