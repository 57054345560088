import { atom, atomFamily, selectorFamily, selector } from 'recoil'
import { setDefaultService } from 'utils'
import { default as _set } from 'lodash/set'
import { default as _get } from 'lodash/get'
import { produce } from 'immer'

export const caseFieldsAtom = atom({
  key: 'caseFields',
  default: [],
})

export const caseValuesAtom = atomFamily({
  key: 'caseValues',
  default: {},
})

export const caseAtom = atom({
  key: 'case',
  default: {},
})

export const caseSelector = selector({
  key: 'caseSelector',
  get: ({ get }) => {
    let _case = get(caseAtom)
    if (!_case) return null
    if (!_case?.default_service && _case?.services) {
      const defaultService = _case.services.find((service) => service.default)
      const newCase = { ..._case, default_service: defaultService }
      return newCase
    }
    return _case
  },
  set: ({ set }, newValue) => {
    if (!newValue) return null
    if (newValue?.default_service) {
      set(caseAtom, newValue)
    } else {
      const defaultService = newValue.services.find(
        (service) => service.default,
      )
      newValue.default_service = defaultService
      set(caseAtom, newValue)
    }
  },
})

export const editCaseFieldSelector = selectorFamily({
  key: 'editCaseField',
  get:
    ({ name, path }) =>
    ({ get }) => {
      const fieldValue = get(caseValuesAtom(name))
      if (!fieldValue) return null
      if (!fieldValue.value) return null
      const value = _get(fieldValue, path)
      const isEmptyString = value === ''
      const shouldResetDate =
        (name.includes('date') || path.includes('date')) && !isEmptyString
      if (shouldResetDate) return new Date(value)
      return value
    },
  set:
    ({ name, path }) =>
    ({ set, get }, newValue) => {
      const fieldValue = get(caseValuesAtom(name))
      if (!fieldValue) return null

      const setCaseDetail = () => {
        const newField = produce(fieldValue, (draft) =>
          _set(draft, path, newValue),
        )
        set(caseValuesAtom(name), newField)
      }

      const variables = {
        name,
        path,
        set,
        get,
        newValue,
        fieldValue,
        caseValuesAtom,
        setCaseDetail,
      }

      setDefaultService(variables)
    },
})
