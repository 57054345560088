import { atom, selectorFamily, selector } from 'recoil'
import { default as _set } from 'lodash/set'
import { default as _get } from 'lodash/get'
import { default as _pick } from 'lodash/pick'
import { default as _remove } from 'lodash/remove'
import { produce } from 'immer'
import { updateOrder } from 'api/orders'
import {
  getOrderProducts,
  createOrderProduct,
  updateOrderProduct,
} from 'api/orderProducts'

export const collectionsAtom = atom({
  key: 'collections',
  default: [],
})

export const selectedProductLineAtom = atom({
  key: 'selectedProductLine',
  default: null,
})

export const selectedProductsAtom = atom({
  key: 'selectedProducts',
  default: [],
})

export const selectedThemeAtom = atom({
  key: 'selectedTheme',
  default: null,
})

export const pinnedThemesAtom = atom({
  key: 'pinnedThemes',
  default: [],
})

export const customizationAtom = atom({
  key: 'customization',
  default: null,
})

export const orderAtom = atom({
  key: 'currentOrder',
  default: {},
})

//the order selector will let us work with the individual properties on the order atom which is an object
export const orderSelector = selectorFamily({
  key: 'orderSelector',
  get:
    ({ property }) =>
    ({ get }) => {
      const order = get(orderAtom)
      if (!order.id) return null
      const value = _get(order, property)
      //if we request the 'theme_id' the value is all that will be returned, in this case, a uuid
      return value
    },
  set:
    ({ property }) =>
    ({ set, get }, newValue) => {
      const order = get(orderAtom)
      if (!order) return null
      //here we can target an update only the property being edited
      const newField = produce(order, (draft) => {
        _set(draft, property, newValue)
      })
      //this will allow us to send that one update to the backend at the same time
      const payload = _pick(newField, property)
      updateOrder(newField.id, payload)
      //then we set the order atom with the new value
      set(orderAtom, newField)
    },
})

export const themesAtom = atom({
  key: 'themes',
  default: [],
})

export const customThemesAtom = atom({
  key: 'customThemes',
  default: [],
})

export const productTabAtom = atom({
  key: 'productTab',
  default: null,
})

export const layoutAtom = atom({
  key: 'layout',
  default: '',
})

export const pinnedThemesSelector = selector({
  key: 'pinnedThemeSelector',
  get: ({ get }) => {
    const pinnedThemes = get(pinnedThemesAtom)
    if (!pinnedThemes) return null
    return pinnedThemes
  },
  set: ({ set, get }, newValue) => {
    const pinnedThemes = get(pinnedThemesAtom)
    if (!pinnedThemes) return null
    //determine if the clicked theme is in the pinned themes array
    const index = pinnedThemes.findIndex(
      (item) => item.id === newValue.id || item.theme_id === newValue.id,
    )
    const pinnedTheme = pinnedThemes.find(
      (item) => item.id === newValue.id || item.theme_id === newValue.id,
    )

    let newField
    if (index < 0) {
      //if it is NOT in the array (index === -1) add it to the atom array
      newField = produce(pinnedThemes, (draft) => {
        draft.push(newValue)
      })
    } else {
      //if it IS in the array remove it from the array
      newField = produce(pinnedThemes, (draft) => {
        _remove(draft, pinnedTheme)
      })
    }
    set(pinnedThemesAtom, newField)
  },
})

export const selectedThemeSelector = selector({
  key: 'selectedThemeSelector',
  get: ({ get }) => {
    const selectedTheme = get(selectedThemeAtom)
    if (!selectedTheme) return null
    return selectedTheme
  },
  set: ({ set, get }, newValue) => {
    const order = get(orderAtom)
    if (!newValue) return null
    //for a pinned theme the theme id is `theme_id`, for normal themese the id is `id` - then send only the theme_id to the database
    const payload = { theme_id: newValue?.theme_id || newValue.id }
    updateOrder(order.id, payload)
    set(selectedThemeAtom, newValue)
  },
})
export const productsAtom = atom({
  key: 'products',
  default: [],
})

export const selectedProductsSelector = selector({
  key: 'selectedProductsSelector',
  get: ({ get }) => {
    const selectedProducts = get(selectedProductsAtom)
    if (!selectedProducts) return null
    return selectedProducts
  },

  set: ({ set, get }, newValue) => {
    const selectedProducts = get(selectedProductsAtom)
    const order = get(orderAtom)
    const products = get(productsAtom)
    if (!selectedProducts) return null
    const orderProductExists = selectedProducts.find(
      (item) => item.product_id === newValue.id || item.id === newValue.id,
    )
    const index = selectedProducts.findIndex(
      (item) => item.product_id === newValue.id || item.id === newValue.id,
    )
    let newField
    if (!orderProductExists) {
      //if the product has not already been added to the order we will create a new one
      //select only the values we want from the product
      const payload = _pick(newValue, ['collection_id', 'name', 'thumbnail'])
      //add values we need for the orderProduct
      payload.product_id = newValue.id
      payload.order_id = order.id
      payload.selected = true
      //send the new orderProduct to the database (we cannot await in the set function)
      createOrderProduct(payload)
      //if we are adding a Register Book or a Deluxe Register Book we also need to add the cover page
      if (
        payload.name === 'Register Book' ||
        payload.name === 'Deluxe Register Book'
      ) {
        const coverPage = products.find(
          (item) =>
            item.name === `${payload.name}: Front Insert` &&
            item.collection_id === payload.collection_id,
        )
        if (coverPage) {
          const coverPagePayload = _pick(coverPage, [
            'collection_id',
            'name',
            'thumbnail',
          ])
          coverPagePayload.product_id = coverPage.id
          coverPagePayload.order_id = order.id
          coverPagePayload.selected = payload.selected
          createOrderProduct(coverPagePayload)
        }
      }
      //add the new orderProduct to the selectedProductsAtom array
      newField = produce(selectedProducts, (draft) => {
        draft.push(payload)
      })
    } else {
      //if the orderProduct is already in the selectedProducts array we need to update it instead
      //we toggle the 'selected' property true/false
      const payload = { selected: !orderProductExists.selected }
      //since the set cannot run an async operation, we need to refresh the selectedProducts array which happends on the card selector, this will make sure that all elements in the array have an id (are fresh from the db) before we get to this step. see cardSelector selectedStateUpdate
      //send the updated property only to the db
      updateOrderProduct(orderProductExists.id, payload)
      //update the item in the selectedProducts array by targeting it at the index
      newField = produce(selectedProducts, (draft) => {
        _set(draft, `[${index}].selected`, !orderProductExists.selected)
      })
      //if the selected product is a Reg Book/Deluxe RB we also need to toggle selected for the front insert
      if (
        orderProductExists.name === 'Register Book' ||
        orderProductExists.name === 'Deluxe Register Book'
      ) {
        const coverPage = selectedProducts.find(
          (item) =>
            item.name === `${orderProductExists.name}: Front Insert` &&
            item.collection_id === orderProductExists.collection_id,
        )
        const coverPageIndex = selectedProducts.findIndex(
          (item) =>
            item.name === `${orderProductExists.name}: Front Insert` &&
            item.collection_id === orderProductExists.collection_id,
        )
        if (coverPage) {
          const coverPagePayload = { selected: payload.selected }

          updateOrderProduct(coverPage.id, coverPagePayload)
          newField = produce(newField, (draft) => {
            _set(draft, `[${coverPageIndex}].selected`, payload.selected)
          })
        }
      }
    }
    //update the selected Products array
    set(selectedProductsAtom, newField)
  },
})

//this is cool but not useful at the moment
export const asyncSelectedProductsSelector = selector({
  key: 'asyncSelectedProducts',
  get: async ({ get }) => {
    const order = get(orderAtom)
    if (!order.id) {
      return []
    } else {
      const { data } = await getOrderProducts(order?.collection_id, order?.id)
      return data
    }
  },
})
