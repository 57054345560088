import styled, { css } from 'styled-components'
import { editorItem } from 'styles'

export const Line = styled.div(
  ({ edits }) => css`
    ${editorItem}
    border-radius: ${edits?.borderRadius};
    background-color: ${edits?.backgroundColor || 'black'};
    box-shadow: ${edits?.dropShadow
      ? `${edits?.dropShadow?.xOffset}px ${edits?.dropShadow?.yOffset}px
      ${edits?.dropShadow?.blur}px ${edits?.dropShadow?.spread}px
      ${edits?.dropShadow?.color}`
      : `${edits?.boxShadow}`};
    border: ${edits?.borderThickness}px ${edits?.borderThickness && 'solid'}
      ${edits?.borderColor};
    border-radius: ${edits?.borderRadius};
    opacity: ${edits?.opacity};
  `,
)
