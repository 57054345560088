import { useCallback } from 'react'
import { useSetToast } from 'hooks'

export const useErrorHandler = () => {
  const setToast = useSetToast()

  const handleError = useCallback(
    (text, error, icon = undefined) => {
      setToast({
        text:
          text || 'An unexpected error has occurred. Please try again later.',
        icon,
      })
      console.error('An error ocurred', error)
    },
    [setToast],
  )

  return handleError
}
