import styled, { css } from 'styled-components'
import { Flex } from 'components'

export const ToastWrapper = styled(Flex)`
  transition: 0.25s;
`

export const Toast = styled(Flex)(
  ({ show }) => css`
    animation: fadeIn 0.25s;

    @keyframes fadeIn {
      0% {
        top: 10px;
        opacity: 0;
      }
      100% {
        top: 0px;
        opacity: 1;
      }
    }

    opacity: ${show ? '1' : '0'};
    transition: 0.25s;
  `,
)
