import React, { useState, useEffect } from 'react'
import { Flex, Text, Button, Input, Slider } from 'components'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  editBackgroundImageAtom,
  pageThemeLayoutSelector,
  pageLayoutAtom,
  showPinturaAtom,
  themeLayoutAtom,
} from 'atoms'
import { useApplyThemeLayoutToPages } from 'hooks'
import { default as _omit } from 'lodash/omit'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'

export default () => {
  const applyThemeLayoutsToPages = useApplyThemeLayoutToPages()
  const setShowPintura = useSetRecoilState(showPinturaAtom)
  const setEditBackgroundImage = useSetRecoilState(editBackgroundImageAtom)
  const [pageLayout, setPageLayout] = useRecoilState(pageLayoutAtom)
  const themeLayout = useRecoilValue(themeLayoutAtom)

  const [hideBackground, setHideBackground] = useRecoilState(
    pageThemeLayoutSelector({ path: 'hide_background' }),
  )

  const [backgroundY, setBackgroundY] = useRecoilState(
    pageThemeLayoutSelector({ path: 'background_top' }),
  )

  const [backgroundX, setBackgroundX] = useRecoilState(
    pageThemeLayoutSelector({ path: 'background_left' }),
  )

  const [backgroundSize, setBackgroundSize] = useRecoilState(
    pageThemeLayoutSelector({ path: 'background_fill' }),
  )

  const [backgroundOpacity, setBackgroundOpacity] = useRecoilState(
    pageThemeLayoutSelector({ path: 'background_opacity' }),
  )

  const [width, height] = backgroundSize
    ? backgroundSize?.replace('%', '').split(' ')
    : ['auto', '105']
  const [backgroundWidth, setBackgroundWidth] = useState(width)
  const [backgroundHeight, setBackgroundHeight] = useState(height)

  const revertBackground = () => {
    const omit = [
      'id',
      'available',
      'paper_type',
      'layout_id',
      'product_id',
      'theme_id',
      'archived',
    ]
    const sanitizedThemeLayout = _omit(themeLayout, omit)

    const revertedPageLayout = {
      ...pageLayout,
      ...sanitizedThemeLayout,
      background_edits: {},
    }

    setPageLayout(revertedPageLayout)
  }

  const applyChanges = (selectedPages) => {
    applyThemeLayoutsToPages(selectedPages, {
      ...pageLayout,
      background_top: backgroundY,
      background_left: backgroundX,
      background_fill: backgroundSize,
      background_opacity: backgroundOpacity / 100,
    })
  }

  useEffect(() => {
    const [width, height] = backgroundSize
      ? backgroundSize?.replace('%', '').split(' ')
      : ['auto', '105']
    setBackgroundWidth(width)
    setBackgroundHeight(height)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setBackgroundSize(
      `${backgroundWidth !== 'auto' ? `${backgroundWidth}%` : 'auto'} ${
        backgroundHeight !== 'auto' ? `${backgroundHeight}%` : 'auto'
      }`,
    )
    //eslint-disable-next-line
  }, [backgroundWidth, backgroundHeight])

  useEffect(() => {
    setEditBackgroundImage(true)

    return () => setEditBackgroundImage(false)
    // eslint-disable-next-line
  }, [])

  return (
    <Flex column padding="16px">
      <Text margin="0 0 16px">Position</Text>
      <Flex margin="0 0 16px">
        <Input
          value={[backgroundX, setBackgroundX]}
          label="Left (px)"
          margin="0 16px 0 0"
          placeholder="100"
          type="number"
        />
        <Input
          value={[backgroundY, setBackgroundY]}
          label="Top (px)"
          margin="0 16px 0 0"
          placeholder="100"
          type="number"
        />
      </Flex>
      <Text margin="0 0 16px">Size</Text>
      <Flex margin="0 0 16px">
        {backgroundWidth !== 'auto' && (
          <>
            <Input
              value={[backgroundWidth, setBackgroundWidth]}
              label="Width fill (%)"
              margin="0 16px 0 0"
              placeholder="100"
              type="number"
            />
          </>
        )}
      </Flex>
      {backgroundWidth !== 'auto' && (
        <Flex full column align="flex-start" margin="0 0 20px">
          <Text size="12px" weight="500">
            Width fill (%)
          </Text>
          <Slider
            sliderState={[backgroundWidth, setBackgroundWidth]}
            min="0"
            max="400"
            initialValue={100}
          />
        </Flex>
      )}
      {backgroundHeight !== 'auto' && (
        <Flex full column align="flex-start" margin="0 0 20px">
          <Text size="12px" weight="500">
            Height fill (%)
          </Text>
          <Slider
            sliderState={[backgroundHeight, setBackgroundHeight]}
            min="0"
            max="400"
            initialValue={100}
          />
        </Flex>
      )}
      <Flex full column align="flex-start" margin="0 0 20px">
        <Text size="12px" weight="500" margin="0 24px 0 0">
          Opacity
        </Text>
        <Slider
          sliderState={[
            !isNaN(backgroundOpacity) ? backgroundOpacity : 0,
            setBackgroundOpacity,
          ]}
          min="0"
          max="100"
          initialValue={0}
        />
      </Flex>
      <Flex margin="0 0 16px">
        <ToggleSwitch
          label="Hide background"
          value={[hideBackground, setHideBackground]}
          labelLeft
        />
      </Flex>
      <Button center margin="0 0 16px" onClick={() => setShowPintura(true)}>
        Edit image
      </Button>
      <Button center margin="0 0 16px" onClick={() => revertBackground()}>
        Revert image
      </Button>
      <Button center margin="0 0 16px" onClick={() => applyChanges('even')}>
        Apply to even pages
      </Button>
      <Button center margin="0 0 16px" onClick={() => applyChanges('odd')}>
        Apply to odd pages
      </Button>
      <Button center margin="0 0 16px" onClick={() => applyChanges('all')}>
        Apply to all pages
      </Button>
    </Flex>
  )
}
