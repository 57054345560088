import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocalStorage, useNavigate } from 'hooks'
import { Flex, Text, Button, Icon, DarkImage } from 'components'
import { usePerformLogout } from 'providers/Auth0'

// import { useSetToast } from "hooks";

export default ({ error }) => {
  const navigate = useNavigate()
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const logout = usePerformLogout()
  const [trialExpired] = useLocalStorage('trialExpired')
  const urlParams = new URLSearchParams(window.location.search)
  const triggerSignup = urlParams.get('trigger_signup')
  const email = urlParams.get('email')

  useEffect(() => {
    if (triggerSignup && email) {
      window.location.search = ''
      loginWithRedirect({
        login_hint: email,
        screen_hint: 'signup',
      })
    } else if (isAuthenticated) {
      navigate('/dashboard')
    }
    //eslint-disable-next-line
  }, [triggerSignup, email, isAuthenticated])
  // this error isn't working ☹️
  // const setToast = useSetToast();
  // error &&
  //   setToast({
  //     text: error,
  //     icon: "",
  //   });

  return (
    <Flex column align="center">
      <Flex align="center" justify="center" column padding="48px 0 0 0">
        <DarkImage
          width="200px"
          alt="Messenger Dove Logo"
          src="messengerDoveLogo"
        />
        <Flex column align="center">
          {!isAuthenticated && !isLoading && (
            <>
              <Flex margin="24px 0 0 0">
                <Button
                  center
                  primary
                  onClick={loginWithRedirect}
                  margin="0 16px 0 0"
                >
                  Login
                </Button>
                {/* Our Link component here wasn't working because we have not rendered the <Routes /> component yet. Check Auth0.js line 65 - 68 */}
                {/* <Button
                  primary
                  onClick={() =>
                    logout({ returnTo: `${window.location.origin}/sign-up` })
                  }
                >
                  Sign Up
                </Button> */}
                {error && <Text>{error}</Text>}
              </Flex>
              {trialExpired && (
                <Flex
                  margin="32px 0 0 0"
                  padding="24px"
                  column
                  align="center"
                  border="1px solid"
                  borderColor="brand"
                  radius="2px"
                >
                  <Text as="h3">{trialExpired}</Text>
                  <Flex margin="8px 0 0 0">
                    <Flex margin="0 24px 0 0">
                      <Icon icon="welcomeEmail" />
                      <Flex column margin="0 0 0 16px">
                        <Text margin="0 0 12px 0">Email</Text>
                        <Text as="a" href="mailto:support@bass-mollet.com">
                          support@bass-mollet.com
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex>
                      <Icon icon="welcomePhone" />
                      <Flex column margin="0 0 0 16px">
                        <Text margin="0 0 12px 0">Please call toll free</Text>
                        <Text as="a" href="tel:1-855-277-0436">
                          1-855-277-0436
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </>
          )}

          {isAuthenticated && (
            <Flex column>
              <Text>Logged in to Director's Print Suite</Text>
              <Text as="h2">{user.name}</Text>
              <Text as="h3">{user.email}</Text>
              <Button primary onClick={() => logout()}>
                Logout
              </Button>
              {/* {error && <Text>{error}</Text>} */}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
