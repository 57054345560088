import * as S from './Text.styled'
import type { TextComponentProps } from 'types/text'

/**
 * Base text component that supports styling, spacing, sizing, decorations, and positioning.
 * @example
 * <Text size="large" weight="bold" margin="1rem">Hello World</Text>
 */
const Text = ({ children, as, ...props }: TextComponentProps) => (
  <S.Text as={as} {...props}>
    {children}
  </S.Text>
)

export default Text
