import styled, { css } from 'styled-components'
import { Flex } from 'components'

export const Alignment = styled(Flex)(
  ({ snap, show, direction }) => css`
    border: ${snap ? `2px` : `1px`} dashed
      ${direction === 'vertical' ? '#76ef3e' : '#3ecfef'};
    visibility: ${show ? 'visible' : 'hidden'};
  `,
)
