import React from 'react'
import { Flex, Text, Icon } from 'components'
import * as S from './Memorials.styled'

export default ({ id, memorial, from, isLoggedIn, archiveMemorial }) => {
  return (
    <S.Card
      column
      width="100%"
      background="paleSecondary"
      padding="16px"
      radius="4px"
      margin="0 24px 24px 24px"
      shadow="0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)"
      position="relative"
    >
      {isLoggedIn && (
        <S.IconWrapper onClick={() => archiveMemorial(id)}>
          <Icon clickable icon="exitModal" fill="error" />
        </S.IconWrapper>
      )}
      <Text margin="0 0 8px 0">{memorial}</Text>
      <Flex justify="flex-end">
        <Text>- {from}</Text>
      </Flex>
    </S.Card>
  )
}
