import React, { useState, useEffect } from 'react'
import { Flex, LoadingInsertImages, InsertImageGrid, Input } from 'components'
import { designAssetsAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export default ({ setShow }) => {
  const designAssets = useRecoilValue(designAssetsAtom)
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [filter, setFilter] = useState('')

  const sanitize = (data) => {
    return data.map((image) => ({
      id: image.id,
      name: image.name,
      image: image.url,
    }))
  }

  const getItems = async () => {
    setLoading(true)
    setItems(sanitize(designAssets))
    setLoading(false)
  }

  useEffect(() => {
    designAssets && getItems()
    // eslint-disable-next-line
  }, [designAssets])

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase()),
  )

  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex full center padding="8px 32px" gap="8px" column>
      {/* TODO: add 'make default' button */}
      <Input value={[filter, setFilter]} placeholder="Filter" />
      <InsertImageGrid
        items={filteredItems}
        srcPrefix={false}
        prependPrefix={false}
        idKey="id"
        imageKey="image"
        nameKey="name"
        type="designAsset"
        setShow={setShow}
        canDelete
      />
    </Flex>
  )
}
