import { axios } from 'api'

//TODO: time for refresh token? (can we just do this every time they visit?)

// NOTE: Endpoint is missing in backend
export const checkEmail = async (email) => {
  try {
    const response = await axios.post('/checkEmail', { email: email })
    return response
  } catch (error) {
    throw error
  }
}

//TODO: trial days?
// NOTE: Endpoint is missing in backend
export const create = async (user) => {
  try {
    const response = await axios.post('/signup', user)
    return response

    // expected payload (ALL REQUIRED):
    // {
    //   email: <String>,
    //   password: <String>,
    //   name: <String>,
    //   funeral_home_id: <UUID: funeral_home_id>,
    // }
  } catch (error) {
    throw error
  }
}

export const user = async (token) => {
  try {
    const response = await axios.get('/user')
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const getStaff = async () => {
  try {
    const response = await axios.get(`/funeralHomeUsers`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const createStaff = async (user) => {
  try {
    const response = await axios.post(`/createStaff`, user)
    return response

    // expected payload:
    // {
    //   email: email,
    //   name: staff member name,
    //   password: password
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getInvitedStaff = async (token) => {
  try {
    const response = await axios.get(`/newStaff/${token}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const archiveUser = async (user_id) => {
  try {
    const response = await axios.patch(`/archiveUser/${user_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const unarchiveUser = async (user_id) => {
  try {
    const response = await axios.patch(`/unarchiveUser/${user_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const resendActivationEmail = async (user_id) => {
  try {
    const response = await axios.get(`/resendUser/${user_id}`)
    return response
  } catch (error) {
    throw error
  }
}

//TODO: update backend to send new token back in Headers
// NOTE: Endpoint is missing in backend
export const updateUser = async (user) => {
  try {
    const response = await axios.patch(`/user`, user)
    return response

    // expected payload:
    // {
    //   email: email,
    //   password: password,
    //   name: name
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const updateManager = async (id, manager) => {
  try {
    const response = await axios.patch(`/updateUserManager/${id}`, manager)
    return response

    //expected payload:
    // {
    //   manager: <boolean>
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint is missing in backend
export const sendShareInvite = async (payload) => {
  try {
    const response = await axios.post(`/sendInviteToUploadCaseImages`, payload)
    return response

    // expected payload:
    // {
    //   email: <String> (REQUIRED),
    //   case_id: <String> (REQUIRED),
    //   name_of_deceased: <String> (REQUIRED),
    //   recipient: <String> (REQUIRED),
    //   number_allowed: <Number> (OPTIONAL),
    //   expiration_date: <Date> (REQUIRED)
    // }
  } catch (error) {
    throw error
  }
}
