import React from 'react'
import { Flex, Icon } from 'components'
import * as S from './Modal.styled'

export default ({
  children,
  show: [show, setShow] = [false, () => {}],
  hideClose,
  ...props
}) => (
  <S.Modal minWidth="364px" show={show} {...props}>
    <Flex
      clickable
      position="absolute"
      top="24px"
      right="24px"
      onClick={() => setShow(false)}
    >
      {!hideClose && <Icon icon="exitModal" fill="gray1" hoverfill="gray2" />}
    </Flex>
    {children}
  </S.Modal>
)
