import React, { useState } from 'react'
import findIndex from 'lodash/findIndex'
import { Flex, Text } from 'components'

export default ({ tabs, ...props }) => {
  const [selected, setSelected] = useState(tabs?.[0]?.name || '')
  const selectedIndex = findIndex(tabs, ({ name }) => name === selected)
  const Content = tabs[selectedIndex]?.content

  return (
    <Flex full column height="100%">
      <Flex
        borderBottom="1px solid"
        borderColor="gray4"
        margin="0 16px"
        width="calc(100% - 32px)"
        {...props}
      >
        {tabs?.map(({ name }) => (
          <Flex
            key={name}
            clickable
            position="relative"
            top="1px"
            height="100%"
            margin="0 12px 12px 0"
            transition="0.25s"
            borderBottom="2px solid"
            borderColor={selected === name ? 'secondary' : 'transparent'}
            selected={selected === name}
            onClick={() => setSelected(name)}
          >
            {/* blue/brand text per Duane 2021-12-07 */}
            <Text weight="500" color={selected === name ? 'brand' : 'gray2'}>
              {name}
            </Text>
          </Flex>
        ))}
      </Flex>
      {selected && Content && <Content />}
    </Flex>
  )
}
