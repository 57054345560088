const editorGlobal: string = `
  div[data-case-detail-table] table {
    border-collapse: collapse !important;
    width: 100% !important;
  }

  div[data-case-detail-table] table td {
    width: 50% !important;
    text-align: center !important;
  }

  div[data-case-detail-table] table td,
  div[data-case-detail-table] table {
    border: 1px dashed #bbb;
  }
`

export default editorGlobal
