import cheerio from 'cheerio'
import { handleHtmlData } from 'utils/global'
import { updateCaseDetails, updateCaseDetailTables, updateTemplates } from './'

export default (editorProps) => {
  const { initialHtml } = editorProps
  const $ = cheerio.load(initialHtml)

  const textTypes = [
    {
      attribute: 'data-case-detail',
      handler: updateCaseDetails,
    },
    {
      attribute: 'data-case-detail-table',
      handler: updateCaseDetailTables,
    },
    {
      attribute: 'data-template',
      handler: updateTemplates,
    },
  ]

  textTypes.forEach((textType) => {
    const { attribute } = textType
    const dynamicTextElements = $(`[${attribute}]`)

    dynamicTextElements.each(function () {
      const element = $(this)
      handleDynamicTextElement({ element, textType, editorProps })
    })
  })

  return $('body').html()
}

const handleDynamicTextElement = ({ element, textType, editorProps }) => {
  const { attribute, handler } = textType

  const attributes = element.attr()
  const dynamicKey = attributes[attribute]
  const elementType = element[0].name

  const textTypeProps = {
    attribute,
    key: dynamicKey,
  }

  const elementProps = {
    type: elementType,
    element,
    properties: getElementProperties(attributes),
  }

  const dynamicTextProps = {
    textTypeProps,
    elementProps,
    editorProps,
  }

  handler(dynamicTextProps)
}

const getElementProperties = (attributes) => {
  const properties = attributes['data-properties']
  if (!properties) return {}

  const decodedProperties = handleHtmlData.decode(properties)
  return decodedProperties
}
