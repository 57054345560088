import React, { useState, useEffect } from 'react'
import { Button, Dropdown, Flex, Input, Text } from 'components'
import { useRecoilState } from 'recoil'
import { useSetToast } from 'hooks'
import { funeralHomeAtom } from 'atoms'
import { getStaff as getStaffApi, updateManager } from 'api/users'
import { updateFuneralHome } from 'api/funeralHomes'
import { LogoSelection } from './'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler()
  const [home, setHome] = useRecoilState(funeralHomeAtom)
  const setToast = useSetToast()
  const [homeName, setHomeName] = useState('')
  const [generalManager, setGeneralManager] = useState('')
  const [staff, setStaff] = useState([])

  useEffect(() => {
    home?.name && setHomeName(home.name)
    //eslint-disable-next-line
  }, [home])

  const getStaff = async () => {
    try {
      // retrieves the staff to populate the dropdown
      const { data } = await getStaffApi()
      setGeneralManager(data.find((staff) => staff.manager)?.id)
      setStaff(data)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    getStaff()
    //eslint-disable-next-line
  }, [])

  const saveChanges = async () => {
    if (home.name !== homeName) {
      setHome(homeName)
      try {
        const resp = await updateFuneralHome({
          id: home.id,
          name: homeName,
        })
        if (resp.status === 200) {
          setToast({
            text: 'Funeral Home Name successfully updated!',
            icon: 'check',
          })
        }
      } catch (err) {
        handleError(
          err?.response?.data?.message || err.message,
          err,
          'deleteOutline',
        )
      }
    }
    if (generalManager !== staff.find((staff) => staff.manager).id) {
      try {
        const resp = await updateManager(generalManager, {
          manager: true,
        })
        if (resp.status === 200) {
          setToast({
            text: 'Manager successfully updated!',
            icon: 'check',
          })
        }
      } catch (err) {
        handleError(
          err?.response?.data?.message || err.message,
          err,
          'deleteOutline',
        )
      }
    }
  }

  return (
    <Flex column full padding="0 40px 0 0">
      <Flex column align="flex-start">
        <Text size="24px" weight="600" margin="0 0 24px 0">
          Funeral home info
        </Text>
        <Input
          width="300px"
          margin="0 0 24px 0"
          label="Funeral home name"
          value={[homeName, setHomeName]}
        />
        <Dropdown
          width="300px"
          margin="0 0 24px 0"
          label="General manager"
          placeholder="Select a manager"
          textKey={'name'}
          valueKey={'id'}
          selected={[generalManager, setGeneralManager]}
          items={staff}
        />
        <Text size="12px" weight="500" margin="0 0 8px 0">
          Funeral home ID
        </Text>
        <Text size="14px" margin="0 0 24px 0">
          {home.id ?? 'No funeral home'}
        </Text>
        <Button primary margin="0 0 40px 0" onClick={saveChanges}>
          Save changes
        </Button>
        <LogoSelection />
      </Flex>
    </Flex>
  )
}
