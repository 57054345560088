import React from 'react'
import { cases } from 'api'
import { casesMockTable } from 'mocks'
import { Navigation, CasesTable, DashboardTable } from 'components'

import { NewCaseButton } from './'

export default () => (
  <>
    <Navigation />
    <DashboardTable
      title="Cases"
      type="Case"
      api={cases}
      mockTable={casesMockTable}
      CreateComponent={NewCaseButton}
      TableComponent={CasesTable}
    />
  </>
)
