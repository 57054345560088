import React from 'react'
import * as S from './Alignment.styled'
import { useRecoilValue } from 'recoil'
import { alignmentVisibilityAtom } from 'atoms'

export default ({ left, height, whiteBorder, ...props }) => {
  const alignmentVisibility = useRecoilValue(alignmentVisibilityAtom)
  return (
    <S.Alignment
      show={alignmentVisibility.verticalArea === left}
      snap={alignmentVisibility.verticalSnap === left}
      position="absolute"
      left={`${left}px`}
      top="0px"
      height={`${whiteBorder ? height - whiteBorder * 2 : height}px`}
      width="0px"
      direction="vertical"
      {...props}
    />
  )
}
