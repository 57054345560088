import { pageLoadingAtom } from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import optimizeImage from 'utils/editor/optimizeImage'

export default ({ uploadFunction, setImages }) => {
  const handleError = useErrorHandler()
  const setPageLoading = useSetRecoilState(pageLoadingAtom)

  const handleFileChange = useCallback(
    async (e) => {
      try {
        const { files } = e?.target
        if (files && files.length) {
          const file = files[0]
          const reader = new FileReader()

          reader.onload = async (event) => {
            const image = new Image()
            image.src = event.target.result

            image.onload = async () => {
              const optimizedImageDataURL = await optimizeImage(
                image,
                2560,
                1440,
                0.75,
              )
              const blob = await (await fetch(optimizedImageDataURL)).blob()

              let fd = new FormData()
              fd.append('file', blob, file.name)

              const resp = await uploadFunction(fd)
              setImages(resp.data)
            }
          }

          reader.readAsDataURL(file)
        }
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
        setPageLoading(false)
      }
    },
    [uploadFunction, setImages, handleError, setPageLoading],
  )

  return handleFileChange
}
