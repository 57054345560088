import { handleKeys } from '../caseDetails'

export default (editorProps) => {
  const { caseDetails } = editorProps
  if (!caseDetails) return

  let insertableCaseDetails = []

  Object.keys(caseDetails)
    .filter(filterUnwantedKeys)
    .filter(
      (key) =>
        (caseDetails[key] !== null && caseDetails[key]?.length > 0) ||
        key.includes('date'),
    )
    .forEach((key) =>
      handleKeys(
        editorProps.editor,
        editorProps.caseDetails,
        key,
        insertableCaseDetails,
        editorProps.dateFormat,
        editorProps.selectedTheme,
      ),
    )

  return insertableCaseDetails
}

const filterUnwantedKeys = (key) => {
  const unwantedKeys = [
    'id',
    'funeral_home_id',
    'created_at',
    'updated_at',
    'editable_case_id',
    'archived',
  ]

  return !unwantedKeys.includes(key)
}
