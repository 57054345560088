import get from 'lodash/get'
import { isEmpty } from 'lodash'
import { isDate } from 'utils'

export default (dynamicTextProps) => ({
  getCaseDetail: (element) => {
    const caseDetailAttribute = element.attr('data-case-detail')
    const caseDetailTableAttribute = element.attr('data-case-detail-table')

    const attribute = caseDetailAttribute || caseDetailTableAttribute

    const path = attribute?.split(' ')?.[0]
    const initialText = element.text()

    const { caseDetails, funeralHomeDetails } = dynamicTextProps.editorProps

    const caseDetail = get(caseDetails, path)
    const funeralHomeDetail = get({ funeralHomeDetails }, path)
    const value = caseDetail || funeralHomeDetail || '&#8202;'

    const isPlaceholder = initialText.includes('{$')

    let type = 'string'

    if (isEmpty(value)) type = 'empty'
    if (isDate(value)) type = 'date'
    if (isPlaceholder) type = 'placeholder'

    return { path, value, initialText, type }
  },

  updateDeepestSpanText: (element, text) => {
    let deepestSpan = element

    // Recursively drill down to the deepest span
    while (deepestSpan.find('span').length > 0) {
      deepestSpan = deepestSpan.find('span').first()
    }
    const textContent = String(text || '')
    // Replace the text content of the deepest span
    deepestSpan
      .contents()
      .filter(function () {
        return this.type === 'text'
      })
      .replaceWith(textContent)
  },
})
