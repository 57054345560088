import React from 'react'
import { Flex, Slider, Button } from 'components'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const [degrees, setDegrees] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.rotate' }),
  )

  const rotate = (direction) => {
    degrees
      ? setDegrees(parseInt(degrees) + parseInt(direction))
      : setDegrees(parseInt(direction))
  }
  const reset = () => {
    setDegrees(null)
  }

  return (
    <Flex column padding="0 12px">
      <Slider
        sliderState={[degrees, setDegrees]}
        min="-180"
        max="180"
        margin="0 0 16px"
        initialValue={0}
      />
      <Flex margin="0 0 20px">
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="0 16px 0 0"
          icon="rotateLeft"
          onClick={() => rotate(-45)}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="0 16px 0 0"
          icon="rotateRight"
          onClick={() => rotate(45)}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          icon="reset"
          onClick={() => reset()}
        />
      </Flex>
    </Flex>
  )
}
