import React from 'react'
import { Flex, Text } from 'components'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { useRecoilState, useRecoilValue } from 'recoil'
import { itemSelector } from 'atoms'
import { roleAtom } from 'atoms'

export const MaintainAspectRatio = ({ selectedItem }) => {
  const userRole = useRecoilValue(roleAtom)
  const [maintainAspectRatio, setMaintainAspectRatio] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'maintainAspectRatio' }),
  )

  if (userRole !== 'messenger-user') {
    return null
  }

  return (
    <>
      <Text margin="0 0 16px">Maintain aspect ratio</Text>
      <Flex margin="0 0 24px">
        <ToggleSwitch value={[maintainAspectRatio, setMaintainAspectRatio]} />
      </Flex>
    </>
  )
}
