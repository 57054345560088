import { css, type DefaultTheme } from 'styled-components'
import { colors as c } from 'utils'
import type { CSS } from 'types/style'

const windowsScrollbar = ({
  theme,
}: {
  theme: DefaultTheme
}): CSS | null | false => {
  if (!theme || !theme.os) {
    return null
  }
  const { os } = theme
  return (
    os.includes('Windows') &&
    css`
      &::-webkit-scrollbar {
        background: ${c('gray5')};
        transition: 0.25s;
      }
      &::-webkit-scrollbar-thumb {
        background: ${c('gray4')};
        &:hover {
          background: ${c('gray4', 90)};
        }
        transition: 0.25s;
      }
    `
  )
}

export default windowsScrollbar
