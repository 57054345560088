import styled from 'styled-components'
import { sizing, spacing } from 'styles'
import { colors as c } from 'utils'

export const Wrapper = styled.div`
  ${sizing}
  ${spacing}

  background-color: rgba(${c('pinturaBackground')});

  .pintura-editor {
    --color-background: ${({ theme }) => theme.pinturaBackground};
    --color-foreground: ${({ theme }) => theme.pinturaForeground};
    --color-primary: ${c('secondary')};
  }
`
