import React, { useEffect } from 'react'
import { Flex, Text, Slider, ColorPicker, Button } from 'components'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const boxShadow = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'edits.boxShadow' }),
  )
  const [xOffset, setXOffset] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.dropShadow.xOffset' }),
  )
  const [yOffset, setYOffset] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.dropShadow.yOffset' }),
  )
  const [blur, setBlur] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.dropShadow.blur' }),
  )
  const [spread, setSpread] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.dropShadow.spread' }),
  )
  const [opacity, setOpacity] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.dropShadow.opacity' }),
  )
  const [color, setColor] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.dropShadow.color' }),
  )
  const feather = useRecoilValue(
    itemSelector({ id: selectedItem, path: 'edits.feather' }),
  )

  useEffect(() => {
    const shadow = boxShadow && boxShadow?.split(')')
    const [x, y, blurD, spreadD] = shadow
      ? shadow?.[1].split('px')
      : [0, 0, 0, 0]
    !blur && setBlur(parseInt(blurD) || 0)
    !xOffset && setXOffset(parseInt(x) || 0)
    !yOffset && setYOffset(parseInt(y) || 0)
    !spread && setSpread(parseInt(spreadD) || 0)
    //eslint-disable-next-line
  }, [blur, xOffset, yOffset, spread, selectedItem])

  useEffect(() => {
    !opacity && setOpacity(100)
    !color && setColor('#000000')
    //eslint-disable-next-line
  }, [opacity, color, selectedItem])

  const resetAll = () => {
    setBlur(0)
    setXOffset(0)
    setYOffset(0)
    setSpread(0)
    setOpacity(100)
    setColor('#000000')
  }

  return (
    <Flex column padding="0 12px" disabled={feather}>
      <Text>Left/Right Offset</Text>
      <Slider
        sliderState={[xOffset, setXOffset]}
        min="-100"
        max="100"
        margin="0 0 20px"
        initialValue={0}
      />

      <Text>Top/Bottom Offset</Text>
      <Slider
        sliderState={[yOffset, setYOffset]}
        min="-100"
        max="100"
        margin="0 0 20px"
        initialValue={0}
      />

      <Text>Blur</Text>
      <Slider
        sliderState={[blur, setBlur]}
        min="0"
        max="100"
        margin="0 0 20px"
        initialValue={0}
      />

      <Text>Spread</Text>
      <Slider
        sliderState={[spread, setSpread]}
        min="0"
        max="100"
        margin="0 0 20px"
        initialValue={0}
      />

      <Text>Opacity</Text>
      <Slider
        sliderState={[opacity, setOpacity]}
        min="0"
        max="100"
        margin="0 0 20px"
        initialValue={100}
      />
      {color && (
        <ColorPicker
          color={[color, setColor]}
          label="Color"
          margin="0 0 20px"
        />
      )}
      <Button margin="0 0 20px" onClick={() => resetAll()}>
        Reset All
      </Button>
    </Flex>
  )
}
