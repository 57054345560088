import {
  differenceInCalendarYears,
  differenceInCalendarDays,
  differenceInCalendarMonths,
} from 'date-fns'
import { isDate, isoStringToDate } from 'utils'
import type { Case } from 'types/case'

const calculateCaseAge = (_case: Case): void => {
  let dateOfBirth = _case?.date_of_birth
  let dateOfDeath = _case?.date_of_death

  if (dateOfBirth && dateOfDeath) {
    if (!isDate(dateOfBirth) || !isDate(dateOfDeath)) {
      dateOfBirth = isoStringToDate(dateOfBirth)
      dateOfDeath = isoStringToDate(dateOfDeath)
    }

    if (dateOfDeath && dateOfBirth) {
      const ageInYears = Math.abs(
        differenceInCalendarYears(dateOfDeath, dateOfBirth),
      )
      const ageInMonths = differenceInCalendarMonths(dateOfDeath, dateOfBirth)
      const ageInDays = differenceInCalendarDays(dateOfDeath, dateOfBirth)

      _case.age = ageInYears
      _case.age_years = ageInYears
      _case.age_months = ageInMonths
      _case.age_days = ageInDays
    }
  }
}

export default calculateCaseAge
