import React from 'react'
import { Button, Flex, Text } from 'components'

import { SettingsFormSection } from './InputWrappers'

export default ({ saveSettings }) => (
  <Flex column full padding="0 40px 40px 0">
    <Flex column align="flex-start">
      <Text size="24px" weight="600" margin="0 0 32px 0">
        Preferences
      </Text>
      <Text weight="600" margin="0 0 24px 0">
        Default Settings
      </Text>
      <SettingsFormSection formSection="preferences" />
      <Text weight="600" margin="0 0 24px 0">
        Preset Header Text
      </Text>
      <SettingsFormSection formSection="headers" />

      <Button primary margin="0 0 40px 0" onClick={() => saveSettings()}>
        Save changes
      </Button>
    </Flex>
  </Flex>
)
