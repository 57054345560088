import { axios } from 'api'

export const getCaseMemorials = async (caseId) => {
  try {
    const response = await axios.get(`/caseMemorials?case_id=${caseId}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const getCaseMemorialsAsGuest = async (token) => {
  try {
    const response = await axios.get(`/caseMemorialsAsGuest/${token}`)
    return response

    // const examplePaylod = {
    //     case_id: 'case id',
    //     memorial: 'memorial string',
    //     submitter_name: 'name of submitter'
    // }
  } catch (error) {
    throw error
  }
}

export const createCaseMemorial = async (payload) => {
  try {
    const response = await axios.post('/createCaseMemorial', payload)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const createCaseMemorialAsGuest = async (token, payload) => {
  try {
    const response = await axios.post(
      `/createCaseMemorialAsGuest/${token}`,
      payload,
    )
    return response
  } catch (error) {
    throw error
  }
}

export const archiveMemorial = async (id) => {
  try {
    const response = await axios.patch(`/archiveCaseMemorial/${id}`)
    return response
  } catch (error) {
    throw error
  }
}
