import { axios } from 'api'

/// SETTINGS ///

// NOTE: This endpoint is not used
export const createFuneralHomeSettings = async (payload) => {
  try {
    const response = await axios.post(`createFuneralHomeSettings`, payload)
    return response

    // expected payload:
    //  {
    //   print_parameter_top: <Number> (nullable, OPTIONAL),
    //   print_parameter_left: <Number> (nullable, OPTIONAL),
    //   print_parameter_right: <Number> (nullable, OPTIONAL),
    //   print_parameter_bottom: <Number> (nullable, OPTIONAL),
    //   default_font: <String> (nullable, OPTIONAL),
    //   case_title_name_of_deceased: <String> (nullable, OPTIONAL),
    //   case_title_date_of_birth: <String> (nullable, OPTIONAL),
    //   case_title_date_of_death: <String> (nullable, OPTIONAL),
    //   case_title_clergy_name: <String> (nullable, OPTIONAL),
    //   case_title_service_date: <String> (nullable, OPTIONAL),
    //   case_title_interment_place: <String> (nullable, OPTIONAL),
    //   date_format: <String> (nullable, OPTIONAL),
    //  }
  } catch (error) {
    throw error
  }
}

export const getFuneralHomeSettings = async () => {
  try {
    const response = await axios.get(`funeralHomeSettings`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const updateFuneralHomeSettings = async (funeral_home_id, payload) => {
  try {
    const response = await axios.patch(
      `funeralHomeSettings/${funeral_home_id}`,
      payload,
    )
    return response

    // expected payload:
    //  {
    //   print_parameter_top: <Number> (nullable, OPTIONAL),
    //   print_parameter_left: <Number> (nullable, OPTIONAL),
    //   print_parameter_right: <Number> (nullable, OPTIONAL),
    //   print_parameter_bottom: <Number> (nullable, OPTIONAL),
    //   default_font: <String> (nullable, OPTIONAL),
    //   case_title_name_of_deceased: <String> (nullable, OPTIONAL),
    //   case_title_date_of_birth: <String> (nullable, OPTIONAL),
    //   case_title_date_of_death: <String> (nullable, OPTIONAL),
    //   case_title_clergy_name: <String> (nullable, OPTIONAL),
    //   case_title_service_date: <String> (nullable, OPTIONAL),
    //   case_title_interment_place: <String> (nullable, OPTIONAL),
    //   date_format: <String> (nullable, OPTIONAL),
    //  }
  } catch (error) {
    throw error
  }
}

/// LOGOS ///

// TODO: front end stores this in the service, a - why, b - do we still need to
// let logos = null;

// NOTE: This endpoint is not used
export const createFuneralHomeLogo = async (size, file) => {
  try {
    const response = await axios.post(
      `createFuneralHomeLogo?size={size}`,
      file,
      {
        headers: { 'Content-Type': undefined },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const updateFuneralHomeLogo = async (id, size, file) => {
  try {
    const response = await axios.patch(`updateLogos/${id}?size=${size}`, file, {
      headers: { 'Content-Type': undefined },
    })
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getFuneralHomeLogo = async () => {
  try {
    const response = await axios.get(`funeralHomeLogos`, {
      headers: { 'Content-Type': undefined },
    })
    return response
  } catch (error) {
    throw error
  }
}

/// DEFAULT LAYOUTS ///
// This is where the user can set the white border or full bleed etc to always be the selected default

// NOTE: This endpoint is not used
export const getFuneralHomeDefaultLayouts = async () => {
  try {
    const response = await axios.get(`funeralHomeDefaultLayouts`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const createFuneralHomeDefaultLayout = async (payload) => {
  try {
    const response = await axios.post(`createFuneralHomeDefaultLayout`, payload)
    return response

    // expected payload:
    // {
    //   layout_id: <UUID> (REQUIRED),
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const deleteFuneralHomeDefaultLayout = async (default_layout_id) => {
  try {
    const response = await axios.delete(
      `funeralHomeDefaultLayouts/${default_layout_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}
