import { css } from 'styled-components'

type GapValue = string | number

export type GapProps = {
  gap?: GapValue
  rowGap?: GapValue
  columnGap?: GapValue
}

const generateGapStyles = ({ gap, rowGap, columnGap }: GapProps) => css`
  gap: ${gap};
  row-gap: ${rowGap};
  column-gap: ${columnGap};
`
export default generateGapStyles
