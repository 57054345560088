import React, { forwardRef } from 'react'
import { Flex, Text } from 'components'
import * as S from './TextArea.styled'

export default forwardRef(
  (
    {
      margin,
      width,
      minWidth,
      label,
      color,
      value: [value, setValue] = ['', null],
      onEnter,
      disabled,
      elementLeft,
      elementRight,
      type,
      error,
      ...props
    },
    ref,
  ) => (
    <>
      <S.InputWrapper
        column
        full={!width}
        disabled={disabled}
        margin={margin}
        width={width}
        minWidth={minWidth}
      >
        {label && (
          <Text color={color} size="12px" margin="0 0 4px 0" weight="500">
            {label}
          </Text>
        )}
        <Flex center position="relative">
          {elementLeft && (
            <Flex position="absolute" left="12px">
              {elementLeft?.()}
            </Flex>
          )}
          <S.TextArea
            full
            value={value || value === 0 ? value : ''}
            type={type || undefined}
            onChange={({ target }) => {
              setValue?.(
                type === 'number' ? parseInt(target.value) : target.value,
              )
            }}
            onKeyDown={({ key }) => key === 'Enter' && onEnter?.()}
            {...props}
            ref={ref}
          />
          {elementRight && (
            <Flex position="absolute" right="12px">
              {elementRight?.()}
            </Flex>
          )}
        </Flex>
      </S.InputWrapper>
      {error && (
        <Text size="14px" color="error" margin="10px 0 0 0">
          {error}
        </Text>
      )}
    </>
  ),
)
