import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { useGetCoords } from 'hooks'
import {
  boundingBoxAtom,
  draggingSelectionAtom,
  selectionRangeAtom,
} from 'atoms'

export default (canvasRef, pageRef) => {
  let heldDown = false
  let selectionOrigin = { x: 0, y: 0 }

  const setBoundingBox = useSetRecoilState(boundingBoxAtom)
  const setSelectionRange = useSetRecoilState(selectionRangeAtom)
  const setDraggingSelection = useSetRecoilState(draggingSelectionAtom)

  const getCoords = useGetCoords()
  const withinCanvas = (e) => canvasRef?.current?.contains(e?.target)
  const allowBoundingBox = (e) =>
    e?.target?.getAttribute('data-bounding-box') === 'true'

  const mouseDown = (event) => {
    if (withinCanvas(event) && allowBoundingBox(event)) {
      setDraggingSelection(true)
      heldDown = true
      selectionOrigin = getCoords(event, pageRef.current)
      setSelectionRange({ top: 0, right: 0, bottom: 0, left: 0 })
    }
  }

  const mouseUp = () => {
    if (heldDown) {
      heldDown = false
      selectionOrigin = { x: 0, y: 0 }
      setBoundingBox({ x: 0, y: 0, width: 0, height: 0 })
    }
    setDraggingSelection(false)
  }

  const mouseDrag = (event) => {
    if (heldDown) {
      const { x: originX, y: originY } = selectionOrigin
      const { x: dragX, y: dragY } = getCoords(event, pageRef.current)

      const dragBelowOriginX = dragX > originX
      const dragRightOfOriginY = dragY > originY

      const left = dragBelowOriginX ? originX : dragX
      const top = dragRightOfOriginY ? originY : dragY
      const right = dragBelowOriginX ? dragX : originX
      const bottom = dragRightOfOriginY ? dragY : originY

      const height = Math.abs(dragY - originY)
      const width = Math.abs(dragX - originX)

      const x = left
      const y = top

      setSelectionRange({ left, top, right, bottom })
      setBoundingBox({ x, y, width, height })
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', (e) => mouseDown(e))
    document.addEventListener('mousemove', (e) => mouseDrag(e))
    document.addEventListener('mouseup', (e) => mouseUp(e))

    return () => {
      document.addEventListener('mousedown', (e) => mouseDown(e))
      document.addEventListener('mousemove', (e) => mouseDrag(e))
      document.addEventListener('mouseup', (e) => mouseUp(e))
    }
    // eslint-disable-next-line
  }, [])
}
