import React from 'react'
import { Text } from 'components'
import * as S from './Support.styled'

export default () => (
  <S.HeaderContainer full padding="28px 40px" margin="0 0 32px">
    <Text size="32px" weight="600">
      Support
    </Text>
  </S.HeaderContainer>
)
