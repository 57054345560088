import React, { useState } from 'react'
import { Button, Input } from 'components'
import * as S from './Login.styled'

export default () => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')

  const submit = () => console.log('submitted')

  return (
    <S.Login>
      Login
      <Input
        placeholder="username"
        value={userName}
        onChange={({ target }) => setUserName(target.value)}
      />
      <Input
        placeholder="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
      />
      <Button onClick={() => submit()}>submit</Button>
    </S.Login>
  )
}
