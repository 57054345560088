import React from 'react'
import { Image } from 'components'
import { themeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import * as images from 'assets/images'

export default ({ src, ...props }) => {
  const theme = useRecoilValue(themeAtom)
  const isDarkTheme = theme === 'dark'
  const lightSource = images?.[src]
  const darkSource = images?.[`${src}Dark`] || lightSource
  const source = isDarkTheme ? darkSource : lightSource

  return <Image src={source} {...props} />
}
