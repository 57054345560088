import React from 'react'
import { Dropdown } from 'components'
import { useRecoilState } from 'recoil'
import { editSettingsFieldSelector } from 'atoms'

export default ({
  name,
  label,
  width,
  margin,
  items,
  textKey,
  valueKey,
  fontMenu,
}) => {
  const [dropdown, setDropdown] = useRecoilState(
    editSettingsFieldSelector({ name: name, path: 'value' }),
  )

  return (
    <Dropdown
      label={label}
      width={width || '300px'}
      margin={margin || '0 24px 0 0'}
      selected={[dropdown, setDropdown]}
      placeholder={label}
      textKey={textKey || 'label'}
      valueKey={valueKey || 'value'}
      fontMenu={fontMenu}
      items={items}
    />
  )
}
