import { Flex } from 'components'
import styled from 'styled-components'
import { colors as c } from 'utils'

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: none;
  position: absolute;
  top: -12px;
  right: -12px;
  background: ${c('white')};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`

export const Card = styled(Flex)`
  &:hover {
    background: ${c('gray5')};

    ${IconWrapper} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
