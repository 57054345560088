import React, { useEffect, useState } from 'react'
import { default as _omit } from 'lodash/omit'
import { Button, Flex, Text, Tabs } from 'components'
import {
  DeceasedInfo,
  //MilitaryInfo,
  Obituary,
  ServiceInfo,
  PersonalImages,
  MemorialContributions,
} from './CaseDetailsTabs'
import { updateCase } from 'api/cases'
import { sanitizeCaseStations } from 'utils'
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil'
import { caseFieldsAtom, caseValuesAtom, caseAtom, hasNewCaseAtom } from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { useSetToast } from 'hooks'
import { isDate, isEmpty } from 'lodash'

export default ({ updateTable }) => {
  const setToast = useSetToast()
  const handleError = useErrorHandler()
  const [activeTab, setActiveTab] = useState('Deceased info')
  const [pairs, setPairs] = useState([])
  const [_case, setCase] = useRecoilState(caseAtom)
  const resetHasNewCase = useResetRecoilState(hasNewCaseAtom)

  //Borrowed from code example here:https://codesandbox.io/s/stackoverflow-63038077-recoil-family-forms-xm6p1?file=/src/App.js
  const loadInitialData = useRecoilCallback(({ set }) => (pairs) => {
    const ids = []
    for (const field of pairs) {
      //pairs is an array of object from case => [{name_of_deceased: "john smith"}]
      //first create an array of 'ids' these will be unique keys stored in the caseFieldsAtom atom
      ids.push(field.id)
      //then store the object on the caseValuesAtom atom family
      set(caseValuesAtom(field.id), () => field)
    }
    set(caseFieldsAtom, ids)
  })

  useEffect(() => {
    loadInitialData(pairs)

    //eslint-disable-next-line
  }, [pairs])

  const mapValuePairs = (_case) => {
    //map the case object into an array of object for each key/value
    const mapped = _case
      ? Object.keys(_case).map((key) => ({
          id: key,
          value: _case[key],
        }))
      : []
    setPairs(mapped)
  }

  useEffect(() => {
    const caseTmp = { ..._case }
    caseTmp.us_stations_where_served = sanitizeCaseStations(
      caseTmp?.us_stations_where_served,
    )

    if (!caseTmp?.services) {
      caseTmp.services = []
    }
    caseTmp.id && mapValuePairs(caseTmp)
  }, [_case])

  const transformNameOfDeceased = (payload) => {
    const {
      prefix_dec,
      first_dec,
      nick_name_dec,
      middle_dec,
      maiden_dec,
      lastdec,
      suffix_dec,
    } = payload
    const formattedNickName = nick_name_dec ? `"${nick_name_dec}"` : ''
    const formattedMaidenName = maiden_dec ? `(${maiden_dec})` : ''

    const name_of_deceased = [
      prefix_dec,
      first_dec,
      formattedNickName,
      middle_dec,
      formattedMaidenName,
      lastdec,
      suffix_dec,
    ]
      .filter(Boolean)
      .join(' ')

    return name_of_deceased
  }

  // If no date selected after it 'save', it is going to take today's date by default.
  const validateServiceDates = (services) => {
    return services.map((service) => {
      const serviceCopy = { ...service }
      if (!isDate(serviceCopy.date)) {
        serviceCopy.date = new Date()
      }
      return serviceCopy
    })
  }

  const saveCase = useRecoilCallback(({ snapshot }) => async () => {
    //get a snapshot of the recoil state (this is fuzzy to me still)
    //get the ids from the caseFieldsAtom atom
    const ids = await snapshot.getPromise(caseFieldsAtom)
    //create an empty array to store the returned values
    const allValues = []
    //extract the case_id for the patch
    let case_id = ''
    //this code came from the same sample listed above
    //for each id get the caseValue - which is the key/value object
    //push that to the array and save the case_id seperately
    for (const field of ids) {
      const caseValue = await snapshot.getPromise(caseValuesAtom(field))
      if (caseValue.id === 'id') case_id = caseValue.value
      allValues.push(caseValue)
    }
    //map the array of key/value objects back into one flat object
    const mapped = allValues.map((item) => ({ [item.id]: item.value }))
    const caseObj = Object.assign({}, ...mapped)
    //prep to send
    //the server will reject a payload with certain properties, this will remove those and then send the payload.
    const omit = [
      'id',
      'funeral_home_id',
      'created_at',
      'updated_at',
      'archived',
      'filledImages',
      'age',
      'age_years',
      'age_months',
      'age_days',
    ]
    const payload = _omit(caseObj, omit)
    payload.name_of_deceased = transformNameOfDeceased(payload)

    if (!isEmpty(payload.services))
      payload.services = validateServiceDates(payload.services)

    if (case_id.length > 0) {
      try {
        const { data } = await updateCase(case_id, payload)
        //on response update the pairs which triggers the data load and also update the cases table so if they close out the modal it's up-to-date
        mapValuePairs(data)
        resetHasNewCase()
        updateTable && updateTable(false, true)
        setCase(data)
        setToast({ text: 'Case updated successfully!' })
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    }
  })

  return (
    <Flex width="1056px" height="100vh" column>
      <>
        <Flex
          padding="24px 40px"
          margin="0 0 48px 0"
          height="94px"
          background="paleSecondary"
          align="center"
          justify="space-between"
        >
          <Text size="34px" weight="600">
            {`Case Details: ${_case?.name_of_deceased || ''}`}
          </Text>
          <Button margin="0 25px 0 0" primary onClick={saveCase}>
            Save
          </Button>
        </Flex>

        <Tabs
          tabs={[
            {
              title: 'Deceased info',
              content: (
                <DeceasedInfo
                  saveCase={saveCase}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              ),
            },
            {
              title: 'Obituary',
              content: (
                <Obituary
                  saveCase={saveCase}
                  caseDetails={_case}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              ),
            },
            {
              title: 'Service info',
              content: (
                <ServiceInfo
                  saveCase={saveCase}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              ),
            },
            {
              title: 'Images and Family Memorials',
              content: (
                <PersonalImages
                  saveCase={saveCase}
                  case_id={_case.id}
                  name_of_deceased={_case.name_of_deceased}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              ),
            },
            {
              title: 'Memorial contributions',
              content: (
                <MemorialContributions
                  saveCase={saveCase}
                  setActiveTab={setActiveTab}
                />
              ),
            },
            //{
            // title: 'Military/Veteran honors',
            // content: (
            //   <MilitaryInfo saveCase={saveCase} activeTab={activeTab} />
            // ),
            //  },
            // {
            //   title: 'Other',
            //   content: (
            //     //TODO: this is a placeholder: create component
            //     <Flex>Coming Soon!</Flex>
            //   ),
            // },
          ]}
          selected={[activeTab, setActiveTab]}
        />
      </>
    </Flex>
  )
}
