import { axios } from 'api'
import { default as fetch } from './fetch.config.js'

export const getFuneralHome = async (funeralHome_id) => {
  try {
    const messengerOrderIdToken = localStorage.getItem('messengerOrderIdToken')
    const options = messengerOrderIdToken
      ? {
          headers: {
            'x-order': messengerOrderIdToken,
          },
        }
      : undefined
    const response = await fetch.get(`funeralHomes`, undefined, options)
    return response
  } catch (error) {
    throw error
  }
}

//TODO: handle trial days
//NOTE: Endpoint missing in backend
export const createFuneralHome = async (payload) => {
  try {
    const response = await axios.post(`createFuneralHome`, payload)
    return response

    // expected payload:
    // {
    //   name: <String> (REQUIRED)
    //   phone: <Number>,
    //   address: <String>,
    //   city: <String>,
    //   state: <String>,
    //   zip_code: <String>,
    //   ip_city: <String> (allow: null),
    //   ip_country_code: <String> (allow: null),
    //   ip_country_name: <String> (allow: null),
    //   ip_address: <String> (allow: null),
    //   ip_latitude: <Number> (allow: null),
    //   ip_longitude: <Number> (allow: null),
    //   ip_region_code: <String> (allow: null),
    //   ip_region_name: <String> (allow: null),
    //   ip_zip_code: <String> (allow: null),
    // }
  } catch (error) {
    throw error
  }
}

// Serves as both an Admin and user endpoint. 😐
// As an Admin endpoint it requires the id parameter to find the home as a user endpoint it will take that from the user's details.
// NOTE: Endpoint missing in backend
export const updateFuneralHome = async (payload) => {
  try {
    const response = await axios.patch(`funeralHomes`, payload)
    return response

    // expected payload:
    // {
    //   id: <String>, admin
    //   name: <String>, user/admin
    //   enrolled: <Boolean> (OPTIONAL), admin
    //   active: <Boolean> (OPTIONAL), admin
    //   service_url_slug: <String> (OPTIONAL), user
    // }
  } catch (error) {
    throw error
  }
}

export const getFuneralHomeLogos = async () => {
  try {
    const response = await axios.get(`funeralHomeLogos`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const createFuneralHomeLogo = async (size, file) => {
  try {
    const response = await axios.post(
      `createFuneralHomeLogo?size=${size}`,
      file,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const updateFuneralHomeLogo = async (logo_id, size, file) => {
  try {
    const response = await axios.patch(
      `funeralHomeLogos/${logo_id}?size=${size}`,
      file,
      {
        headers: { 'content-type': 'form-data' },
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint missing in backend
export const getIpLocation = async () => {
  try {
    const response = await axios.get('iplocation')
    return response
  } catch (error) {
    throw error
  }
}
