import React, { useState, useEffect } from 'react'
import { HiddenMenu, Menu, NavItem } from 'components'
import { userAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { useLocation } from 'react-router-dom'
import { isLocalHost } from 'utils'
import { usePerformLogout } from 'providers/Auth0'

export default () => {
  const [userOpen, setUserOpen] = useState(false)
  const [dropdownItems, setDropdownItems] = useState([
    { text: 'Settings', link: '/settings' },
    { text: 'Digital registry', link: '/digital-registry' },
    {
      text: 'Log out',
      onClick: () => logout(),
    },
  ])

  const user = useRecoilValue(userAtom)
  const logout = usePerformLogout()

  const { pathname: location } = useLocation()
  const adminUser = user.type === 'superAdmin'
  const inAdmin = location.includes('/admin')

  useEffect(() => {
    let updatedItems = [...dropdownItems]
    const secondToLast = updatedItems.length - 1
    const addItem = (item) => updatedItems.splice(secondToLast, 0, item)

    adminUser &&
      addItem({
        text: inAdmin ? 'User dashboard' : 'Admin dashboard',
        link: inAdmin ? '/dashboard' : '/admin',
      })

    isLocalHost &&
      addItem({
        text: 'Documentation',
        link: '/showcase',
      })

    setDropdownItems(updatedItems)

    //eslint-disable-next-line
  }, [])

  return (
    <HiddenMenu
      left
      show={[userOpen, setUserOpen]}
      parentElement={() => (
        <NavItem
          text={user?.name}
          dropdown
          selected={userOpen}
          onClick={() => setUserOpen(!userOpen)}
        />
      )}
      hiddenElement={() => (
        <Menu
          show={[userOpen, setUserOpen]}
          margin="0 24px 0 0"
          items={dropdownItems}
        />
      )}
    />
  )
}
