import { atom, atomFamily, selectorFamily } from 'recoil'
import { default as _set } from 'lodash/set'
import { default as _get } from 'lodash/get'
import { produce } from 'immer'

export const settingsFieldsAtom = atom({
  key: 'settingsFields',
  default: [],
})

export const settingsValuesAtom = atomFamily({
  key: 'settingsValues',
  default: {},
})

export const dateFormatAtom = atom({
  key: 'dateFormat',
  default: 'MMMM do yyyy',
})

export const editSettingsFieldSelector = selectorFamily({
  key: 'editSettingsField',
  get:
    ({ name, path, parse }) =>
    ({ get }) => {
      const fieldValue = get(settingsValuesAtom(name))
      if (!fieldValue) return null
      if (!fieldValue.id) return null

      let value = _get(fieldValue, path)
      if (
        name.includes('print_parameter') ||
        path.includes('print_parameter')
      ) {
        value = value === null ? '0.00000' : value
        if (parse) value = parseFloat(value)
      } else if (!value) return null

      return value
    },
  set:
    ({ name, path }) =>
    ({ set, get }, newValue) => {
      const fieldValue = get(settingsValuesAtom(name))
      if (!fieldValue) return null

      let oppositeName
      switch (name) {
        case 'print_parameter_left':
          oppositeName = 'print_parameter_right'
          break
        case 'print_parameter_right':
          oppositeName = 'print_parameter_left'
          break
        case 'print_parameter_top':
          oppositeName = 'print_parameter_bottom'
          break
        case 'print_parameter_bottom':
          oppositeName = 'print_parameter_top'
          break
        default:
          oppositeName = ''
      }

      if (name.includes('print_parameter')) {
        let oppositeValue = get(settingsValuesAtom(oppositeName))
        const oppositeField = produce(oppositeValue, (draft) => {
          _set(draft, path, null)
        })
        set(settingsValuesAtom(oppositeName), oppositeField)
      }
      const newField = produce(fieldValue, (draft) => {
        _set(draft, path, newValue)
      })
      set(settingsValuesAtom(name), newField)
    },
})
