import React, { useState } from 'react'
import { Flex, Text, Slider, Button } from 'components'

export default ({
  rotateLeft,
  rotateRight,
  flipHorizontal,
  flipVertical,
  resetRotate,
  resetFlip,
}) => {
  const [degrees, setDegrees] = useState('0')

  return (
    <Flex column padding="0 12px">
      <Text margin="0 0 16px">Rotate</Text>
      <Slider
        sliderState={[degrees, setDegrees]}
        min="-180"
        max="180"
        margin="0 0 16px"
        initialValue={0}
      />
      <Flex margin="0 0 20px">
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="0 16px 0 0"
          icon="rotateLeft"
          onClick={() => rotateLeft()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="0 16px 0 0"
          icon="rotateRight"
          onClick={() => rotateRight()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          icon="reset"
          onClick={() => resetRotate()}
        />
      </Flex>
      <Text margin="0 0 16px">Flip</Text>
      <Flex margin="0 0 20px">
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="0 16px 0 0"
          icon="flipHorizontal"
          onClick={() => flipHorizontal()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          margin="0 16px 0 0"
          icon="flipVertical"
          onClick={() => flipVertical()}
        />
        <Button
          width="42px"
          height="42px"
          padding="0"
          icon="reset"
          onClick={() => resetFlip()}
        />
      </Flex>
    </Flex>
  )
}
