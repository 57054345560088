import React, { useState } from 'react'
import { ModalButton, FullscreenModal, CaseDetails } from 'components'
import { NewCaseModal } from '../CaseDetails'

export default ({ buttonText, icon, type, onSave, getItems }) => {
  const [showButtonModal, setShowButtonModal] = useState(false)
  const [showFullModal, setShowFullModal] = useState(false)

  return (
    <>
      <ModalButton
        left
        primary
        text="New Case"
        icon="plus"
        show={[showButtonModal, setShowButtonModal]}
      >
        <NewCaseModal
          setShowCaseModal={setShowFullModal}
          setShowButtonModal={() => setShowButtonModal(false)}
          type={type}
          onSave={onSave}
          getItems={getItems}
        />
      </ModalButton>
      <FullscreenModal show={[showFullModal, setShowFullModal]}>
        {showFullModal && <CaseDetails />}
      </FullscreenModal>
    </>
  )
}
