import type { FeatherProps } from './itemFeather'

type ShapeConfig = {
  element: string
  padding?: string
  dimensions?: string
}

type SVGDataUrl = `data:image/svg+xml,${string}`

const featheredShape = (
  edits?: FeatherProps['edits'],
  width?: number,
  height?: number,
): SVGDataUrl => {
  const strength = edits?.featherStrength || 6
  const circle = edits?.circle
  const borderRadius = edits?.borderRadius || 0
  const padding = strength * 2.5
  const shapeOffset = `calc(100%25 - ${padding * 2}px)`

  const halfWidth = (width ?? 0) / 2
  const halfHeight = (height ?? 0) / 2

  const rectangle: ShapeConfig = {
    element: 'rect',
    padding: `x='${padding}px' y='${padding}px'`,
    dimensions: `width='${shapeOffset}' height='${shapeOffset}' rx='${borderRadius}px'`,
  }

  const ellipse: ShapeConfig = {
    element: 'ellipse',
    padding: `rx='${halfWidth - padding}' ry='${halfHeight - padding}'`,
    dimensions: `cx='${halfWidth}' cy='${halfHeight}'`,
  }

  const determineShape = ({ element, padding, dimensions }: ShapeConfig) =>
    `%3C${element} ${padding} ${dimensions} fill='black'/%3E`

  const imageType = `data:image/svg+xml,`
  const openingSVG = `%3Csvg xmlns='http://www.w3.org/2000/svg' style='filter: blur(${strength}px);' width='${width}px' height='${height}px' fill='none' preserveAspectRatio='none'%3E`
  const shape = `${determineShape(circle ? ellipse : rectangle)}`
  const closingSVG = `%3C/svg%3E`

  return `${imageType}${openingSVG}${shape}${closingSVG}`
}

export default featheredShape
