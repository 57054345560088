import React from 'react'
import {
  SettingsInputWrapper,
  // SettingsDateWrapper,
  SettingsDropdownWrapper,
  // SettingsCheckWrapper,
  // SettingsArrayInput,
} from '.'

export default ({ formField }) => {
  return (
    <>
      {formField.type === 'input' && (
        <SettingsInputWrapper
          width="280px"
          margin="0 24px 24px 0"
          label={formField.label}
          name={formField.key}
        />
      )}
      {formField.type === 'dropdown' && (
        <SettingsDropdownWrapper
          width="280px"
          margin="0 24px 24px 0"
          label={formField.label}
          name={formField.key}
          items={formField.dropdownOptions}
          textKey={formField.textKey}
          valueKey={formField.valueKey}
          fontMenu={formField.fontMenu}
        />
      )}
      {/* {formField.type === "date" && (
        <CaseDateWrapper
          width="280px"
          margin="0 24px 24px 0"
          label={formField.label}
          name={formField.key}
        />
      )} */}
      {/* {formField.type === "check" && (
        <CaseCheckWrapper
          width="280px"
          margin="0 24px 24px 0"
          label={formField.label}
          type={formField.key}
        />
      )} */}
      {/* {formField.type === "array" && (
        <CaseArrayInput
          type={formField.key}
          shortType={formField.shortLabel}
          title={formField.label}
        />
      )} */}
      {/* {formField.type === "nestedArray" &&
        formField.typeKeys.map((type, index) => (
          <NestedObjectWrapper
            key={`${type}_${index}`}
            type={formField.key}
            shortType={type}
            title={type}
            formField={formField}
          />
        ))} */}
      {/* this is for arrays we do not know the 'type' of for all entries currently, only additionalFamily */}
      {/* {formField.type === "mappedArray" && (
        <NestedObjectWrapperWithKeyMapping formField={formField} />
      )} */}
    </>
  )
}
