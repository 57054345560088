import styled, { css } from 'styled-components'
import { colors as c } from 'utils'
import { Flex, Icon } from 'components'

export const CategoryItem = styled(Flex)`
  &:hover {
    background: ${c('gray5')};
  }
`

export const ItemArrow = styled(Icon)(
  ({ selected }) => css`
    transform: rotate(${selected ? '-180deg' : '0deg'});
    transition: 0.25s;
  `,
)
