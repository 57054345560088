import * as S from './SVG.styled'
import type { SVGProps } from 'types/svg'

const SVG = ({
  src,
  fill,
  hoverfill,
  opacity,
  clickable,
  ...props
}: SVGProps) => {
  return (
    <S.SVG
      src={src}
      fill={fill}
      hoverfill={hoverfill}
      opacity={opacity}
      clickable={clickable ? 1 : 0}
      {...props}
    />
  )
}

export default SVG
