import { handleFuneralHome } from '../caseDetails'

export default (editorProps) => {
  const { caseDetails } = editorProps
  if (!caseDetails) return

  let insertableFuneralHomeDetails = []

  if (editorProps.funeralHomeDetails.id) {
    handleFuneralHome(
      editorProps.editor,
      editorProps.funeralHomeDetails,
      insertableFuneralHomeDetails,
      editorProps.dateFormat,
      editorProps.selectedTheme,
    )
  }

  return insertableFuneralHomeDetails
}
