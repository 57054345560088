import { axios } from 'api'

export const getOrderLayouts = async (order_product_details_id) => {
  try {
    const response = await axios.get(
      `orderLayoutDetails?order_product_details_id=${order_product_details_id}`,
    )
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const createOrderLayout = async (payload) => {
  try {
    const response = await axios.post(`createOrderLayoutDetails`, payload)
    return response

    // expected payload:
    // {
    //   name: <String> (REQUIRED),
    //   product_id: <UUID> (REQUIRED),
    //   layout_id: <UUID> (REQUIRED),
    //   order_product_details_id: <UUID>,
    //   order_layout_id: <UUID>,
    //   default: <Boolean> (REQUIRED),
    //   paper_width: <Number> (REQUIRED),
    //   paper_height: <Number> (REQUIRED),
    //   theme_width: <Number> (REQUIRED),
    //   theme_height: <Number> (REQUIRED),
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const updateOrderLayout = async (order_layout_id, payload) => {
  try {
    const response = await axios.patch(
      `updateOrderLayoutDetails/${order_layout_id}`,
      payload,
    )
    return response

    // expected payload (ALL OPTIONAL):
    // {
    //   name: <String>,
    //   saved: <Boolean>,
    //   background_watermark_edited: <Boolean>,
    //   default: <Boolean>,
    //   paper_width: <Number>,
    //   paper_height: <Number>,
    //   theme_width: <Number>,
    //   theme_height: <Number>,
    // }
  } catch (error) {
    throw error
  }
}
