import React from 'react'
import { Flex, Image, Text } from 'components'
import { formatDate } from 'utils'
import { useFamilyUpload } from 'hooks'

export default ({ _case }) => {
  const { defaultPlaceholder } = useFamilyUpload()
  const images = _case.personal_images

  // if no images, use placeholder
  const finalSrc =
    images?.length === 0
      ? defaultPlaceholder
      : `${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}${images?.[0]}`

  return (
    <Flex
      column
      width="37.5%"
      maxWidth="540px"
      height="100vh"
      background="secondary"
      position="relative"
      justify="flex-end"
    >
      <Image src={finalSrc} width="100%" height="auto" position="absolute" />
      <Flex column width="100%" height="35%" center>
        <Text size="18px" margin="0 0 16px 0">
          In Memory Of
        </Text>
        <Text size="40px" margin="0 0 24px 0" weight="600">
          {_case.name_of_deceased}
        </Text>
        <Text size="20px">{`${formatDate(
          _case.date_of_birth,
          'MMMM do, yyyy',
        )} - ${formatDate(_case.date_of_death, 'MMMM do, yyyy')}`}</Text>
      </Flex>
    </Flex>
  )
}
