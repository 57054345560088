const calculateOpacity = (opacity) => 1 - opacity

export const DraggableBackground = ({
  backgroundX,
  backgroundY,
  pageLayout,
  preprinted,
  pageTwo,
  backgroundImageUrl,
}) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity:
    calculateOpacity(pageLayout?.background_opacity || 0) ||
    (pageTwo ? 0.3 : 0),
  backgroundPosition: `${backgroundX}px ${backgroundY}px`,
  backgroundRepeat: 'no-repeat',
  backgroundImage: `${
    pageLayout?.hide_background || preprinted
      ? ''
      : `url(${backgroundImageUrl})`
  }`,
  backgroundSize: `${
    pageLayout?.background_fill ? pageLayout.background_fill : 'cover'
  }`,
})
