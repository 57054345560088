import { formatDate } from 'utils/dates'
import { handleHtmlData } from 'utils/global'
import getThemeStylesForTemplate from 'utils/tinyMCE/templates/getThemeStylesForTemplate'

export default ({ editorProps, templateProps, firstInsertion = false }) => {
  const { caseDetails, dateFormat, selectedTheme } = editorProps
  const { serviceIndex } = templateProps

  const templateData = { serviceIndex }
  const encodedProperties = handleHtmlData.encode(templateData)

  const services = caseDetails?.services
  const currentService = services?.[serviceIndex]

  if (!currentService) return ''

  const {
    location,
    address,
    city,
    state,
    postal_code,
    date,
    start_time,
    end_time,
  } = currentService

  const locationParagraph = location ? `<p><span>${location}</span></p>` : ''

  const addressString = address ? `${address}` : ''
  const cityString = city ? `${city}, ` : ''
  const stateString = state ? `${state} ` : ''
  const postalCodeString = postal_code ? `${postal_code}` : ''

  const addressParagraph = address ? `<p><span>${addressString}</span></p>` : ''

  const hasCityProperties = city || state || postal_code
  const cityParagraph = hasCityProperties
    ? `<p><span>${cityString}${stateString}${postalCodeString}</span></p>`
    : ''

  const formattedDate = formatDate(date, dateFormat)
  const dateParagraph = date ? `<p><span>${formattedDate}</span></p>` : ''

  const startTimeString = start_time ? `${start_time}` : ''
  const endTimeString = end_time ? `${end_time}` : ''
  const timeParagraph =
    start_time && end_time
      ? `<p><span>${startTimeString} to ${endTimeString}</span></p>`
      : ''

  let themeStyles = ''

  /* Apply theme styling if:
   * - This is a new template insertion
   * - Theme object is not null
   */
  if (firstInsertion && selectedTheme) {
    themeStyles = getThemeStylesForTemplate({ selectedTheme })
  }

  const html = `
    <div data-template="service" data-properties="${encodedProperties}" class="mceNonEditable" style="${themeStyles}">
      ${locationParagraph}
      ${addressParagraph}
      ${cityParagraph}
      ${dateParagraph}
      ${timeParagraph}
    </div>
  `

  return html
}
