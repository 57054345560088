import styled, { css } from 'styled-components'
import { spacing, sizing, decorations, image } from 'styles'

export const Image = styled.img(
  ({ ...props }) => css`
    ${spacing(props)}
    ${sizing(props)}
    ${decorations(props)}
    ${image(props)}
  `,
)
