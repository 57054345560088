import styled, { css } from 'styled-components'
import { Flex } from 'components'

const CommonSlideoutStyles = css`
  overflow-x: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`

export const SlideoutMenu = styled(Flex)`
  ${CommonSlideoutStyles}
`

export const ModalSlideout = styled(Flex)(
  ({ translateY }) => css`
    ${CommonSlideoutStyles}
    transform: ${translateY ? `translateY(${translateY})` : 'none'};
  `,
)

export const Content = styled(Flex)(
  ({ overflowY, overflowX }) => css`
    overflow-y: ${overflowY};
    overflow-x: ${overflowX};

    /* this after element allows for "overflow: auto" to have margin */
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
    }
  `,
)
