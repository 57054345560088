import React, { useState, useEffect, useRef } from 'react'
import { Button, Flex } from 'components'
import { generatePDF, generatePDFStatus } from 'api/orders'
import { useRecoilValue } from 'recoil'
import { editSettingsFieldSelector } from 'atoms'
import * as S from './PrintPreview.styled'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler()
  const printArea = useRef()
  const leftOffset = useRecoilValue(
    editSettingsFieldSelector({
      name: 'print_parameter_left',
      path: 'value',
      parse: true,
    }),
  )

  const rightOffset = useRecoilValue(
    editSettingsFieldSelector({
      name: 'print_parameter_right',
      path: 'value',
      parse: true,
    }),
  )
  const topOffset = useRecoilValue(
    editSettingsFieldSelector({
      name: 'print_parameter_top',
      path: 'value',
      parse: true,
    }),
  )
  const bottomOffset = useRecoilValue(
    editSettingsFieldSelector({
      name: 'print_parameter_bottom',
      path: 'value',
      parse: true,
    }),
  )
  const [page, setPage] = useState({})
  const [printing, setPrinting] = useState(false)

  const pageSizes = [11, 8.5]
  const pagePositions = [0.5, 0.25]
  const dpi = 96

  const getPrintSettings = async () => {
    //update the print page with correct offsets

    const page = {}
    if (topOffset && topOffset !== '0.00000') {
      page.top = (pagePositions[1] - topOffset) * dpi
    } else if (bottomOffset && bottomOffset !== '0.00000') {
      page.top = (pagePositions[1] + bottomOffset) * dpi
    } else {
      page.top = pagePositions[1] * dpi
    }
    if (leftOffset && leftOffset !== '0.00000') {
      page.left = (pagePositions[0] - leftOffset) * dpi
    } else if (rightOffset && rightOffset !== '0.00000') {
      page.left = (pagePositions[0] + rightOffset) * dpi
    } else {
      page.left = pagePositions[0] * dpi
    }

    //ensure offset does not go off the page
    if (page.top < 0) page.top = 0
    if (page.left < 0) page.left = 0
    setPage(page)
  }

  useEffect(
    () => {
      getPrintSettings()
    },
    //eslint-disable-next-line
    [leftOffset, topOffset, rightOffset, bottomOffset],
  )

  const printPDF = async () => {
    setPrinting(true)

    let body = {
      html: 'pdfAlign',
      page_height: 8.5,
      page_width: 11,
    }

    try {
      //this is the only request we make using fetch instead of axios, I added this in the api files
      const response = await generatePDF(body)
      if (response) {
        const checkPDFStatus = async (id) => {
          let PDFstatus = await generatePDFStatus(id)
          while (
            PDFstatus.progress !== 100 &&
            PDFstatus.progress !== undefined
          ) {
            await new Promise((resolve) => setTimeout(resolve, 2000)) // Wait for 2 seconds
            PDFstatus = await generatePDFStatus(id)
          }
          return PDFstatus
        }

        const PDFstatus = await checkPDFStatus(response.id)
        if (PDFstatus.progress === undefined) {
          setPrinting(false)
          //FE handle received s3 URL here
        }
      }
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  return (
    <Flex className="PrintArea" full>
      <div className="preview" style={{ margin: '0 0 48px 0' }}>
        <Button
          disabled={printing}
          margin="0 0 20px 0"
          primary
          onClick={() => printPDF()}
        >
          Print
        </Button>

        <S.PrintPreview
          page={page}
          top={topOffset}
          left={leftOffset}
          pageSizes={pageSizes}
          pagePositions={pagePositions}
          dpi={dpi}
          id="printBuild"
          className="printBuild"
          ref={printArea}
          style={{
            margin: '0 0 0 10px',
            opacity: `${printing ? '.5' : '1'}`,
          }}
        >
          <div id="page" className="printPage">
            <div className="printOutlines">
              <div className="boxes">
                <span style={{ fontWeight: 800 }}>BOX 1</span>
                <br /> Needs to be 0.5" from edge of paper
                <br />
                <span
                  style={{
                    position: 'absolute',
                    left: '0px',
                    fontSize: '35px',
                    fontFamily: 'Century Gothic',
                  }}
                >
                  &larr;
                </span>
              </div>
              <div className="eightLine">
                <div
                  className="boxes"
                  style={{
                    top: '0px',
                  }}
                >
                  <span style={{ fontWeight: '800' }}>BOX 2</span>
                  <br /> Needs to be 8" from Box 1
                </div>
              </div>
              <div
                style={{ position: 'absolute', top: '510px', left: '350px' }}
              >
                8"
              </div>
              <div className="arrow">
                <div className="line"></div>
                <div className="right-point point"></div>
                <div className="left-point point"></div>
              </div>
              <div className="top">
                <span style={{ fontWeight: '800' }}>BOX 3</span>
                <br />
                This line should be 0.25" from the top edge
                <div
                  style={{
                    position: 'absolute',
                    left: '175px',
                    top: '-4px',
                    fontSize: '35px',
                    fontFamily: 'Century Gothic',
                  }}
                >
                  &uarr;
                </div>
              </div>
              <div>
                <img
                  style={{
                    position: 'absolute',
                    width: '150px',
                    left: '600px',
                    top: '30px',
                  }}
                  src="https://directorsprintsuite.com/images/logo.png"
                  alt=""
                />
              </div>
            </div>
            <div
              className="testPrint"
              style={{
                textAlign: 'left',
                position: 'absolute',
                top: '50px',
                left: '75px',
                color: '#194c73',
              }}
            >
              <div style={{ fontSize: '14px' }}>Printer Alignment Sheet</div>
              <div
                style={{
                  marginBottom: '0px',
                  fontSize: '12px',
                  lineHeight: '16px',
                }}
              >
                Instructions:
              </div>
              <div className="listPrint">
                <p className="printNumber">
                  1. Print this sheet from the tray that you plan on printing
                  stationery.
                </p>
                <p className="printItem">
                  - Make sure "fit to print" is not selected when printing.
                </p>
                <p className="printNumber">
                  2. Box 1 should be 0.5 inches from the left edge of the paper.
                </p>
                <p className="printNumber">
                  3. Box 3 should be 0.25 inches from the top edge of the paper.
                </p>
                <p className="printItem">
                  - If either of these measurements is off, correct them using
                  the adjustments on the left.
                </p>
                <p className="printItem">
                  - This should only have to be done once, unless something
                  changes in your printer.
                </p>
                <p className="printNumber">
                  4. Measure the distance from Box 1 to Box 2
                </p>
                <p className="printItem">
                  - This measurement is important when printing bookmarks and
                  prayer cards.
                </p>
                <p className="printItem">
                  - If this measurement isn't correct then the alignment will
                  not be consistent.
                </p>
                <p className="printNumber">
                  5. This measurement between boxes should be 8 inches.
                </p>
                <p className="printItem">
                  - If it's not then your printer driver is slightly shrinking
                  your print, and this printer issue cannot be corrected by this
                  tool.
                </p>
                <p className="printItem">
                  - Please contact your printer manufacturer for an upgraded
                  printer driver.
                </p>
              </div>
            </div>
          </div>
        </S.PrintPreview>
      </div>
    </Flex>
  )
}
