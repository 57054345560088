import React from 'react'
import * as icons from 'assets/icons'
import { Grid, Flex, Text, Icon } from 'components'

export default () => (
  <Grid columns="4">
    {Object.keys(icons).map((icon, i) => (
      <Flex
        key={`${icon}${i}`}
        column
        center
        margin="12px"
        width="100px"
        radius="2px"
      >
        <Icon icon={icon} fill="gray1" margin="0 0 8px 0" />
        <Text size="12px">{icon}</Text>
      </Flex>
    ))}
  </Grid>
)
