import React from 'react'
import { Grid } from 'react-loader-spinner'
import { useColors } from 'hooks'

export default ({ children, isLoading, color, ...props }) =>
  isLoading ? (
    <Grid color={useColors()(color || 'gray1')} height={60} width={60} />
  ) : (
    children
  )
