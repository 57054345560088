import React from 'react'
import Masonry from 'react-responsive-masonry'
import { Flex, Image, Text } from 'components'

export default ({ images = [] }) => {
  return (
    <Flex column margin="32px 0 80px 0">
      <Flex width="100%" justify="flex-end">
        <Text color="gray2" weight="500" margin="0 0 8px 0">
          {images.length} images
        </Text>
      </Flex>
      <Masonry gutter="16px">
        {images.map((image) => (
          <Image
            key={image}
            src={`${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}${image}`}
          />
        ))}
      </Masonry>
    </Flex>
  )
}
