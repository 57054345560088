import React, { useState, useEffect } from 'react'
import { Flex, MenuTabs, PropertyMenu } from 'components'
import { rightMenuAtom, roleAtom } from 'atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'

export default () => {
  const [activeTab, setActiveTab] = useState('Elements')
  const setRightMenu = useSetRecoilState(rightMenuAtom)
  const userRole = useRecoilValue(roleAtom)

  const isFhUser = userRole === 'fh-user'

  useEffect(() => {
    setRightMenu(activeTab)
    //eslint-disable-next-line
  }, [activeTab])

  const adminTabs = ['Elements', 'Background', 'Settings']
  const userTabs = ['Elements', 'Settings']
  const tabs = isFhUser ? userTabs : adminTabs

  return (
    <Flex
      column
      justify="space-between"
      height="100%"
      width="250px"
      background="white"
      zindex="1"
      borderLeft="1px solid"
      borderColor="gray4"
    >
      <MenuTabs activeTab={[activeTab, setActiveTab]} tabs={tabs} />
      <PropertyMenu activeTab={activeTab} />
      {/* <GiveFeedback /> */}
      {/*  NOTE: This component will be eventually reused but its functionality will change */}
    </Flex>
  )
}
