import { registerTextCasingPlugin, registerTextOutlinePlugin } from 'utils'
import highlightDynamicTextPlugin from 'utils/tinyMCE/plugins/highlightDynamicTextPlugin'

export default (editorProps) => {
  const { editor } = editorProps

  editor.editorManager.PluginManager.add('casedetails', function (editor) {
    const pluginProps = { ...editorProps, editor }
    registerTextCasingPlugin(pluginProps)
    registerTextOutlinePlugin(pluginProps)
    highlightDynamicTextPlugin(pluginProps)
  })
}
