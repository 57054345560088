import React, { useState, useEffect } from 'react'
import { Flex, Text, Image, Check } from 'components'
import * as S from './CardSelector.styled'
import { useResetRecoilState } from 'recoil'
import { selectedProductsAtom, selectedThemeAtom } from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({
  allowMultiple,
  cards,
  cardWidth,
  cardHeight,
  cardPadding,
  cardIcon,
  productIcon,
  iconHover,
  selectedState,
  cardAlign,
  cardJustify,
  prependURL,
  imageHeight,
  imageWidth,
  imageFit,
  imagePosition,
  matchProperty,
  selectedStateUpdate,
  isProductLine,
  ...props
}) => {
  const handleError = useErrorHandler()
  const [selected, setSelected] = selectedState
  const [selectedItems, setSelectedItems] = useState(allowMultiple ? [] : null)
  const [iconVisible, setIconVisible] = useState('')
  const resetProducts = useResetRecoilState(selectedProductsAtom)
  const resetTheme = useResetRecoilState(selectedThemeAtom)

  useEffect(() => {
    setSelectedItems(selected)
  }, [selected, setSelected])

  const toggleSelect = async (card) => {
    try {
      if (isProductLine) {
        resetProducts()
        resetTheme()
      }
      if (allowMultiple) {
        selectedStateUpdate && (await selectedStateUpdate())
        setSelected(card)
      } else {
        if (selected?.id === card.id) {
          setSelected(null)
        } else {
          setSelected(card)
        }
      }
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const toggleHover = (cardId) => {
    if (iconHover) {
      cardId === iconVisible && setIconVisible('')
      cardId !== iconVisible && setIconVisible(cardId)
    }
  }

  const determineSelected = (card) => {
    if (allowMultiple) {
      return selectedItems.some(
        (item) => card.id === item[matchProperty || 'id'] && item.selected,
      )
    } else {
      return selectedItems?.id
        ? selectedItems?.id === card.id || selectedItems?.id === card.theme_id
        : selectedItems === card.id
    }
  }

  return (
    <Flex
      width="100%"
      justify={cardJustify}
      align={cardAlign}
      wrap="wrap"
      {...props}
    >
      {cards.map((card, cardIndex) => (
        <S.SelectableCard
          column
          justify="center"
          align="center"
          position="relative"
          key={`card-${cardIndex}`}
          id={`${card.title}-${cardIndex}`}
          width={cardWidth}
          height={cardHeight}
          cardPadding={cardPadding}
          margin={cardIndex === cards.length - 1 ? '0 0 16px' : '0 16px 16px 0'}
          isSelected={determineSelected(card)}
          onClick={() => toggleSelect(card)}
          onMouseEnter={() => toggleHover(`${card.title}-${cardIndex}`)}
          onMouseLeave={() => toggleHover(`${card.title}-${cardIndex}`)}
        >
          {productIcon && (
            <Check
              position="absolute"
              top="12px"
              left="12px"
              checked={determineSelected(card)}
            />
          )}
          {cardIcon && (
            <S.CardIcon
              key={`${cardIcon.icon}-icon`}
              icon={cardIcon.icon}
              fill={card.theme_id || card.isPinned ? 'secondary' : 'gray3'}
              opacity={
                !iconHover ||
                iconVisible === `${card.title}-${cardIndex}` ||
                card.isPinned
                  ? 1
                  : 0
              }
              onClick={(e) => cardIcon.clickHandler(e, card)}
            />
          )}
          {(card.image || card.thumbnail) && (
            <Flex align="center" height={imageHeight || '124px'}>
              <Image
                src={
                  prependURL
                    ? `${prependURL}${card.image || card.thumbnail}?w=250`
                    : card.image || card.thumbnail
                }
                width={imageWidth || '220px'}
                height={imageHeight || '124px'}
                objectFit={imageFit || 'contain'}
                objectPosition={imagePosition || 'center'}
              />
            </Flex>
          )}
          {(card?.name || card?.title) && (
            <Text
              size="14px"
              weight="bold"
              justify="center"
              align="center"
              whiteSpace="nowrap"
            >
              {card?.name || card?.title}
            </Text>
          )}
          {card.description && (
            <Text
              size="14px"
              color="gray2"
              justify="center"
              align="center"
              whiteSpace="nowrap"
            >
              {card.description}
            </Text>
          )}
        </S.SelectableCard>
      ))}
    </Flex>
  )
}
