import { isNumber } from 'lodash'

export default (position) => {
  if (position) {
    if (!isNumber(position) && position.includes('%')) {
      return 0
    } else if (!isNumber(position) && position.includes('px')) {
      return parseInt(position)
    } else {
      return position
    }
  } else {
    return 0
  }
}
