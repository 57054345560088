import React, { useState } from 'react'
import { Button } from 'components'
import { useSetToast } from 'hooks'
import { makeGlobalLayouts } from 'api/admin'

export default () => {
  const [loading, setLoading] = useState(false)
  const setToast = useSetToast()

  const launchGlobalLayouts = async () => {
    setLoading(true)

    try {
      await makeGlobalLayouts()
      setToast({
        icon: 'check',
        text: 'Global layouts created successfully',
      })
    } catch (error) {
      setToast({
        text: 'Failed to create global layouts',
      })
    } finally {
      setLoading(false)
    }
  }

  const buttonText = loading
    ? '🌎 Making all personal templates global...'
    : '🌎 Make all personal templates global'

  return (
    <Button primary onClick={launchGlobalLayouts} disabled={loading}>
      {buttonText}
    </Button>
  )
}
