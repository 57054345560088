import { useRecoilValue, useRecoilCallback } from 'recoil'
import {
  editorClipboardAtom,
  itemAtomFamily,
  itemIdAtom,
  selectedItemsAtom,
} from 'atoms'
import { v4 as uuidv4 } from 'uuid'

export const usePasteMultipleItemsFromClipboard = () => {
  const itemIds = useRecoilValue(itemIdAtom)
  const clipboard = useRecoilValue(editorClipboardAtom)

  const someAsyncOperation = async () => {
    return new Promise((resolve) => setTimeout(resolve, 100))
  }

  const pasteMultiItemsFromClipBoard = useRecoilCallback(
    ({ set }) =>
      async () => {
        const newSelectedItemsIds = []
        const newSelectedItems = []
        const newItemIds = [...itemIds]

        await Promise.all(
          clipboard.map(async (item) => {
            await someAsyncOperation()

            const uuid = uuidv4()
            const copiedItem = { ...item }

            copiedItem.id = uuid
            copiedItem.x = parseInt(copiedItem.x) + 15
            copiedItem.y = parseInt(copiedItem.y) + 15

            set(itemAtomFamily(copiedItem.id), copiedItem)
            newItemIds.push(copiedItem.id)
            newSelectedItemsIds.push(copiedItem.id)
            newSelectedItems.push(copiedItem)
          }),
        )

        set(itemIdAtom, newItemIds)
        set(selectedItemsAtom, newSelectedItemsIds)
        set(editorClipboardAtom, newSelectedItems)
      },
  )

  return () => pasteMultiItemsFromClipBoard()
}
