import styled, { css } from 'styled-components'

export const PrintPreview = styled.div(
  ({ page, left, top, pageSizes, pagePositions, dpi }) => css`
    .printPage {
      background: white;

      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      padding: ${page.top}px 0 0 ${page.left}px;
      height: ${pageSizes[1] * dpi}px;
      width: ${pageSizes[0] * dpi}px;
      position: relative;
      box-sizing: border-box;
    }

    .printOutlines {
      width: 100%;
      height: 100%;
      border-left: 1px solid #888;
      border-top: 1px solid #888;
      width: ${pageSizes[0] * dpi -
      (pagePositions[0] * 2 - parseFloat(left)) * dpi}px;
      height: ${pageSizes[1] * dpi -
      (pagePositions[1] * 2 - parseFloat(top)) * dpi}px;
    }

    .boxes {
      top: 400px;
      position: absolute;
      height: 75px;
      border: 1px solid #888;
      width: 140px;
      text-align: center;
      padding-top: 5px;
      font-size: 12px;
    }

    .eightLine {
      border-left: 1px solid #888;
      position: absolute;
      top: 400px;
      left: ${8 * dpi + page.left}px;
      width: 1px;
      height: 150px;
    }

    .arrow {
      width: ${8 * dpi}px;
      left: ${page.left}px;
      top: 500px;
      position: absolute;
    }

    .line {
      width: ${8 * dpi - 20}px;
      left: 12px;
      position: absolute;
      margin-top: 5px;
      background: blue;
      height: 2px;
      float: left;
    }

    .point {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }

    .right-point {
      border-left: 12px solid blue;
      float: right;
    }

    .left-point {
      border-right: 12px solid blue;
      float: left;
    }

    .top {
      top: ${page.top}px;
      position: absolute;
      left: 350px;
      width: 200px;
      height: 50px;
      font-size: 12px;
      border: 1px solid #888;
      padding: 5px 20px;
    }

    .listPrint p {
      width: 600px;
      color: #194c73;
      line-height: 12px;
      font-size: 12px;
      margin-bottom: 0px;
      margin: 5px;
    }

    .listPrint .printNumber {
      padding: 5px 0px;
    }

    .listPrint .printItem {
      padding-left: 15px;
      font-style: italic;
    }
  `,
)
