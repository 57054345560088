import React, { useEffect, useState } from 'react'
import { Expandable, Flex } from 'components'
import { CaseFormSection } from '..'

export default ({ saveCase, setActiveTab, activeTab }) => {
  const [expandedPersonal, setExpandedPersonal] = useState(true)
  const [expandedFamily, setExpandedFamily] = useState('')
  const [expandedAdditionalFamily, setExpandedAdditionalFamily] = useState('')

  useEffect(() => {
    setExpandedPersonal(true)
    setExpandedFamily(false)
    setExpandedAdditionalFamily(false)
  }, [activeTab])

  const saveAndContinue = (expanded) => {
    switch (expanded) {
      case 'personalInfo':
        setExpandedPersonal(false)
        setExpandedFamily(true)
        break
      case 'familyInfo':
        setExpandedFamily(false)
        setExpandedAdditionalFamily(true)
        break
      case 'additionalFamily':
        setExpandedPersonal(false)
        setExpandedFamily(false)
        setExpandedAdditionalFamily(false)
        setActiveTab('Obituary')
        break
      default:
        //for now collapse all
        setExpandedPersonal(false)
        setExpandedFamily(false)
        setExpandedAdditionalFamily(false)
    }
    saveCase()
  }

  return (
    <Flex column full padding="0 48px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 32px 0"
          title="Personal Information"
          show={[expandedPersonal, setExpandedPersonal]}
          full
        >
          <CaseFormSection
            formSection="personalInfo"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>

        <Expandable
          margin="0 0 32px 0"
          title="Family Information"
          show={[expandedFamily, setExpandedFamily]}
          full
        >
          <CaseFormSection
            formSection="familyInfo"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
        <Expandable
          margin="0 0 32px 0"
          title="Additional Family Information"
          show={[expandedAdditionalFamily, setExpandedAdditionalFamily]}
          full
        >
          <CaseFormSection
            formSection="additionalFamily"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
      </Flex>
    </Flex>
  )
}
