import React, { useEffect } from 'react'
import { Flex } from 'components'
import LeftHero from './LeftHero'
import RightContent from './RightContent'
import { useFamilyUpload } from 'hooks'

export default () => {
  const { token, _case, getCase } = useFamilyUpload(window.location.pathname)

  useEffect(() => {
    token && getCase(token)
    //eslint-disable-next-line
  }, [token])

  return (
    <Flex>
      <LeftHero _case={_case} />
      <RightContent _case={_case} />
    </Flex>
  )
}
