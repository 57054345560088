import { useRecoilValue, useSetRecoilState } from 'recoil'
import { editorClipboardAtom, itemsSelector, selectedItemAtom } from 'atoms'

export const useCopyMultiItemsToClipboard = () => {
  const items = useRecoilValue(itemsSelector())
  const setClipboard = useSetRecoilState(editorClipboardAtom)
  const setSelectedItem = useSetRecoilState(selectedItemAtom)

  const copyMultiItemsToClipboard = () => {
    setSelectedItem('')

    const copiedItems = [...items]
    setClipboard(copiedItems)
  }

  return () => copyMultiItemsToClipboard()
}
