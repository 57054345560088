/**
 * Optimizes an image by resizing it to not exceed the specified maximum dimensions,
 * maintaining the original aspect ratio, and converting it to WebP format for minimal file size.
 *
 * @param {HTMLImageElement} image - The source image to be optimized.
 * @param {number} maxWidth - The maximum width allowed for the optimized image.
 * @param {number} maxHeight - The maximum height allowed for the optimized image.
 * @param {number} quality - The quality of the output image, between 0 (lowest) and 1 (highest).
 * @returns {Promise<string>} A data URL representing the optimized image in WebP format.
 */

const optimizeImage = async (image, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas')
      let width = image.width
      let height = image.height

      // Maintain aspect ratio
      const aspectRatio = width / height
      if (width > height) {
        if (width > maxWidth) {
          width = maxWidth
          height = maxWidth / aspectRatio
        }
      } else {
        if (height > maxHeight) {
          height = maxHeight
          width = maxHeight * aspectRatio
        }
      }

      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, width, height)

      // Check if the browser supports WebP
      const supportsWebP = () => {
        const elem = document.createElement('canvas')
        return (
          elem.getContext?.('2d') &&
          elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
        )
      }

      const format = supportsWebP() ? 'image/webp' : 'image/jpeg'
      const dataUrl = canvas.toDataURL(format, quality)
      resolve(dataUrl)
    } catch (error) {
      console.error('Error optimizing image:', error)
      reject(error) // Rethrow the error after logging it, to ensure the caller is aware an error occurred.
    }
  })
}

export default optimizeImage
