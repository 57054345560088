import React from 'react'
import { defaultFHLogo } from 'assets'
import { useLocation } from 'react-router-dom'
import { Flex, Link, Image, NavItem, UserDropDown } from 'components'

export default () => {
  const { pathname: location } = useLocation()

  return (
    <>
      <Flex center>
        <Link href="/dashboard">
          <Image clickable src={defaultFHLogo} />
        </Link>
      </Flex>
      <Flex>
        <NavItem
          text="Clients"
          link="/admin"
          selected={location === '/admin'}
        />
        <NavItem
          text="Assets"
          link="/admin/assets"
          selected={location === '/admin/assets'}
        />
        <NavItem
          text="Products"
          link="/admin/products"
          selected={location === '/admin/products'}
        />
        <NavItem
          text="Themes"
          link="/admin/themes"
          selected={location === '/admin/themes'}
        />
        <NavItem
          text="Analytics"
          link="/admin/analytics"
          selected={location === '/admin/analytics'}
        />
        <NavItem
          text="Status Message"
          link="/admin/status-message"
          selected={location === '/admin/status-message'}
        />
      </Flex>
      <UserDropDown />
    </>
  )
}
