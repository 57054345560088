import React, { useState } from 'react'
import { Check, DateInput, Dropdown, Expandable, Flex, Text } from 'components'

export default () => {
  const [dropdown, setDropdown] = useState('')
  const [date, setDate] = useState(null)
  const [expanded, setExpanded] = useState(true)
  const [moreItems, setMoreItems] = useState(false)

  return (
    <>
      <Flex column align="center">
        <Flex width="calc(90vw - 200px)">
          <Expandable
            title="Expandable box"
            show={[expanded, setExpanded]}
            full
          >
            <Text>Test item 1</Text>
            <Text>Test item 2</Text>
            <Text>Test item 3</Text>
            <DateInput date={[date, setDate]} left />
            <Dropdown
              label="Default font"
              selected={[dropdown, setDropdown]}
              placeholder="Select a font"
              textKey="fontName"
              valueKey="font"
              items={[
                { fontName: 'Noto Serif TC', font: 'notoSerifTC' },
                { fontName: 'Century Gothic', font: 'centuryGothic' },
                { fontName: 'Times New Roman', font: 'timesNewRoman' },
              ]}
            />
            <Check checked={moreItems} onClick={() => setMoreItems(!moreItems)}>
              Toggle more Items
            </Check>
            {moreItems && (
              <>
                <Text>Test item 4</Text>
                <Text>Test item 5</Text>
                <Text>Test item 6</Text>
              </>
            )}
          </Expandable>
        </Flex>
      </Flex>
    </>
  )
}
