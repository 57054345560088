export default new Array(10).fill().map(() => ({
  id: '',
  name: '',
  email: '',
  type: '',
  manager: null,
  funeral_home_id: '',
  corporation_id: null,
  archived: null,
}))
