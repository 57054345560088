const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const convertKeysToSnakeCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item))
  } else if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = camelToSnakeCase(key)
      acc[newKey] = convertKeysToSnakeCase(value)
      return acc
    }, {})
  }
  return obj
}

export default convertKeysToSnakeCase
