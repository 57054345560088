import React, { useEffect } from 'react'
import { Flex, Text, Slider, ColorPicker, Button } from 'components'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const [xOffset, setXOffset] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.textShadow.xOffset' }),
  )

  const [yOffset, setYOffset] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.textShadow.yOffset' }),
  )
  const [blur, setBlur] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.textShadow.blur' }),
  )

  const [opacity, setOpacity] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.textShadow.opacity' }),
  )
  const [color, setColor] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'edits.textShadow.color' }),
  )

  useEffect(() => {
    !blur && setBlur(0)
    !xOffset && setXOffset(0)
    !yOffset && setYOffset(0)
    !color && setColor('#FFF')
    //eslint-disable-next-line
  }, [blur, xOffset, yOffset, opacity])

  const resetAll = () => {
    setBlur(0)
    setXOffset(0)
    setYOffset(0)
    setOpacity(100)
    setColor()
  }

  return (
    <Flex column padding="0 12px">
      <Text>Left/Right Offset</Text>
      <Slider
        sliderState={[xOffset, setXOffset]}
        min="-25"
        max="25"
        margin="0 0 20px"
        initialValue={0}
      />

      <Text>Top/Bottom Offset</Text>
      <Slider
        sliderState={[yOffset, setYOffset]}
        min="-25"
        max="25"
        margin="0 0 20px"
        initialValue={0}
      />

      <Text>Blur/Spread</Text>
      <Slider
        sliderState={[blur, setBlur]}
        min="0"
        max="100"
        margin="0 0 20px"
        initialValue={0}
      />

      <Text>Opacity</Text>
      <Slider
        sliderState={[opacity, setOpacity]}
        min="0"
        max="100"
        margin="0 0 20px"
        initialValue={100}
      />
      <ColorPicker color={[color, setColor]} label="Color" margin="0 0 20px" />
      <Button margin="0 0 20px" onClick={() => resetAll()}>
        Reset All
      </Button>
    </Flex>
  )
}
