import { default as isEmpty } from 'lodash/isEmpty'
import { camelToSnakeCase } from 'utils'

export default ({ originalPage, layoutUpdates }) => {
  // make a deep copy of the page
  const page = JSON.parse(JSON.stringify(originalPage))
  let themeLayout = camelToSnakeCase(page?.product?.themeLayout)

  // create the object for the updated pages
  const updatedPage = {
    ...themeLayout,
    ...layoutUpdates,
  }

  if (isEmpty(updatedPage?.edited_background || {}))
    updatedPage.edited_background = {}

  // update the replacement_image
  page.product.themeLayout = updatedPage

  // return the modified page
  return page
}
