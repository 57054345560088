import styled, { css } from 'styled-components'
import { colors as c } from 'utils'
import { Flex, Icon } from 'components'

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: none;
  position: absolute;
  top: -12px;
  right: -12px;
  background: ${c('white')};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`

export const ImageItem = styled(Flex)`
  &:hover {
    background: ${c('gray5')};

    ${IconWrapper} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const Image = styled(Flex)(
  ({ src, srcPrefix }) => css`
    background: url(${srcPrefix || ''}${src}) center/contain no-repeat;
  `,
)

export const Page = styled(Flex)(
  () => css`
    border: 1px solid ${c('gray1')};
    border-radius: 2px;
    height: 50px;
    width: 375px;
  `,
)

export const ProductWrapper = styled.div`
  transform: translate(-50%, -50%) scale(0.1);
  max-width: 150px;
  max-height: 75px;
  position: relative;
`

export const DragIcon = styled(Icon)(
  ({ isDragged, isSelected }) => css`
    cursor: ${isSelected || isDragged ? 'grabbing' : 'grab'};
  `,
)
