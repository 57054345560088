import { css } from 'styled-components'
import type { AlignmentProps, FlexProps } from 'types/layout'
import type { StyledFunction } from 'types/style'

const alignment = ({ center, justify, align }: AlignmentProps) => css`
  justify-content: ${center ? 'center' : justify};
  align-items: ${center ? 'center' : align};
`

const flex: StyledFunction<FlexProps> = ({ wrap, flex, column }) => css`
  display: flex;
  flex-wrap: ${wrap};
  flex: ${flex};
  flex-direction: ${column && 'column'};
  ${alignment}
`

export default flex
