import React from 'react'
import { Flex, MenuDrawer, InsertImageGrid, CustomVerse } from 'components'

export default ({
  drawers,
  categoryKey,
  categoryNameKey,
  imageKey,
  nameKey,
  children,
  setShow,
  type,
  srcPrefix,
  reload,
  isVerse,
  ...props
}) => (
  <Flex column full margin="16px" {...props}>
    {drawers?.map((list, index) => (
      <MenuDrawer
        startOpen={false}
        title={list?.[categoryNameKey]}
        subtitle={!type ? list?.[categoryKey].length : ''}
        key={index}
        reload={reload}
        full
        doNotCapitalize={type === 'customVerse' ? true : false}
      >
        {!type && (
          <InsertImageGrid
            items={list?.[categoryKey]}
            imageKey={imageKey}
            nameKey={nameKey}
            setShow={setShow}
            srcPrefix={srcPrefix}
            isVerse={isVerse}
          />
        )}
        {type === 'customVerse' && (
          <CustomVerse
            verse={list}
            setShow={setShow}
            reload={reload}
            key={index}
          />
        )}
      </MenuDrawer>
    ))}
  </Flex>
)
