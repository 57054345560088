import styled from 'styled-components'
import { disabled } from 'styles'
import { colors as c } from 'utils'
import { Flex } from 'components'

export const MenuItem = styled(Flex)`
  ${disabled}
  &:hover {
    background: ${c('gray5')};
  }
`
