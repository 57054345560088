import {
  editorPagesAtom,
  selectedPageAtom,
  objectSelector,
  itemIdAtom,
  itemAtomFamily,
  pageLayoutAtom,
  watermarkIdAtom,
  watermarkAtomFamily,
} from 'atoms'
import { default as _omit } from 'lodash/omit'
import { useRecoilValue, useRecoilCallback, useSetRecoilState } from 'recoil'

export default () => {
  const selectedPage = useRecoilValue(selectedPageAtom)
  //selectedPage - 1 gets us the array index for the page
  const setEditorPages = useSetRecoilState(
    objectSelector({
      atom: editorPagesAtom,
      property: `[${selectedPage - 1}].product.items`,
    }),
  )
  const setEditorWatermarks = useSetRecoilState(
    objectSelector({
      atom: editorPagesAtom,
      property: `[${selectedPage - 1}].product.watermarks`,
    }),
  )

  const setEditorPagesProduct = useSetRecoilState(
    objectSelector({
      atom: editorPagesAtom,
      property: `[${selectedPage - 1}].product.themeLayout`,
    }),
  )

  const saveItems = useRecoilCallback(({ snapshot }) => async () => {
    //get a snapshot of the recoil state (this is fuzzy to me still)
    //get the ids from the itemIds atom
    const ids = await snapshot.getPromise(itemIdAtom)
    //create an empty array to store the returned values
    const allItems = []

    //this code came from the same sample
    //for each id get the item - which is the object
    //push that to the array
    for (const id of ids) {
      const item = await snapshot.getPromise(itemAtomFamily(id))
      item?.type && allItems.push(item)
    }
    setEditorPages(allItems)

    //run the same logic as for items above to save the watermarks to the json
    const watermarks = await snapshot.getPromise(watermarkIdAtom)
    const watermarkItems = []
    for (const id of watermarks) {
      const item = await snapshot.getPromise(watermarkAtomFamily(id))
      item?.type && watermarkItems.push(item)
    }
    watermarkItems.length > 0 && setEditorWatermarks(watermarkItems)

    const updatedThemeLayout = await snapshot.getPromise(pageLayoutAtom)

    const omit = [
      'archived',
      'available',
      'id',
      'layout_id',
      'paper_type',
      'product_id',
    ]

    const themeLayout = _omit(updatedThemeLayout, omit)
    setEditorPagesProduct(themeLayout)
  })
  return () => saveItems()
}
