import { axios } from 'api'

// NOTE: This endpoint is not used
export const create = async (payload) => {
  try {
    const response = await axios.post(`createLayout`, payload)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const update = async (layout_id, payload) => {
  try {
    const response = await axios.patch(`layouts/${layout_id}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const getLayoutsByProduct = async (product_id) => {
  try {
    const response = await axios.get(`layouts?product_id=${product_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getById = async (layout_id) => {
  try {
    const response = await axios.get(`layouts/${layout_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getForCustomThemes = async (product_id) => {
  try {
    const response = await axios.get(`layoutsForCustomThemes/${product_id}`)
    return response
  } catch (error) {
    throw error
  }
}

//TODO: figure out config and payload
//export const uploadRepalcementImage = async (layout_id, payload) => axios.post(`uploadRepalcementImage/${layout_id}`, payload, config)
