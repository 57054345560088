import { css } from 'styled-components'
import type { StyledFunction } from 'types/style'

export type DisabledProps = {
  disabled?: boolean
}

const disabled: StyledFunction<DisabledProps> = ({ disabled }) => css`
  ${disabled &&
  css`
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  `}
`

export default disabled
