import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemedStyledProps,
} from 'styled-components'

export type SVGFillValue =
  | string
  | FlattenInterpolation<ThemedStyledProps<object, DefaultTheme>>

const svgFill = (fill: SVGFillValue) => css`
  & svg {
    & path {
      fill: ${fill};
    }
  }
`

export default svgFill
