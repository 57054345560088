import type { PropsWithChildren } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { themeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { getThemeColors, browserStats } from 'utils'
import type { ColorValue } from 'types/style'

const Theme = ({ children }: PropsWithChildren<{}>) => {
  const theme = useRecoilValue(themeAtom) as keyof ColorValue
  const masterTheme: DefaultTheme = {
    ...getThemeColors(theme),
    ...browserStats,
    palette: theme,
  }
  return <ThemeProvider theme={masterTheme}>{children}</ThemeProvider>
}
export default Theme
