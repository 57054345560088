export default (imageFile, name = '') => {
  if (imageFile instanceof File || imageFile instanceof Blob) {
    const reader = new FileReader()

    reader.onload = (event) => {
      const image = new Image()
      image.onload = () => {
        const logName = name ? 'name: ' : ''
        const { width, height } = image

        console.log(logName, { width, height })
      }
      image.src = event.target.result
    }

    reader.readAsDataURL(imageFile)
  } else {
    console.error('The provided file is not a valid Blob or File.')
  }
}
