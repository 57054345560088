import { caseDetailSpan } from '.'
import { replaceInitialValues } from 'utils'

export default (editor, caseDetails, key, templates, dateFormat) => {
  let content = ''
  caseDetails[key].forEach((_, i) => {
    content += `
      <p>
        ${caseDetailSpan(`${key}[${i}].sequence`)}, 
        ${caseDetailSpan(`${key}[${i}].name`)}, 
        ${caseDetailSpan(`${key}[${i}].charity_of_choice`)}
      </p><br/>
      `
  })

  templates.push({
    type: 'menuitem',
    text: 'Memorial Contributions',
    onAction: () =>
      editor.execCommand(
        'mceInsertTemplate',
        false,
        replaceInitialValues(content, caseDetails),
      ),
  })
}
