import { css } from 'styled-components'
import type { StyledFunction } from 'types/style'

export type SizingProps = {
  width?: string
  minWidth?: string
  maxWidth?: string
  height?: string
  minHeight?: string
  maxHeight?: string
  full?: boolean
}

const sizing: StyledFunction<SizingProps> = ({
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  full,
}) => css`
  width: ${full ? '100%' : width};
  min-width: ${minWidth};
  max-width: ${maxWidth};
  height: ${height};
  min-height: ${minHeight};
  max-height: ${maxHeight};
`

export default sizing
