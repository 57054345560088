export default (editorProps) => {
  const { editor } = editorProps
  const isHighlighted = new WeakMap()

  const toggleHighlight = (element) => {
    const range = editor.dom.createRng()
    range.selectNodeContents(element)
    const selection = editor.selection

    selection.setRng(range)
    isHighlighted.set(element, true)
  }

  const findParentWithAttribute = (element) => {
    while (
      element &&
      !(
        element.hasAttribute('data-case-detail') ||
        element.hasAttribute('data-case-detail-table') ||
        element.hasAttribute('data-template')
      )
    ) {
      element = element.parentElement
    }
    return element
  }

  editor.on('click', (e) => {
    const target = e.target
    const parentWithAttribute = findParentWithAttribute(target)

    if (parentWithAttribute) {
      e.preventDefault()
      toggleHighlight(parentWithAttribute)
    }
  })
}
