import React from 'react'
import { EditorSearchBar, Text } from 'components'
import * as S from './InsertCategories.styled'

export default ({
  selected,
  children,
  menu,
  setSelectedMenu,
  setShowSlideout,
  ...props
}) => {
  if (menu === 'SearchBar') {
    return (
      <EditorSearchBar
        selected={selected}
        children={children}
        menu={menu}
        setSelectedMenu={setSelectedMenu}
        setShowSlideout={setShowSlideout}
        {...props}
      />
    )
  }
  return (
    <S.CategoryItem
      align="center"
      justify="space-between"
      height="54px"
      borderBottom="1px solid"
      borderColor="gray4"
      clickable
      {...props}
    >
      <Text margin="0 0 0 12px">{children}</Text>
      <S.ItemArrow
        selected={selected}
        icon="chevronRight"
        fill="gray2"
        margin="0 12px 0 0"
      />
    </S.CategoryItem>
  )
}
