import React from 'react'
import { Flex, Text } from 'components'

import { DefaultTemplateWrapper } from './DefaultTemplateComponents'

export default () => (
  <Flex column>
    <Text size="24px" weight="600" margin="0 0 32px 0">
      Select Default Templates
    </Text>
    <DefaultTemplateWrapper />
  </Flex>
)
