import { axios } from 'api'

export const getAllCollections = async () => {
  try {
    const response = await axios.get(`collections`)
    return response
  } catch (error) {
    throw error
  }
}

export const getCollection = async (collection_id) => {
  try {
    const response = await axios.get(`collections/${collection_id}`)
    return response
  } catch (error) {
    throw error
  }
}
