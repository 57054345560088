export default {
  encode: (data) => {
    const stringifiedData = JSON.stringify(data)
    return encodeURIComponent(stringifiedData)
  },
  decode: (data) => {
    const decodedData = decodeURIComponent(data)
    return JSON.parse(decodedData)
  },
}
