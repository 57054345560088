import {
  editorPagesAtom,
  caseAtom,
  itemIdAtom,
  selectionRangeAtom,
  pageZoomAtom,
  selectedItemAtom,
  alignmentPointsAtom,
  alignmentVisibilityAtom,
  selectedPageAtom,
  orderAtom,
  editorLayoutAtom,
  pageLayoutAtom,
  themeLayoutAtom,
  openSwapImageMenuAtom,
} from 'atoms'
import { useResetRecoilState, useRecoilValue } from 'recoil'
import { useDeleteItems } from './'

export default () => {
  const itemIds = useRecoilValue(itemIdAtom)

  const resetEditorPages = useResetRecoilState(editorPagesAtom)
  const resetOrder = useResetRecoilState(orderAtom)
  const resetCase = useResetRecoilState(caseAtom)
  const resetItemIds = useResetRecoilState(itemIdAtom)
  const resetSelectionRangeAtom = useResetRecoilState(selectionRangeAtom)
  const resetPageZoomAtom = useResetRecoilState(pageZoomAtom)
  const resetSelectedItemAtom = useResetRecoilState(selectedItemAtom)
  const resetAlignmentPointsAtom = useResetRecoilState(alignmentPointsAtom)
  const resetAlignmentVisibilityAtom = useResetRecoilState(
    alignmentVisibilityAtom,
  )
  const resetSelectedPageAtom = useResetRecoilState(selectedPageAtom)
  const resetEditorLayoutAtom = useResetRecoilState(editorLayoutAtom)
  const resetPageLayout = useResetRecoilState(pageLayoutAtom)
  const resetThemeLayoutAtom = useResetRecoilState(themeLayoutAtom)
  const resetSwapImageMenu = useResetRecoilState(openSwapImageMenuAtom)

  return () => {
    itemIds?.map((id) => {
      useDeleteItems(id)
      return id
    })
    resetEditorPages()
    resetCase()
    resetItemIds()
    resetSelectionRangeAtom()
    resetPageZoomAtom()
    resetSelectedItemAtom()
    resetAlignmentPointsAtom()
    resetAlignmentVisibilityAtom()
    resetSelectedPageAtom()
    resetOrder()
    resetEditorLayoutAtom()
    resetPageLayout()
    resetThemeLayoutAtom()
    resetSwapImageMenu()
  }
}
