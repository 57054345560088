import applyStylingToNonEditable from 'utils/tinyMCE/plugins/applyStylingToNonEditable'

export default (editorProps) => {
  const editorFunctions = applyStylingToNonEditable(editorProps)
  const { editor } = editorProps
  const transformStyles = ['uppercase', 'capitalize', 'lowercase']

  // Helper function to get the current text-transform style of the selection
  const getTextTransform = () =>
    editor.dom.getStyle(editor.selection.getNode(), 'text-transform') || ''

  // Helper function to recursively remove the text-transform from all child nodes
  const removeTextTransformRecursive = (node) => {
    editorFunctions.unlockNonEditableBlocks()
    if (node.nodeType === 1) {
      editor.dom.setStyle(node, 'text-transform', null)
      Array.from(node.children).forEach(removeTextTransformRecursive)
    }
    editorFunctions.lockNonEditableBlocks()
  }

  // Apply or remove the text-transform style
  const applyTextTransform = (value) => {
    editorFunctions.unlockNonEditableBlocks()
    if (value === 'remove') {
      removeTextTransformRecursive(editor.selection.getNode())
    } else {
      editor.formatter.register('customTextTransform', {
        inline: 'span',
        styles: { 'text-transform': value },
      })
      editor.formatter.apply('customTextTransform')
    }
    editorFunctions.lockNonEditableBlocks()
  }

  editor.ui.registry.addMenuButton('casing', {
    text: 'Text casing',
    fetch: (callback) => {
      const currentTransform = getTextTransform()
      const items = transformStyles.map((transform) => ({
        type: 'menuitem',
        text: transform.charAt(0).toUpperCase() + transform.slice(1),
        onAction: () => applyTextTransform(transform),
        active: currentTransform === transform,
      }))

      items.push({
        type: 'menuitem',
        text: 'Remove casing',
        onAction: () => applyTextTransform('remove'),
        active: !currentTransform,
      })

      callback(items)
    },
  })
}
