import styled, { css } from 'styled-components'
import { ResizableBox } from 'react-resizable'
import { Icon } from 'components'
import { colors as c } from 'utils'

const CommonIconStyles = css`
  position: absolute;
  top: 5px;
  z-index: ${({ z }) => z + 1};
  visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
`
export const DeleteItem = styled(Icon)(
  () => css`
    ${CommonIconStyles}
    right: 5px;
  `,
)

export const SwapImage = styled(Icon)(
  () => css`
    ${CommonIconStyles}
    left: 5px;
  `,
)

export const ResizeControls = styled(ResizableBox)(
  ({ selected, rotate, disabled }) => css`
    width: 100%;
    height: 100%;
    position: relative;
    outline: ${selected && `2px solid`};
    outline-color: ${selected && c('brand', 75)};
    ${disabled && 'z-index: -2;'}
    ${rotate ? `transform: rotate(${rotate}deg);` : ''}
  `,
)

export const ResizeHandle = styled.div(
  ({ z, position, selected }) => css`
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: 15px;
    height: 15px;
    border-bottom: solid 7px ${c('brand', 75)};
    border-right: solid 7px ${c('brand', 75)};
    color: #fff;
    border-left: solid 7px transparent;
    border-top: solid 7px transparent;
    visibility: ${selected ? 'visible' : 'hidden'};
    cursor: ${`${position}-resize`};
    z-index: ${z + 1};
  `,
)
