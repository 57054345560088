import React from 'react'
import { MenuButton } from 'components'

export default ({ toggleCheckboxes, showingArchived, openFile }) => (
  <MenuButton
    left
    margin="0 16px 0 0"
    text="More actions"
    items={[
      // {
      //   text: 'New memorial label',
      //   icon: 'plus',
      //   onClick: () => console.log('new memorial label'),
      // },
      {
        text: 'Import case',
        icon: 'importCase',
        onClick: () => openFile(),
      },
      {
        text: showingArchived ? 'Unarchive multiple' : 'Archive multiple',
        icon: 'archive',
        onClick: () => toggleCheckboxes(),
      },
    ]}
  />
)
