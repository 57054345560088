import React, { useState } from 'react'
import { Navigation, Flex, Tabs } from 'components'
import {
  ButtonsDrawer,
  DropzoneDrawer,
  ExpandablesDrawer,
  PinturaDrawer,
  PlaygroundDrawer,
  StylesDrawer,
  TablesDrawer,
  TextboxDrawer,
} from './Drawers'

export default () => {
  const [selectedTab, setSelectedTab] = useState('Playground')

  return (
    <>
      <Navigation />

      <Flex margin="42px 0 0 0">
        <Tabs
          selected={[selectedTab, setSelectedTab]}
          tabs={[
            { title: 'Playground', content: <PlaygroundDrawer /> },
            { title: 'Buttons', content: <ButtonsDrawer /> },
            { title: 'Styles', content: <StylesDrawer /> },
            { title: 'Pintura', content: <PinturaDrawer /> },
            { title: 'Textbox', content: <TextboxDrawer /> },
            { title: 'Tables', content: <TablesDrawer /> },
            { title: 'Expandables', content: <ExpandablesDrawer /> },
            { title: 'Dropzone', content: <DropzoneDrawer /> },
          ]}
        />
      </Flex>
    </>
  )
}
