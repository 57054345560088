import React from 'react'
import { Button, Flex } from 'components'
import { caseForm } from 'utils'
import { CaseInputSelector } from '.'

export default ({ saveAndContinue, formSection, sidebar = false }) => {
  const isHonors = formSection === 'honors'
  return (
    <>
      <Flex wrap="wrap" width="100%">
        {caseForm[formSection].map((formField, index) => (
          <CaseInputSelector
            key={`${formField}_${index}`}
            formField={formField}
          />
        ))}
      </Flex>
      {!sidebar && (
        <Flex justify="flex-end">
          <Button
            primary
            onClick={() => {
              saveAndContinue(formSection)
            }}
          >
            {!isHonors ? 'Save and continue' : 'Save'}
          </Button>
        </Flex>
      )}{' '}
    </>
  )
}
