import handleSpecialCaseDetailArrays from 'utils/tinyMCE/handleSpecialCaseDetailArrays'
import { tableTemplate } from '.'
import startCase from 'lodash/startCase'

export default (editor, caseDetails, key, templates, dateFormat) => {
  // Pushes content of array as a table

  const items = handleSpecialCaseDetailArrays({
    path: key,
    items: caseDetails[key],
  })

  const tableContent = tableTemplate({ path: key, items })

  templates.push({
    type: 'menuitem',
    text: `${startCase(key)}`,
    onAction: () =>
      editor.execCommand('mceInsertTemplate', false, tableContent),
  })
}
