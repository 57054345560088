import { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { uploadEditedCaseImage, deleteEditedCaseImage } from 'api/cases'
import {
  uploadEditedBackgroundImage,
  deleteEditedBackgroundImage,
} from 'api/customThemes'
import { getImageDimensions, getUrlPath } from 'utils'
import {
  itemSelector,
  caseAtom,
  selectedItemAtom,
  itemAtomFamily,
  showPinturaAtom,
  backgroundImageAtom,
  pageThemeLayoutSelector,
  pageLayoutAtom,
  pageLoadingAtom,
  themeLayoutAtom,
} from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default (update, editBackground) => {
  const handleError = useErrorHandler()
  const _case = useRecoilValue(caseAtom)
  const selectedItem = useRecoilValue(selectedItemAtom)
  const item = useRecoilValue(itemAtomFamily(selectedItem))
  const backgroundImage = useRecoilValue(backgroundImageAtom)
  const setShowPintura = useSetRecoilState(showPinturaAtom)
  const setLoadingPage = useSetRecoilState(pageLoadingAtom)
  const themeLayout = useRecoilValue(themeLayoutAtom)

  // case image
  const setEditedImageURL = useSetRecoilState(
    itemSelector({ id: item.id, path: 'image.editedUrl' }),
  )
  const setImageURL = useSetRecoilState(
    itemSelector({ id: item.id, path: 'image.url' }),
  )
  const setImageEdits = useSetRecoilState(
    itemSelector({ id: item.id, path: 'image.edits' }),
  )

  const [originalImageSize, setOriginalImageSize] = useState(null)

  const { id: caseId, filledImages } = _case || {}
  const {
    image: { url, edits, editedUrl } = {},
    properties: { caseImage } = {},
  } = item || {}

  // background image
  const pageThemeLayout = useRecoilValue(pageLayoutAtom)
  const setEditedBackground = useSetRecoilState(
    pageThemeLayoutSelector({ path: 'background_edits' }),
  )

  useEffect(() => {
    if (url?.includes('placeholder')) {
      setImageURL(
        `${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}${filledImages?.[caseImage]}`,
      )
    }
  })

  const getOrignalImageSize = async (props) => {
    const { item } = props

    const isItemImage = item?.image
    if (!isItemImage) return

    const originalSizes = await getImageDimensions(item?.image?.url)
    setOriginalImageSize(originalSizes)
  }

  useEffect(() => {
    if (editBackground === undefined) return
    if (item === undefined) return

    const sizeProps = {
      item,
      pageThemeLayout,
      themeLayout,
      editBackground,
    }

    getOrignalImageSize(sizeProps)
    // eslint-disable-next-line
  }, [item, editBackground, pageThemeLayout, themeLayout])

  // methods
  const handleCaseImageDelete = async () => {
    if (editedUrl) {
      try {
        const editedUrlPath = getUrlPath(editedUrl)
        await deleteEditedCaseImage(caseId, {
          filename: editedUrlPath,
        })
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    }
  }

  const handleBackgroundImageDelete = async () => {
    if (pageThemeLayout?.background_edits?.url) {
      try {
        const editedUrlPath = getUrlPath(pageThemeLayout?.background_edits?.url)
        await deleteEditedBackgroundImage(caseId, {
          filename: editedUrlPath,
        })
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    }
  }

  const handleCaseImageUpload = async (formData) => {
    try {
      const response = await uploadEditedCaseImage(caseId, formData)
      return response
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const handleProcessing = () => {
    editBackground && setLoadingPage(true)
    editBackground && setShowPintura(false)
  }

  const handleEditedBackgroundImageUpload = async (formData) => {
    try {
      const newBackground = await uploadEditedBackgroundImage(caseId, formData)
      setLoadingPage(false)
      return newBackground
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const handleCaseImageUploadSuccess = (imageState, data) => {
    setImageEdits(imageState)
    setEditedImageURL(data.location)
  }

  const handleEditedBackgroundImageUploadSuccess = (imageState, data) => {
    setEditedBackground({
      url: data,
      edits: imageState,
      theme_id: themeLayout?.theme_id,
    })
  }

  const handleDoneEditing = async (props) => {
    const { imageState, dest } = props

    let formData = new FormData()
    formData.append('file', dest)

    // if there is already an edited image url, then delete
    editBackground ? handleBackgroundImageDelete() : handleCaseImageDelete()

    const { status, data } = editBackground
      ? await handleEditedBackgroundImageUpload(formData)
      : await handleCaseImageUpload(formData)

    if (data && status === 200) {
      editBackground
        ? handleEditedBackgroundImageUploadSuccess(imageState, data)
        : handleCaseImageUploadSuccess(imageState, data)
      setShowPintura(false)
      update()
    }
  }

  const handleFailure = () => {
    alert('Error processing')
    setLoadingPage(false)
  }

  const caseImageEdits = {
    ...edits,
    targetSize: {
      width: originalImageSize?.width,
      height: originalImageSize?.height,
    },
  }

  return {
    url: editBackground
      ? `${process.env.REACT_APP_S3BUCKET_THEME_IMAGES}${backgroundImage}`
      : url,
    edits: editBackground
      ? pageThemeLayout?.background_edits?.edits
      : caseImageEdits,
    handleFailure,
    handleProcessing,
    handleDoneEditing,
  }
}
