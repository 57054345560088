export default (baseURL) => [
  {
    name: `Clearing Cache`,
    url: `${baseURL}Clearing-Cache.pdf`,
  },
  {
    name: 'Uploading a Logo',
    url: `${baseURL}Uploading-a-Logo.pdf`,
  },
  {
    name: `Creating a Custom Theme`,
    url: `${baseURL}Creating-A-Custom-Theme.pdf`,
  },
  {
    name: `Memories Box`,
    url: `${baseURL}Memories-Box.pdf`,
  },
  {
    name: `Creating an Opacity Box`,
    url: `${baseURL}Creating-An-Opacity-Box.pdf`,
  },
  {
    name: `Removing a Silhouette`,
    url: `${baseURL}Removing-a-Silhouette.pdf`,
  },
  {
    name: `Adjusting Layer Level`,
    url: `${baseURL}Adjusting-Layer-Level.pdf`,
  },
  {
    name: `Creating a Duplicate Order`,
    url: `${baseURL}Creating-a-Duplicate-Order.pdf`,
  },
  {
    name: `Image Editor`,
    url: `${baseURL}Image-Editor.pdf`,
  },
  {
    name: `Creating a Saved Layout`,
    url: `${baseURL}Creating-a-Saved-Layout.pdf`,
  },
  {
    name: `Adding and Removing Pages`,
    url: `${baseURL}Adding-And-removing-pages.pdf`,
  },
  {
    name: `Floral Pix App`,
    url: `${baseURL}Floral-Pix-App.pdf`,
  },
  {
    name: `Uploading a Logo`,
    url: `${baseURL}Uploading-A-Logo.pdf`,
  },
  {
    name: `Choosing a Product Line`,
    url: `${baseURL}Choosing-a-Product-Line.pdf`,
  },
  {
    name: `Creating a Custom Verse or Phrase`,
    url: `${baseURL}Creating-a-Custom-Verse-or-Phrase.pdf`,
  },
  {
    name: `Creating a New Case`,
    url: `${baseURL}Creating-a-New-Case.pdf`,
  },
  {
    name: `Creating a New Print Package`,
    url: `${baseURL}Creating-a-New-Print-Package.pdf`,
  },
  {
    name: `Memorial Ornament Labels`,
    url: `${baseURL}Memorial-Ornament-Labels.pdf`,
  },
  {
    name: `Text Editing Tool`,
    url: `${baseURL}Text-Editing-Tool.pdf`,
  },
  {
    name: `Uploading Photos`,
    url: `${baseURL}Uploading-Photos.pdf`,
  },
  {
    name: `Remembrance Registry System`,
    url: `${baseURL}Remembrance-Registry-System.pdf`,
  },
]
