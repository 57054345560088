import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  selectedItemAtom,
  itemAtomFamily,
  editorClipboardAtom,
  selectedItemsAtom,
} from 'atoms'

export default () => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const item = useRecoilValue(itemAtomFamily(selectedItem))
  const setClipboard = useSetRecoilState(editorClipboardAtom)
  const setSelectedItems = useSetRecoilState(selectedItemsAtom)

  const copyItemToClipboard = () => {
    setSelectedItems([])

    const copiedItem = { ...item }
    setClipboard([copiedItem])
  }
  return () => copyItemToClipboard()
}
