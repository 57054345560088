import type { ThemeProps } from 'types/DBTheme'

const fontColors = (props: ThemeProps) => {
  const { selectedTheme } = props

  const THEME_COLORS = {
    main: { value: selectedTheme?.font_color, label: 'Theme Color' },
    accent1: { value: selectedTheme?.font_color_accent1, label: 'Accent 1' },
    accent2: { value: selectedTheme?.font_color_accent2, label: 'Accent 2' },
  }

  const customColorsMap = Object.entries(THEME_COLORS)
    .filter(([_, color]) => !!color.value)
    .flatMap(([_, color]) => [color.value, color.label])

  return [
    '#BFEDD2',
    'Light Green',
    '#FBEEB8',
    'Light Yellow',
    '#F8CAC6',
    'Light Red',
    '#ECCAFA',
    'Light Purple',
    '#C2E0F4',
    'Light Blue',

    '#2DC26B',
    'Green',
    '#F1C40F',
    'Yellow',
    '#E03E2D',
    'Red',
    '#B96AD9',
    'Purple',
    '#3598DB',
    'Blue',

    '#169179',
    'Dark Turquoise',
    '#E67E23',
    'Orange',
    '#BA372A',
    'Dark Red',
    '#843FA1',
    'Dark Purple',
    '#236FA1',
    'Dark Blue',

    '#ECF0F1',
    'Light Gray',
    '#CED4D9',
    'Medium Gray',
    '#95A5A6',
    'Gray',
    '#7E8C8D',
    'Dark Gray',
    '#34495E',
    'Navy Blue',

    '#000000',
    'Black',
    '#ffffff',
    'White',
    ...customColorsMap,
  ]
}

export default fontColors
