import React from 'react'
import { Grid } from 'components'
import { ImageItem } from '../Items'

export default ({
  setShow,
  items,
  imageKey,
  nameKey,
  srcPrefix,
  type,
  canDelete,
  isVerse,
  prependPrefix = true,
  idKey,
  columns = 2,
  ...props
}) => (
  <Grid columns={columns} {...props}>
    {items?.map((item, index) => {
      const effectiveSrcPrefix = prependPrefix
        ? srcPrefix || process.env.REACT_APP_S3BUCKET_UPLOADS
        : ''
      return (
        <ImageItem
          id={item[idKey]}
          name={item[nameKey]}
          src={`${effectiveSrcPrefix}${item[imageKey] || item}`}
          index={index}
          type={type}
          key={index}
          setShow={setShow}
          canDelete={canDelete}
          isVerse={isVerse}
        />
      )
    })}
  </Grid>
)
