import * as S from './RedMargin.styled'
import { useRecoilValue } from 'recoil'
import { showRedMarginAtom } from 'atoms'

type RedMarginProps = {
  height: number
  width: number
  paddingLeft: number
  paddingRight: number
  paddingTop: number
  paddingBottom: number
  color: string
}

const RedMargin = ({
  height,
  width,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  color,
}: RedMarginProps) => {
  const showRedMargin = useRecoilValue(showRedMarginAtom)
  if (!showRedMargin) return null

  return (
    <S.Container height={height} width={width}>
      <S.TopFrame
        color={color}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingTop={paddingTop}
      />
      <S.RightFrame
        color={color}
        paddingTop={paddingTop}
        paddingRight={paddingRight}
        paddingBottom={paddingBottom}
      />
      <S.BottomFrame
        color={color}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingBottom={paddingBottom}
      />
      <S.LeftFrame
        color={color}
        paddingTop={paddingTop}
        paddingLeft={paddingLeft}
        paddingBottom={paddingBottom}
      />
      <S.TopLeftCorner
        color={color}
        paddingLeft={paddingLeft}
        paddingTop={paddingTop}
      />
      <S.TopRightCorner
        color={color}
        paddingRight={paddingRight}
        paddingTop={paddingTop}
      />
      <S.BottomLeftCorner
        color={color}
        paddingLeft={paddingLeft}
        paddingBottom={paddingBottom}
      />
      <S.BottomRightCorner
        color={color}
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
      />
    </S.Container>
  )
}

export default RedMargin
