import styled, { css } from 'styled-components'
import { typeArea, sizing, spacing, disabled } from 'styles'
import { Flex } from 'components'

export const InputWrapper = styled(Flex)`
  ${disabled}
`

export const Input = styled.input(
  ({ ...props }) => css`
    padding: 12px 16px;
    height: 42px;
    width: 100%;

    ${typeArea}
    ${sizing(props)}
    ${spacing(props)}
  `,
)
