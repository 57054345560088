export const ColorPicker = (color) => ({
  background:
    color ||
    'repeating-conic-gradient(rgba(180, 180, 180, .25) 0% 25%, transparent 0% 50%) 50% / 14px 14px',
  height: '42px',
  width: '42px',
  position: 'relative',
  cusror: 'pointer',
  borderRadius: '2px',
  marginRight: '16px',
})
