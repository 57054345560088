import { isoStringToDate, convertObjectOfDates } from 'utils'
import { dashboardDates as dates } from 'mocks'
import type { ServiceDate, SortableServiceDate } from 'types/dates'

type DashboardCell = {
  services?: ServiceDate[]
  sortable_service_date?: SortableServiceDate[]
  [key: string]: unknown
}

/**
 * Converts array of case dashboard cells' date fields to Date objects using @link{convertObjectOfDates} and @link{isoStringToDate}.
 * @param originalCells - The original dashboard cells, for example, may include funeral home orders or cases.
 * @example
 * ```
 * Example usage:
 * const cells = sanitizeDashboardDates([
 *   {
 *     // Direct cell dates
 *     created_at: '2024-03-20T10:00:00Z',
 *     date_of_death: '2024-03-19T15:30:00Z',
 *
 *     // services is an array containing an array of potential service objects
 *     services: [[
 *       {
 *         date: '2024-03-21T09:00:00Z',
 *         startTime: '2024-03-21T09:00:00Z',
 *         endTime: '2024-03-21T10:00:00Z'
 *       },
 *       {
 *         date: '2024-03-22T14:00:00Z',
 *         startTime: '2024-03-22T14:00:00Z',
 *         endTime: '2024-03-22T15:00:00Z'
 *       }
 *     ]],
 *
 *     // sortable_service_date is an array containing an array of potential sortable service date objects
 *     sortable_service_date: [[
 *       {
 *         date: '2024-03-21T00:00:00Z'
 *       }
 *     ]]
 *   }
 * ])
 *
 * Results in:
 * [
 *   {
 *     created_at: '03/20/2024',
 *     date_of_death: '03/19/2024',
 *     services: [[
 *       {
 *         date: '03/21/2024',
 *         startTime: '09:00 AM',
 *         endTime: '10:00 AM'
 *       },
 *       {
 *         date: '03/22/2024',
 *         startTime: '02:00 PM',
 *         endTime: '03:00 PM'
 *       }
 *     ]],
 *     sortable_service_date: [[
 *       {
 *         date: '03/21/2024'
 *       }
 *     ]]
 *   }
 * ]
 * console.log(cells[1].sortable_service_date[0]) // Date object
 * ```
 * @returns The sanitized cells data with their dates converted to Date objects.
 */

const sanitizeDashboardDates = (originalCells: DashboardCell[]) => {
  const cells = [...originalCells]

  cells.forEach((cell: DashboardCell) => {
    convertObjectOfDates(dates.table, cell, isoStringToDate, null)

    cell?.services?.forEach((service: ServiceDate) =>
      convertObjectOfDates(dates.service, service, isoStringToDate, null),
    )

    cell?.sortable_service_date?.forEach((service: SortableServiceDate) =>
      convertObjectOfDates(
        dates.sortableServiceDate,
        service,
        isoStringToDate,
        null,
      ),
    )
  })

  return cells
}

export default sanitizeDashboardDates
