import React, { useState, useEffect } from 'react'
import { Flex, Text, ProductPrint, Icon } from 'components'
import * as S from './InsertItems.styled'
import { isEven } from 'utils'

export default ({
  index,
  theme,
  themeLayout,
  value,
  deletePage,
  goToPage,
  disabled,
  preventDelete,
  isDeleting,
}) => {
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    !isDeleting && setDeleting(false)
  }, [isDeleting])

  return (
    <Flex
      full
      align="center"
      justify="space-between"
      height="100%"
      opacity={deleting ? '.5' : '1'}
      onClick={() => goToPage(index)}
    >
      <Flex align="center">
        <S.DragIcon
          margin="0 24px 0 0"
          data-movable-handle
          icon="pageShuffleDrag"
          tabIndex={-1}
          disabled={disabled}
        />
        <S.ProductWrapper>
          <ProductPrint
            isPageShuffleItem={true}
            position="relative"
            theme={theme}
            themeLayout={themeLayout}
            page={value}
            //TODO we will want to add this logic back in eventually probably?
            // isPMRegisterBook={isPMRegisterBook}
            isEven={isEven(value.pageProperties.pageNumber)}
          />
        </S.ProductWrapper>
      </Flex>
      <Flex align="center" width="70%" justify="space-between">
        <Flex column>
          <Text margin="0 24px 0 0">
            Page {value.pageProperties.pageNumber}
          </Text>
          <Text color="gray2">
            {value?.pageProperties?.product?.includes('Register Book')
              ? `${isEven(value.pageProperties.pageNumber) ? 'Left' : 'Right'}`
              : `${isEven(value.pageProperties.pageNumber) ? 'Back' : 'Front'}`}
          </Text>
        </Flex>

        {!disabled && !preventDelete && (
          <Icon
            disabled={disabled}
            icon="archive"
            fill="gray2"
            margin="0 24px 0 0"
            onClick={(e) => {
              setDeleting(true)
              deletePage(e, index)
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}
