import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0, Recoil, Theme, Initiation, Routes } from 'providers'
import { reportWebVitals } from 'utils'
import 'assets/css/fonts.css'
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'

FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID })

Sentry.init({
  dsn: 'https://94de4c3df2dd9a22c8781e9aca3f853e@o4507587149234176.ingest.us.sentry.io/4507587150675968',
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://dignity-direct-frontend-staging.vercel.app/',
    'https://dignity-direct-frontend.vercel.app/',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  <Recoil>
    <Theme>
      <Initiation>
        <Auth0>
          <Routes />
        </Auth0>
      </Initiation>
    </Theme>
  </Recoil>,
  document.getElementById('root'),
)

reportWebVitals()
