import { axios } from 'api'

/// ADMIN USER FUNCTIONS ///

// NOTE: Endpoint not used
export const updateUser = async (id, user) => {
  try {
    const res = await axios.patch(`/users/${id}`, user)
    return res

    // expected payload (ALL OPTIONAL):
    // {
    //   email: <String>,
    //   password: <String>,
    //   name: <String>,
    //   funeral_home_id: <UUID: funeral_home_id>,
    //   corporation_id: <UUID: corporation_id>,
    //   type: <string: type [user, corporationAdmin, superAdmin]>,
    //   manager: <boolean>,
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const usersEmailsAsCSV = async () => {
  try {
    const res = await axios.get(`/usersEmailsAsCSV`)
    return res
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const searchAllUsers = async (searchParams) => {
  try {
    const res = await axios.post(`/searchUsers`, searchParams)
    return res

    // expected payload (ALL OPTIONAL):
    // {
    //   user_name: <String>,
    //   user_id: <String>,
    //   funeral_home_id: <String>,
    //   funeral_home_name: <String>,
    //   funeral_home_location: <String>,
    //   user_email: <String>,
    //   corporation: <String>,
    //   admin: <Boolean>,
    //   active: <Boolean>,
    //   not_enrolled: <Boolean>,
    //   archived: <Boolean>,
    //   limit: <Number>,
    //   page: <Number>,
    // }
  } catch (error) {
    throw error
  }
}

/// ADMIN ASSET ENDPOINTS ///
/* Assets are structured into Categories (ie Clip Art), then SubCategories (ie Hobbies), then Asset itself (ie Ballet Shoes) 
the asset will have an subcategory_id -> the subcategory will have a category_id*/

//Asset Category
// NOTE: Endpoint not used
export const createAssetCategory = async (payload) => {
  try {
    const res = await axios.post(`/createAssetCategory`, payload)
    return res

    //expected payload
    // {
    //   name: <String> (required)
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const updateAssetCategory = async (payload, asset_category_id) => {
  try {
    const res = await axios.patch(
      `/assetCategories/${asset_category_id}`,
      payload,
    )
    return res

    //expected payload
    // {
    //   name: <String> (optional)
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const deleteAssetCategory = async (asset_category_id) => {
  try {
    const res = await axios.delete(`/assetCategories/${asset_category_id}`)
    return res
  } catch (error) {
    throw error
  }
}

//Asset subcategory
// NOTE: Endpoint not used
export const createAssetSubcategory = async (payload) => {
  try {
    const res = await axios.post(`/createAssetSubcategory`, payload)
    return res

    //expected payload
    // {
    //   name: <String> (required),
    //   category_id: <uuid> (required)
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const updateAssetSubcategory = async (payload, asset_subcategory_id) => {
  try {
    const res = await axios.patch(
      `/assetSubcategories/${asset_subcategory_id}`,
      payload,
    )
    return res

    //expected payload
    // {
    //   name: <String> (optional)
    //   category_id: <uuid> (optional)
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const deleteAssetSubcategory = async (asset_subcategory_id) => {
  try {
    const res = await axios.delete(
      `/assetSubcategories/${asset_subcategory_id}`,
    )
    return res
  } catch (error) {
    throw error
  }
}

//Assets
// NOTE: Endpoint not used
export const createAsset = async (payload) => {
  try {
    const res = await axios.post(`/createAsset`, payload)
    return res

    //expected payload
    // {
    //   name: <String> (required),
    //   image: <String> (required), (this is the aws location id - ie '4e5je4odansonn2af9a4iimage.png') this can/should be a placeholder while the image is uploaded to aws
    //   subcategory_id: <uuid> (required)
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const updateAsset = async (payload, asset_id) => {
  try {
    const res = await axios.patch(`/assets/${asset_id}`, payload)
    return res

    //expected payload
    // {
    //   name: <String> (optional)
    //   image: <String> (optional),
    //   subcategory_id: <uuid> (optional)
    // }
  } catch (error) {
    throw error
  }
}

// NOTE: Endpoint not used
export const deleteAsset = async (asset_id) => {
  try {
    const res = await axios.delete(`/assets/${asset_id}`)
    return res
  } catch (error) {
    throw error
  }
}

//technically this is not an admin endpoint 😬
// NOTE: Endpoint not used
export const uploadAssetImage = async (asset_id, image_payload) => {
  try {
    const res = await axios.post(`/uploadAssetImage/${asset_id}`, image_payload)
    return res

    // the asset_id will be from the create_asset endpoint the image payload is the image file (or a compressed version)
  } catch (error) {
    throw error
  }
}

// TODO: corporation admin endpoints for corporation dashboard?

export const migrateThemes = async (payload) => {
  try {
    const res = await axios.post(`/migrateTableData`, payload)
    return res
  } catch (error) {
    throw error
  }
}

export const makeGlobalLayouts = async () => {
  try {
    const res = await axios.post(`/globalLayouts`, {})
    return res
  } catch (error) {
    throw error
  }
}

export const revertGlobalLayouts = async () => {
  try {
    const res = await axios.post(`/localLayouts`, {})
    return res
  } catch (error) {
    throw error
  }
}
