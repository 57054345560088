import * as S from './Divider.styled'
import { useRecoilValue } from 'recoil'
import { showRedMarginAtom } from 'atoms'

export default ({ whiteBorder, left, padding, redMargin, ...props }) => {
  const showRedMargin = useRecoilValue(showRedMarginAtom)

  return (
    <S.Divider
      position="absolute"
      left={`${whiteBorder ? `${left - whiteBorder}` : `${left + padding}`}px`}
      height={`calc(100% - ${padding * 2}px)`}
      top={`${padding}px`}
      width={whiteBorder ? `${padding}px` : '0px'}
      background={whiteBorder && showRedMargin ? redMargin : undefined}
      borderLeft={
        !whiteBorder && showRedMargin
          ? `${padding}px solid ${redMargin}`
          : undefined
      }
      whiteBorder={whiteBorder}
      {...props}
    />
  )
}
