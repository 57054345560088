import React from 'react'
import { Flex, Image, Text } from 'components'
import { formatDate } from 'utils'
import { useFamilyUpload } from 'hooks'

export default ({ _case }) => {
  const { avatarPlaceholder } = useFamilyUpload()
  const images = _case.personal_images

  // if 0 or 1 images, use placeholder to not duplicate LeftHero image
  const finalSrc =
    images?.length <= 1
      ? avatarPlaceholder
      : `${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}${images?.[1]}`

  return (
    <Flex align="center" margin="0 0 48px 0">
      <Image
        src={finalSrc}
        radius="100%"
        width="112px"
        height="112px"
        margin="0 32px 0 0"
      />
      <Flex column>
        <Text weight="500" size="14px" transform="uppercase" margin="0 0 8px 0">
          Share memories with {_case.funeral_home}
        </Text>
        <Text weight="600" size="24px" margin="0 0 8px 0">
          In memory of {_case.name_of_deceased}
        </Text>
        <Text size="16px">
          Last Day to Upload:{' '}
          {formatDate(_case.expiration_date, 'MMMM do, yyyy')}
        </Text>
      </Flex>
    </Flex>
  )
}
