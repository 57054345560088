import React from 'react'
import { Text } from 'components'
import * as S from './ToastAction.styled'

export default ({ text, removeToast, onClick }) => (
  <S.ToastAction
    center
    clickable
    margin="0 0 0 8px"
    radius="2px"
    padding="4px"
    border="1px solid"
    borderColor="white"
    transition="0.25s"
    onClick={() => {
      onClick?.()
      removeToast()
    }}
  >
    <Text size="12px" weight="600" color="white">
      {text}
    </Text>
  </S.ToastAction>
)
