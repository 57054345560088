import React, { useEffect, useState } from 'react'
import { Expandable, Flex } from 'components'
import { CaseFormSection } from '..'

export default ({ saveCase, setActiveTab, activeTab }) => {
  const [expandedServiceDetails, setExpandedServiceDetails] = useState(true)
  //const [expandedMusicSelections, setExpandedMusicSelections] = useState('')
  const [expandedPallbearers, setExpandedPallbearers] = useState('')
  const [expandedInterment, setExpandedInterment] = useState('')

  useEffect(() => {
    setExpandedServiceDetails(true)
    setExpandedPallbearers(false)
    //setExpandedMusicSelections(false)
    setExpandedInterment(false)
  }, [activeTab])

  const saveAndContinue = (expanded) => {
    switch (expanded) {
      case 'serviceInfo':
        setExpandedServiceDetails(false)
        //setExpandedMusicSelections(true)
        break
      case 'musicSelections':
        //setExpandedMusicSelections(false)
        setExpandedPallbearers(true)
        break
      case 'pallBearersSocieties':
        setExpandedInterment(true)
        setExpandedPallbearers(false)
        //setExpandedMusicSelections(false)
        setExpandedServiceDetails(false)
        break
      case 'intermentInfo':
        setExpandedInterment(false)
        setExpandedPallbearers(false)
        //setExpandedMusicSelections(false)
        setExpandedServiceDetails(false)
        setActiveTab('Images and Family Memorials')
        break
      default:
        //for now collapse all
        setExpandedInterment(false)
        setExpandedPallbearers(false)
        //setExpandedMusicSelections(false)
        setExpandedServiceDetails(false)
    }
    saveCase()
  }

  return (
    <Flex column full padding="0 48px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 32px 0"
          title="Service details"
          show={[expandedServiceDetails, setExpandedServiceDetails]}
          full
        >
          <CaseFormSection
            formSection="serviceInfo"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>

        {/*     <Expandable
          margin="0 0 32px 0"
          title="Music selections"
          show={[expandedMusicSelections, setExpandedMusicSelections]}
          full
        >
          <CaseFormSection
            formSection="musicSelections"
            saveAndContinue={saveAndContinue}
          />
        </Expandable> */}
        <Expandable
          margin="0 0 32px 0"
          title="Pallbearers & societies attending"
          show={[expandedPallbearers, setExpandedPallbearers]}
          full
        >
          <CaseFormSection
            formSection="pallBearersSocieties"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
        <Expandable
          margin="0 0 32px 0"
          title="Interment information"
          show={[expandedInterment, setExpandedInterment]}
          full
        >
          <CaseFormSection
            formSection="intermentInfo"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
      </Flex>
    </Flex>
  )
}
