import { roleAtom, userAtom } from 'atoms'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

export const useAdminValidation = () => {
  const [userRole, setUserRole] = useRecoilState(roleAtom)
  const user = useRecoilValue(userAtom)
  // Get query params from URL
  const queryParams = new URLSearchParams(window.location.search)
  // const queryParams = new URLSearchParams(location.search)
  const adminKey = queryParams.get('access')

  useEffect(() => {
    if (user.type === 'SUPER_ADMIN') {
      setUserRole('messenger-user')
      return
    }

    if (!adminKey) {
      setUserRole('fh-user')
      return
    }

    const role = adminKey === 'messenger' ? 'messenger-user' : 'fh-user'
    setUserRole(role)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.type, adminKey])

  return userRole
}
