import applyDefaultThemeStyles from './applyDefaultThemeStyles'
import keepNewBlockStyles from './keepNewBlockStyles'

export default (editorProps) => {
  const { editor, selectedTheme, isVerse } = editorProps

  const $ = editor.editorManager.dom.DomQuery
  const nonEditableClass = editor.getParam(
    'noneditable_noneditable_class',
    'mceNonEditable',
  )

  // Temporarily remove the noneditable class before any content mutation
  const unlockNonEditableBlocks = () => {
    $(editor.getBody())
      .find(`*.${nonEditableClass}`)
      .each(function () {
        $(this).removeClass(nonEditableClass)
        $(this).attr('contenteditable', true) // Temporarily allow editing
      })
  }

  // Re-lock non-editable elements after content mutation is finished
  const lockNonEditableBlocks = () => {
    $(editor.getBody())
      .find(`*[data-case-detail], *[data-template], *[data-template-item]`)
      .each(function () {
        $(this).addClass(nonEditableClass)
        $(this).attr('contenteditable', false) // Restore non-editable state
      })
  }

  const placeCursorOnFocusedTables = (e) => {
    const focusedElement = e.target.bodyElement
    if (!focusedElement) return

    const dataCaseDetailTableElement = focusedElement.querySelector(
      '[data-case-detail-table]',
    )
    if (!dataCaseDetailTableElement) return

    editor.selection.collapse(false)
  }

  // Trigger before any command that could mutate content
  editor.on('BeforeExecCommand', ({ command }) => {
    unlockNonEditableBlocks()
  })

  editor.on('NewBlock', (e) => {
    try {
      keepNewBlockStyles({ editor, newBlockEvent: e })
    } catch (error) {
      console.error('Failed to apply formatting:', error)
    }
  })

  // Trigger after any command is finished
  editor.on('ExecCommand', ({ command }) => {
    setTimeout(() => {
      lockNonEditableBlocks()
    }, 0) // Ensure it locks after the command is executed
  })

  // Also trigger when a node or selection is changed (e.g., font change)
  editor.on('NodeChange', (e) => {
    unlockNonEditableBlocks()
    setTimeout(() => {
      lockNonEditableBlocks()
    }, 0)
  })

  editor.on('focus', (e) => {
    if (isVerse)
      applyDefaultThemeStyles({
        editor,
        tinyMceEvent: e,
        selectedTheme,
        isVerse,
      })
    placeCursorOnFocusedTables(e)
  })

  // On initialization, lock the blocks initially and apply default theme styles on new text boxes
  editor.on('init', (e) => {
    applyDefaultThemeStyles({ editor, tinyMceEvent: e, selectedTheme })
    lockNonEditableBlocks()
  })

  // Expose the functions for external use
  return { unlockNonEditableBlocks, lockNonEditableBlocks }
}
