import papa from 'papaparse'
import _ from 'lodash'

const parseFile = async (rawFile) => {
  return new Promise((resolve) => {
    papa.parse(rawFile, {
      skipEmptyLines: true,
      dynamicTyping: true,
      header: true,
      complete: (results) => {
        resolve(results)
      },
    })
  })
}

export default async (file) => {
  const results = await parseFile(file)

  const { data } = results

  const object = _.mapKeys(data[0], (value, key) => {
    return _.snakeCase(key)
  })

  if (!object.deceaseds_name) {
    return caseParse(object)
  } else {
    return srsParse(object)
  }
}

const caseParse = (object) => {
  // Collates services and stores them on newCase variable
  const service1 = object.service_1_date && {
    default: object.service_1_default && object.service_1_default,
    date: object.service_1_date && object.service_1_date,
    location: object.service_1_location && object.service_1_location,
    time: object.service_1_time && object.service_1_time,
    type: object.service_1_type && object.service_1_type,
  }
  const service2 = object.service_2_date && {
    default: object.service_2_default && object.service_2_default,
    date: object.service_2_date && object.service_2_date,
    location: object.service_2_location && object.service_2_location,
    time: object.service_2_time && object.service_2_time,
    type: object.service_2_type && object.service_2_type,
  }
  const service3 = object.service_3_date && {
    default: object.service_3_default && object.service_3_default,
    date: object.service_3_date && object.service_3_date,
    location: object.service_3_location && object.service_3_location,
    time: object.service_3_time && object.service_3_time,
    type: object.service_3_type && object.service_3_type,
  }
  const service4 = object.service_4_date && {
    default: object.service_4_default && object.service_4_default,
    date: object.service_4_date && object.service_4_date,
    location: object.service_4_location && object.service_4_location.join(','),
    time: object.service_4_time && object.service_4_time,
    type: object.service_4_type && object.service_4_type,
  }
  object.services = []
  service1 && object.services.push(service1)
  service2 && object.services.push(service2)
  service3 && object.services.push(service3)
  service4 && object.services.push(service4)

  // Collates additional_family and stores them on newCase variable

  const family1 = object.family_1_name && {
    name: object.family_1_name && object.family_1_name,
    relation: object.family_1_relation && object.family_1_relation,
    type: object.family_1_type && object.family_1_type,
  }
  const family2 = object.family_2_name && {
    name: object.family_2_name && object.family_2_name,
    relation: object.family_2_relation && object.family_2_relation,
    type: object.family_2_type && object.family_2_type,
  }
  const family3 = object.family_3_name && {
    name: object.family_3_name && object.family_3_name,
    relation: object.family_3_relation && object.family_3_relation,
    type: object.family_3_type && object.family_3_type,
  }
  const family4 = object.family_4_name && {
    name: object.family_4_name && object.family_4_name,
    relation: object.family_4_relation && object.family_4_relation,
    type: object.family_4_type && object.family_4_type,
  }
  const family5 = object.family_5_name && {
    name: object.family_5_name && object.family_5_name,
    relation: object.family_5_relation && object.family_5_relation,
    type: object.family_5_type && object.family_5_type,
  }
  object.additional_family = []
  family1 && object.additional_family.push(family1)
  family2 && object.additional_family.push(family2)
  family3 && object.additional_family.push(family3)
  family4 && object.additional_family.push(family4)
  family5 && object.additional_family.push(family5)

  Object.keys(object).map((key) => {
    arrayValues.forEach((arrayValue) => {
      if (key === arrayValue) {
        object[key] =
          typeof object[key] === 'string' ? object[key].split(', ') : []
      }
    })
    if (key.includes('service_')) {
      delete object[key]
    }
    if (key.includes('family_')) {
      delete object[key]
    }
    //handle empty columns
    if (key === '') delete object[key]
    return key
  })

  return object
}

const srsParse = (object) => {
  const children = [],
    music = [],
    siblings = [],
    organizations = [],
    pallbearers = [],
    societies = [],
    clergy = [],
    firingParty = [],
    songs = [],
    service = {},
    stations = [],
    medals = []

  Object.keys(object).map((key) => {
    if (key.includes('child')) {
      object[key] && children.push(object[key])
      delete object[key]
    }
    if (key.includes('music')) {
      object[key] && music.push(object[key])
      delete object[key]
    }
    if (key.includes('song')) {
      object[key] && songs.push(object[key])
      delete object[key]
    }
    if (key.includes('sibling')) {
      object[key] && siblings.push(object[key])
      delete object[key]
    }
    if (key.includes('organization')) {
      object[key] && organizations.push(object[key])
      delete object[key]
    }
    if (key.includes('pallbearer')) {
      object[key] && pallbearers.push(object[key])
      delete object[key]
    }
    if (key.includes('society')) {
      object[key] && societies.push(object[key])
      delete object[key]
    }
    if (key.includes('clergy')) {
      object[key] && clergy.push(object[key])
      delete object[key]
    }
    if (key.includes('firing_party')) {
      object[key] && firingParty.push(object[key])
      delete object[key]
    }
    if (key.includes('station')) {
      object[key] && stations.push(object[key])
      delete object[key]
    }
    if (key.includes('medals_other')) {
      object[key] && medals.push(object[key])
      delete object[key]
    }
    if (key.includes('rendered')) {
      delete object[key]
    }
    if (key.includes('user_defined')) {
      delete object[key]
    }
    if (key.includes('service_number')) {
      object[key] = object[key]?.toString()
    }
    if (key.includes('case_number')) {
      object[key] = object[key]?.toString()
    }
    if (key.includes('service_')) {
      if (key !== 'service_number' && !key.includes('cross')) {
        const path = key.split('_')[1]
        if (object[key]) service[path] = object[key]
        delete object[key]
      }
    }
    //handle empty columns
    if (key === '') delete object[key]
    return key
  })
  object.children = children
  object.songs = music
  object.siblings = siblings
  object.clergy = clergy
  object.societies = societies
  object.organizations = organizations
  object.pallbearers = pallbearers
  object.firing_parties_for_funeral_service = firingParty
  object.special_songs = songs
  if (Object.keys(service).length > 0)
    object.services = [{ ...service, type: 'service' }]
  object.us_stations_where_served = stations
  object.other_medals = medals

  delete object.age
  delete object.age_months
  delete object.age_years
  delete object.age_days
  delete object.departure_date
  delete object.departure_place
  delete object.return_date
  delete object.return_place

  if (!object.distinguished_service_medal)
    object.distinguished_service_medal = false
  if (!object.purple_heart_medal) object.purple_heart_medal = false
  if (!object.silver_star_medal) object.silver_star_medal = false
  if (!object.medal_of_honor) object.medal_of_honor = false
  if (!object.air_force_cross_medal) object.air_force_cross_medal = false
  if (!object.navy_cross_medal) object.navy_cross_medal = false
  if (!object.bronze_star_medal) object.bronze_star_medal = false

  return renameKeys(object, srs_keys)
}

function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key
    return { [newKey]: obj[key] }
  })
  return Object.assign({}, ...keyValues)
}

const srs_keys = {
  case_number: 'editable_case_id',
  air_force_cross: 'air_force_cross_medal',
  bronze_star: 'bronze_star_medal',
  date_discharged: 'date_discharged_from_service',
  deceaseds_father: 'father_of_deceased',
  deceaseds_mother: 'mother_of_deceased',
  deceaseds_name: 'name_of_deceased',
  family_contact: 'family_contact_name',
  family_contact_full_address: 'family_contact_address',
  medal_other: 'other_medals',
  navy_cross: 'navy_cross_medal',
  ncoic: 'ncoic_name',
  obit: 'obituary',
  oic: 'oic_name',
  place_discharged: 'place_discharged_from_service',
  providing_honors: 'person_providing_honors',
  purple_heart: 'purple_heart_medal',
  rank_discharged: 'rank_discharged_from_service',
  residence_full_address: 'address_of_deceased',
  silver_star: 'silver_star_medal',
  distinguished_service_cross: 'distinguished_service_medal',
}

const arrayValues = [
  'children',
  'clergy',
  'grandchildren',
  'siblings',
  'personal_images',
  'organizations',
  'songs',
  'special_songs',
  'pallbearers',
  'honorary_pallbearers',
  'societies',
  'other_medals',
  'us_stations_where_served',
  'firing_parties_for_funeral_service',
]
