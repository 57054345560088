import React, { useState } from 'react'
import { Clipboard, Flex, MenuDrawer, Text } from 'components'
import { determineItemProperties } from 'utils'
import * as Properties from '../Drawers'
import { useRecoilValue } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'

export default () => {
  const selectedItemId = useRecoilValue(selectedItemAtom)
  let selectedItem = useRecoilValue(
    itemSelector({ id: selectedItemId, path: 'type' }),
  )

  selectedItem =
    selectedItem && selectedItem !== 'watermark' ? selectedItem : false

  const [openDrawer, setOpenDrawer] = useState(
    selectedItem ? determineItemProperties?.[selectedItem][0] : false,
  )
  return (
    <Flex column>
      {selectedItem &&
        determineItemProperties?.[selectedItem].map((property, index) => {
          const PropertyDrawer =
            Properties?.[`${property.replace(' ', '')}Drawer`]
          return (
            <MenuDrawer
              show={[openDrawer === property, () => setOpenDrawer(property)]}
              title={property}
              key={property}
              startOpen={index === 0}
            >
              <PropertyDrawer />
            </MenuDrawer>
          )
        })}
      {!selectedItem && (
        <Text margin="32px 40px" color="gray2">
          Select an element to edit
        </Text>
      )}
      <Clipboard padding="0 24px 24px 24px" justify="center" />
    </Flex>
  )
}
