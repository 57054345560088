export const boundingBoxStyling = (boundingBox, c) => ({
  position: 'absolute',
  height: `${boundingBox.height}px`,
  width: `${boundingBox.width}px`,
  top: `${boundingBox.y}px`,
  left: `${boundingBox.x}px`,
  backgroundColor: c('lockedGray4', '50'),
  border: `1px solid ${c('lockedGray4')}`,
  borderRadius: '4px',
})
