import React from 'react'
import { Flex, AdminNav, PrintPackageNav } from 'components'
import * as S from './Navigation.styled'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'

export default ({ type, ...props }) => {
  const userRole = useRecoilValue(roleAtom)

  const isFhUser = userRole === 'fh-user'
  const navHeight = isFhUser ? '0' : '70px'

  if (!type) return null

  return (
    <>
      <Flex
        blur
        full
        background="lockedAlmostBlack"
        bgOpacity="80"
        height={navHeight}
        position="fixed"
        zindex="2"
      />
      <S.Navigation
        full
        justify="space-between"
        align="stretch"
        height={navHeight}
        padding="0 36px"
        zindex="2"
        position="fixed"
      >
        {
          {
            admin: <AdminNav />,
            package: <PrintPackageNav />,
          }[type]
        }
      </S.Navigation>
      <Flex full height={navHeight} background="lockedAlmostBlack" {...props} />
    </>
  )
}
